import React, {useState, useCallback, useEffect} from 'react'
import Way from '../Components/Pages/globalComponents/Way'
import ProductsInBasket from "../Components/Pages/Basket/ProductsInBasket"


export default function Basket(props) {

    const [title] = useState("Корзина");
    const [way] = useState(["Mary Furnitura", "Корзина"]);
    const [globalHref, setGlobalHref] = useState(props.href);
    const [href, setHref] = useState(globalHref);


    const updateHref = useCallback((href) => {
        setHref(href);
    })

    if (globalHref !== props.href) {
        setGlobalHref(props.href);
        setHref(props.href);
    }

    return (
        <div className='Basket' id='body'>

            <Way
                title={title}
                way={way}
            />
            <ProductsInBasket
                href={href}
                updateHref={updateHref}
            />
        </div>
    )
}
