module.exports = global.components = {
  url:   "https://maryfurnitura.ru", //   "http://localhost:8000", //      ", //https://maryfurnitura.ru"", // "http://91.90.34.162:4565",
  phone: ["+79119050888", "8 (911) 905-08-88"],
  vk: ["https://vk.com/club200821031", "ВКонтакте"],
  gmail: ["maryfurnitura@gmail.com", "maryfurnitura@gmail.com"],
  tg: ["https://t.me/Mary_furnitura1", "Telegram"],
  whatsapp: ["https://wa.me/+79119050888", "WhatsApp"],
  viber: ["viber://add?number=79119050888", "Viber"],
  course: ["https://mariannacrafts.ru/", ""],
  fair: ["https://www.livemaster.ru/mjw", "Ярмарка мастеров"],
  address: ["", "г. Санкт-Петербург, Суздальское шоссе 28, к.2"],
};
