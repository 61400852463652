import React from "react";
import "./css/MasterClassesAndCoursesContent.css";
import img1 from "./img/adv/1.jpg";
import img2 from "./img/adv/2.jpg";
import img3 from "./img/adv/3.jpg";
import img4 from "./img/adv/4.jpg";
export default function MasterClassesAndCoursesContent() {
  return (
    <div className="MasterClassesAndCoursesContent">
      <div className="titleBaner"></div>
      <div className="infoCourses">
        <p>
          Достижения моих учениц: Трендовые украшения своими руками – это
          прибыльный бизнес!
          <br />
          Научитесь создавать ювелирную бижутерию и зарабатывайте от 100 тысяч в
          месяц на своем хобби!
        </p>
        <div>
          <a target="_blank" href="https://mariannacrafts.ru/#rec678180552">
            Тарифы
          </a>
          <a target="_blank" href="https://mariannacrafts.ru/#rec678180550">
            Посмотреть наполнение
          </a>
        </div>
      </div>
      <div className="galery">
        <div className="img"></div>
        <div className="img"></div>
        <div className="img"></div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
}
