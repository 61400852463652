import React, {useState, useEffect} from 'react'
import './css/PopUpGalery.css';

import Like from "../../img/likeActive.svg"
import LikeGreenActive from "../../img/LikeGreenActive.svg"

import {Link} from "react-router-dom";
import Favourite from "../../../../Methods/localStorage/Favourite";
import BasketStorage from "../../../../Methods/localStorage/BasketStorage";

export default function PopUpGalery(props) {

    const [LikedImage, setLikedImage] = useState(0);
    const [idLittleImage, setidLittleImage] = useState(0);
    const [like, setlike] = useState(null);

    const [newQuantity, setnewQuantity] = useState();
    const [price, setprice] = useState(0);
    const [old, setold] = useState()
    const [favourite, ] = useState(new Favourite());
    const [basket, ] = useState(props.basket);

    useEffect(() => {

        setlike(favourite.isFavourite(props.information.article))

        if (old !== props.quantity) {
            setold(props.quantity)
            setnewQuantity(props.quantity)
            if (price === null || price === undefined || price === 0) {
                setprice(props.price * newQuantity)
            }
        } else {
            if (newQuantity === 0) {
                setnewQuantity(props.quantity)
            }
            setprice(props.price * newQuantity)
        }
    }, [price, newQuantity, like, old, props.quantity, props.information.article, props.price])

    // закрытие попап окна
    const closePopUpGalery = () => {

        document.getElementById("Footer").style.display = "block";
        let scrollPopUpGaleryTop = document.getElementById('body').style.top;
        scrollPopUpGaleryTop = scrollPopUpGaleryTop.replace(/[^0-9,\s]/g, "")

        document.getElementById(props.namePopUp).style.display = "none";

        document.getElementById('body').classList.remove("fixed");

        window.scroll(scrollPopUpGaleryTop, scrollPopUpGaleryTop);

        document.getElementById('headerBg').classList.remove("hover");

        document.getElementById("LittleImagePopUp" + idLittleImage).style.borderColor = "#ECEBE9";

        setlike(null)
        setnewQuantity(0)
        setprice(0)

        setLikedImage(0)
        setidLittleImage(0)

        document.getElementById("likePopUpGalery").style.backgroundimage = `url(${Like})`;

        document.getElementById("LittleImagePopUp" + idLittleImage).style.borderColor = "#323334";
    }
    const zoomImage = () => {
        document.getElementById('toZoomBigImage').style.display = "block";
    }
    const CloseZoomImage = () => {
        document.getElementById('toZoomBigImage').style.display = "none";
    }
    const likedProduct = (e) => {
        // like Продукта в popup окне
        let likedIdProduct = e.currentTarget.className;
        favourite.updateFavourite(likedIdProduct);
        setlike(favourite.isFavourite(likedIdProduct))
    }
    const activateLittleImage = (e) => {
        // получает порядковый id маленького изображения

        let idLittleImage = e.currentTarget.id;
        idLittleImage = idLittleImage.replace(/[^\d]/g, '');

        setidLittleImage(idLittleImage)
        if (idLittleImage !== LikedImage) {

            // active
            document.getElementById("LittleImagePopUp" + idLittleImage).style.borderColor = "#323334";
            // nonactive
            document.getElementById("LittleImagePopUp" + LikedImage).style.borderColor = "#ECEBE9";

            // Замена большого изображения
            document.getElementById("toZoom").style.backgroundImage = `url(${props.information.image[idLittleImage].image})`;
            document.getElementById("imgOnZoomBigImage").style.backgroundImage = `url(${props.information.image[idLittleImage].image})`;

            setLikedImage(idLittleImage)
        }
    }

    // Расчет стоимости
    const QuantityPlus = () => {
        let setUpdQuantity = newQuantity + 1

        if (setUpdQuantity > props.information.amount) {
            setnewQuantity(props.information.amount)
            alert("Всего товара на складе - " + props.information.amount)
        } else {
            setnewQuantity(setUpdQuantity)
        }
        setprice(price * newQuantity)
    }
    const QuantityMinus = () => {
        let setUpdQuantity = newQuantity - 1

        if (setUpdQuantity < 1) {
            setnewQuantity(1)
        } else {
            setnewQuantity(setUpdQuantity)
        }
        setprice(price * newQuantity)
    }

    const getProduct = () => {
        let button = document.getElementsByClassName("getProductBasket")[0]
        let button_width = button.style.width

        let total = document.getElementById("basketTotal")

        setTimeout(() => {
            total.style.display = "none"

            button.style.width = "150px"

        }, 1000)
        button.textContent = "Добавлен в корзину"


        basket.updateBasket(props.information.article, newQuantity)

        setTimeout(() => {
            button.textContent = "В корзине"
            button.style.width = button_width
            total.style.display = "flex"
        }, 2000)

    }

    let is_favorite,
        isAgree = like;

    if (isAgree === true) {
        is_favorite = <div id='likePopUpGalery' className={props.information.article} onClick={likedProduct}
                           style={{backgroundImage: `url(${LikeGreenActive})`}}></div>
    } else {
        is_favorite = <div id='likePopUpGalery' className={props.information.article} onClick={likedProduct}
                           style={{backgroundImage: `url(${Like})`}}></div>
    }

    let img,
        haveOnShop,
        total,
        littleImages,
        zoom

    if (props.information.image.length > 0) {
        img = `url(${props.information.image[0].image})`
        littleImages =
            <div className='allImages'>
                {/* Вывод малленьких изображений */}
                {props.information.image.map((item, idex) => (
                    <div className='LittleImage nonactive' key={"allImages" + "LittleImagePopUp" + idex}
                         id={"LittleImagePopUp" + idex} onClick={activateLittleImage}>
                        <div className='img'
                             style={{
                                 backgroundImage: `url(${item.image})`
                             }}></div>
                    </div>
                ))}
            </div>
        zoom =
            <div className='zoom'>
                <button onClick={zoomImage}></button>
            </div>
    }

    if (props.information.amount > 0) {
        haveOnShop =
            <div className='left'>
                <div className='img'></div>
                <span>Есть в наличии</span>
            </div>
        total =
            <div style={{display: "flex"}} className='left'>
                <div className="total" id="basketTotal">
                    <button id='minus' onClick={QuantityMinus}>-</button>
                    <input id={props.information.article} value={newQuantity} disabled></input>
                    <button id='plus' onClick={QuantityPlus}>+</button>
                </div>
                <button className="getProductBasket" id='getProduct' onClick={getProduct}>В корзину</button>
            </div>


    } else {
        haveOnShop =
            <div className='left'>
                <p>Нет в наличии</p>
            </div>
    }

    return (
        <div className='popUpGalery' id={props.namePopUp}>
            <div className='shortInformation'>
                <div className='headerProduct'>
                    <h3>{props.information.name}</h3>
                    <div className='cross' onClick={closePopUpGalery}></div>
                </div>
                <div className='left'>
                    <div className='bigImage' id='toZoom' style={{
                        backgroundImage: img
                    }}>
                        {zoom}
                    </div>

                    <div className='toZoomBigImage' id='toZoomBigImage'>
                        <button className='cross' onClick={() => CloseZoomImage()}></button>
                        <div className='bigimage' id='imgOnZoomBigImage' style={{
                            backgroundImage: img
                        }}></div>
                    </div>

                    {littleImages}

                </div>
                <div className='right'>
                    <div className='availableInStock'>

                        {haveOnShop}
                        <div className='right'>
                            {is_favorite}
                        </div>
                        <div className='clearfix'></div>
                    </div>
                    <div className='shortInfo'>
                        <div className='article'>
                            <span className='name'>Артикул:</span>
                            <span className='status'>{props.information.article}</span>
                        </div>

                        {/* Вывод информации о товаре */}
                        {props.information.characteristic.map((item, index) => (
                            <div className='shortInfoMap' key={"shortInfoMap" + index}>
                                <span className='name'>{item.attribute}:</span>
                                <span className='status'>{item.value}</span>
                            </div>
                        ))}
                    </div>

                    {/* Вывод стоимости */}
                    {/* <div className='Cost'>
                        <span id='costProductFirst'>{price}</span>
                        <span>руб/шт</span>
                    </div> */}

                    {
                        props.information.discount ?
                            <div style={{display: "inline-block", paddingBottom: "10px"}} className='cost'>
                                <p id='cost'>{props.information.discount_price.new_price} руб/шт</p>
                                <div style={{fontSize: "12px", display: "inline-block"}} className="oldCost">
                                    <p style={{
                                        marginRight: 0,
                                        textDecoration: "line-through"
                                    }}>{props.information.discount_price.old_price}руб/шт</p>
                                </div>
                            </div>
                            :
                            <div style={{paddingBottom: "10px"}} className='cost'>
                                <p style={{display: "inline-flex"}} id='cost'>{props.information.price} руб/шт</p>
                            </div>
                    }

                    <div className='total'>
                        {total}
                        <div className='right'>
                            <Link to={{
                                pathname: `/product/${props.information.article}/`
                            }}>
                                <button onClick={closePopUpGalery}>Подробнее</button>
                            </Link>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                </div>
                <div className='clearfix'></div>
            </div>
        </div>
    )
}
