import ProfileMethods from "../ProfileMethods";

class Favourite{
    //{"articles": [], "quantity": 0}
    #profile = new ProfileMethods();
    constructor() {
        this.favourite = JSON.parse(localStorage.getItem("favourite"))

    }

    setDone(done){
        this.favourite.done = done
        localStorage.favourite = JSON.stringify(this.favourite)
    }

    isFavourite(article){
        return this.favourite.articles.indexOf(article) !== -1;
    }

    getIndex(article){
        return this.favourite.articles.indexOf(article);
    }

    setFavourite(value= {}){
        this.setDone(false)
        localStorage.favourite = JSON.stringify(this.favourite)
        let event = new Event('storage');
        event.newValue = Object.assign({}, {"object": "favourite"}, value);
        window.dispatchEvent(event);
        return true
    }

    profileUpdateFavourite(articles){
        let storage_articles =  this.favourite.articles
        this.favourite.articles = Array.from(new Set(storage_articles.concat(articles)))
        this.favourite.quantity = this.favourite.articles.length
        this.setFavourite()
        return storage_articles.filter(item => !articles.includes(item))
    }

    updateFavourite(article){
        let favourite;
        let isFavourite = this.isFavourite(article)
        let value = {}
        if (isFavourite){
            this.favourite.articles.splice(this.getIndex(article), 1)
            this.favourite.quantity -= 1;
            value = {"method": "delete", "article": article}
        }else {
            this.favourite.articles.push(article)
            this.favourite.quantity += 1;
        }
        this.changeProfileFavourite(article)
        this.setFavourite(value)
        return true
    }

    changeProfileFavourite(article){
        if(localStorage.id) {
            if(typeof article === "string") {
                article = [article]
            }
            fetch(global.components.url + "/user/api/user/favourite/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "profile": localStorage.id,
                    "products": article
                }),
            })
        }
        return true
    }

}
export default Favourite;