import React, {Component} from 'react'
import GaleryProducts from '../index/GaleryProducts'

export default class Recomendation extends Component {
    render() {
        return (
            <div>
                <GaleryProducts
                    basket={this.props.basket}
                    favourite={this.props.favourite}
                    title="Рекомендуем"
                    p="если вы создаете ювелирную бижутерию на заказ или для себя, занимаетесь бизнесом или имеете увлекательное хобби, связанное с украшениями, в нашем интернет-магазине вы найдете всё, что вам необходимо"
                    updateDataProducts={this.props.updateDataProducts}
                    updateLikeProduct={this.props.updateLikeProduct}
                />
            </div>
        )
    }
}
