import "./text.css"
const Text = (props) => {
    return(
        <div class="text__text content" >
            <div class="text__text-container" dangerouslySetInnerHTML={{__html: props.text}}>
            </div>
        </div>
    )

}
export default Text;