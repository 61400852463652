import React from 'react'
import Baners from '../globalComponents/Baners'
import InstagramExamples from '../index/InstagramExamples'
import './css/Contact.css'

export default function Contact() {
    return (
        <div className='Contact'>
            <div className='content'>
                <div className='title'>
                    <div className='left'>
                        <Baners
                            noFollow={"none"}
                        />
                    </div>
                    <div className='right'>
                        <div className='contactsInfo'>
                            <h3>Контакты</h3>
                            <div className='info'>
                                <p><span>Телефон: </span><a target="_blank" href={"tel:" + global.components.phone[0]}>{global.components.phone[1]}</a></p>
                                <p><span>Почта: </span><a target="_blank" href={"mailto:" + global.components.gmail[0]}>{global.components.gmail[1]}</a></p>
                                <p><a target="_blank" href={global.components.vk[0]}><span>{ global.components.vk[1]}</span></a></p>
                                <p><a target="_blank" href={global.components.tg[0]}><span>{ global.components.tg[1]}</span></a></p>
                                <p><a target="_blank" href={global.components.whatsapp[0]}><span>{ global.components.whatsapp[1]}</span></a></p>
                                <p><a target="_blank" href={global.components.viber[0]}><span>{ global.components.viber[1]}</span></a></p>
                            </div>
                        </div>
                    </div>
                    <div className='clearfix'></div>
                </div>
                <div className='followSoc'>
                    <InstagramExamples />
                </div>
            </div>
        </div>
    )
}
