import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Cost from './componentsFiltre/Cost'
import FiltreCompontent from './componentsFiltre/FiltreCompontent'

import './css/filtres.css'

export default class Filtres extends Component {
    state = {
        activeFiltreComp: null, products: this.props.products, params: new URLSearchParams(window.location.search),
    }


    componentDidMount() {
        let ordering = this.state.params.getAll("ordering")

        if (ordering.indexOf("-price") !== -1) {
            document.getElementById('arrCost').style.transform = 'rotate(180deg)'
        }
        if (ordering.indexOf('popularity') !== -1) {
            document.getElementById('arrPopularity').style.transform = 'rotate(180deg)'
        }
    }

    updateActiveFiltre = (newActiveFiltreComp) => {
        this.setState({
            activeFiltreComp: newActiveFiltreComp,
        })
    }

    OpenCategoryMenu = () => {
        document.getElementById("CategoryMenu").classList.remove("closeCategoryMenu");
    }
    OpenFiltreMenu = () => {
        document.getElementById("filtreMenu").classList.remove("closeFiltreMenu");
        document.getElementById("filtreMenu").style.display = "flex"
    }
    closefiltreMenu = () => {
        document.getElementById("filtreMenu").classList.add("closeFiltreMenu");
        document.getElementById("filtreMenu").style.display = "none"
    }

    pushFiltres = () => {
        window.location.search = this.state.params
    }

    clearFiltres = () => {
        this.state.params.forEach((value, name, searchParams) => {
            this.state.params.delete(name);
        })
        this.state.params.forEach((value, name, searchParams) => {
            this.state.params.delete(name);
        })

        window.location.search = this.state.params


    }

    sortPopularity = async () => {
        let oredering = this.state.params.getAll("ordering")
        let params = []
        if (oredering.indexOf("price") !== -1) {
            params.push("price")
        } else if (oredering.indexOf("-price") !== -1) {
            params.push("-price")
        }

        if (document.getElementById('arrPopularity').style.transform === "rotate(0deg)" || document.getElementById('arrPopularity').style.transform === '') {
            params.push("popularity")
            document.getElementById('arrPopularity').style.transform = 'rotate(180deg)'
        } else if (document.getElementById('arrPopularity').style.transform === "rotate(180deg)") {
            document.getElementById('arrPopularity').style.transform = 'rotate(0deg)'
            params.push("-popularity")
        }
        this.state.params.set("ordering", params)
        window.location.search = this.state.params
    }

    sortCost = async () => {
        let oredering = this.state.params.getAll("ordering")
        let params = []
        if (oredering.indexOf("popularity") !== -1) {
            params.push("popularity")
        } else if (oredering.indexOf("popularity") !== -1) {
            params.push("-popularity")
        }
        this.state.params.delete("ordering")
        if (document.getElementById('arrCost').style.transform === 'rotate(0deg)' || document.getElementById('arrCost').style.transform === '') {
            params.push("-price")

            document.getElementById('arrCost').style.transform = 'rotate(180deg)'

        } else if (document.getElementById('arrCost').style.transform === "rotate(180deg)") {
            params.push("price")
            document.getElementById('arrCost').style.transform = 'rotate(0deg)'
        }
        this.state.params.set("ordering", params)
        window.location.search = this.state.params

    }

    searchData = () => {
        let val = document.getElementById('searchOnLetter').value;
        if (val) {
            window.location = ("/search/" + val + '/')
        }
        //    this.props.setSearchProducts(val)
    }

    render() {
        if (this.props.ParrentName === 'SearchCatalogData') {
            return (<div className='FiltresBlock'>

                <button id='CategoryButton' onClick={this.OpenCategoryMenu}>Категории каталога</button>

                <div id='filtreMenu' className='closeFiltreMenu'>
                    <div className='information'>
                        <div className='title'>
                            <Link to="/">
                                <div className='logo'></div>
                            </Link>
                            <button className='crossMenu' id='closefiltreMenu' onClick={this.closefiltreMenu}>
                                <span></span></button>
                        </div>
                        <div className='info'>
                            <h2>Фильтр:</h2>

                            <div className='buttons'>
                                <button className='push' onClick={this.pushFiltres}>Показать</button>
                                <button className='clear' onClick={this.clearFiltres}>Сбросить</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='Filtres'>
                    <input style={{ border: "solid 1px", padding: "5px"}} required id='searchOnLetter' placeholder='Поиск'></input>
                    <button style={{ paddingLeft: "10px"}} onClick={this.searchData}>Поиск</button>
                </div>


                <div className='sort'>
                    {/*<button className='filtreButton' id='filtreButton' onClick={this.OpenFiltreMenu}>
                        <div className='img'></div>
                        Фильтр
                    </button>*/}
                    <div>
                        <button className='color' onClick={this.sortPopularity}>
                            <p>По популярности</p>
                            <div className='arrDown' id='arrPopularity'></div>
                        </button>
                        <button className='color' onClick={this.sortCost}>
                            <p>По цене</p>
                            <div className='arrDown' id='arrCost'></div>
                        </button>
                    </div>
                </div>
            </div>)
        } else {
            return (<div className='FiltresBlock'>

                <button id='CategoryButton' onClick={this.OpenCategoryMenu}>Категории каталога</button>

                <div id='filtreMenu' className='closeFiltreMenu'>
                    <div className='information'>
                        <div className='title'>
                            <Link to="/">
                                <div className='logo'></div>
                            </Link>
                            <button className='crossMenu' id='closefiltreMenu' onClick={this.closefiltreMenu}>
                                <span></span></button>
                        </div>
                        <div className='info'>
                            <h2>Фильтр:</h2>


                            {this.props.filtres ? this.props.filtres.map((item, index) => (<FiltreCompontent
                                key={"FiltreInfoCompontent" + index}
                                ParrentName={"CategoryMenu"}
                                params={this.state.params}
                                filtres={item}
                                activeFiltreComp={this.state.activeFiltreComp}
                                updateActiveFiltre={this.updateActiveFiltre}

                            />)) : <div></div>}

                            <Cost

                                activeFiltreComp={this.state.activeFiltreComp}
                                updateActiveFiltre={this.updateActiveFiltre}/>
                        </div>


                        <div className='buttons'>
                            <button className='push' onClick={this.pushFiltres}>Показать</button>
                            <button className='clear' onClick={this.clearFiltres}>Сбросить</button>
                        </div>
                    </div>
                </div>

                <div className='Filtres'>
                    <div className='left'>
                        <p>Фильтр:</p>

                        <Cost
                            changeFilterPriceGTE={this.props.changeFilterPriceGTE}
                            changeFilterPriceLTE={this.props.changeFilterPriceLTE}
                            changeProducts={this.props.changeProducts}
                            searchFiltres={this.props.searchFiltres}
                            changeFilterData={this.props.changeFilterData}
                            changeCategoryIn={this.props.changeCategoryIn}
                            changeCharacteristicIn={this.props.changeCharacteristicIn}
                            changeFilterPrice={this.props.changeFilterPrice}
                            changeFilterPopularity={this.props.changeFilterPopularity}
                            activeFiltreComp={this.state.activeFiltreComp}
                            updateActiveFiltre={this.updateActiveFiltre}
                            searchFiltreData={this.props.searchFiltreData}
                            changeDiscount={this.props.changeDiscount}
                        />
                        {this.props.filtres ?
                            this.props.filtres.map((item, index) => (
                            <FiltreCompontent
                                key={"FiltreCompontent" + index}
                                filtres={item}
                                searchFiltres={this.props.searchFiltres}
                                activeFiltreComp={this.state.activeFiltreComp}
                                updateActiveFiltre={this.updateActiveFiltre}
                            />)) : <div></div>}
                        <button className='clear' onClick={this.clearFiltres}>Сбросить</button>

                    </div>
                    <div className='right'>
                        <button className='morefiltres' id='morefiltres' onClick={this.OpenFiltreMenu}>БОЛЬШЕ
                            ФИЛЬТРОВ
                        </button>
                    </div>
                </div>


                <div className='sort'>
                    <button className='filtreButton' id='filtreButton' onClick={this.OpenFiltreMenu}>
                        <div className='img'></div>
                        Фильтр
                    </button>


                    <div>
                        <button className='color' onClick={this.sortPopularity}>
                            <p>По популярности</p>
                            <div className='arrDown' id='arrPopularity'></div>
                        </button>
                        <button className='color' onClick={this.sortCost}>
                            <p>По цене</p>
                            <div className='arrDown' id='arrCost'></div>
                        </button>
                    </div>

                </div>
            </div>)
        }
    }
}
