import React, { Component } from 'react'
import FormAvtorization from '../Components/Pages/avtorization/FormAuthorization'
import Way from '../Components/Pages/globalComponents/Way'
//HelmetetaTags from "react-meta-tags";
import { Helmet } from 'react-helmet';

export default class Avtorization extends Component {
  state = {
    way: ["Mary Furnitura", "Авторизация"],
    title: "Авторизация",
  }
  render() {
    return (
      <div className='Avtorization'>
          <Helmet>
              <title>Авторизация</title>
          </Helmet>
        <Way
          title={this.state.title}
          way={this.state.way}
        />
        <FormAvtorization />
      </div>
    )
  }
}
