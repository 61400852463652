import React, { useState, useEffect } from "react";
import "./css/Header.css";
import { Link, useLocation } from "react-router-dom";

export default function Header(props) {
  const [basket, setBasket] = useState();
  const [liked, setLiked] = useState();

  const [quantityOnBasket, setQuantityOnBasket] = useState();
  const [quantityOnLiked, setQuantityOnLiked] = useState();
  const location = useLocation();

  useEffect(() => {
    closeMenu();
  }, [location]);

  useEffect(() => {
    setBasket(props.quantityOnBasket);
    setLiked(props.quantityOnLiked);
  }, [basket, liked, props.quantityOnBasket, props.quantityOnLiked]);

  useEffect(() => {
    if (basket) {
      setQuantityOnBasket(<p className="quantityOnBasket">{basket}</p>);
    }
    if (liked) {
      setQuantityOnLiked(<p className="quantityOnLiked">{liked}</p>);
    }
  }, [basket, liked, props.quantityOnBasket, props.quantityOnLiked]);

  document.addEventListener("scroll", function (e) {
    var scroll = window.pageYOffset;

    if (scroll >= 40) {
      document.getElementById("scrollHeader").style.display = "flex";
      document.getElementById("staticHeader").style.display = "none";
      /*
      document.getElementById('header').style.top = "-40px";
*/
      document.getElementById("header").style.backgroundColor =
        "rgba(255, 255, 255, 0.7)";
      /*
      document.getElementById("darkburger").style.top = "40px";
*/
    }

    if (scroll === 0) {
      if (document.getElementById("scrollHeader") !== null) {
        document.getElementById("scrollHeader").style.display = "none";
      }

      document.getElementById("staticHeader").style.display = "flex";
      document.getElementById("header").style.top = "0px";
      document.getElementById("header").style.backgroundColor =
        "rgba(255, 255, 255, 0)";
      document.getElementById("darkburger").style.top = "0";
    }
  });
  document.addEventListener("transitionend", function (e) {
    let transitionend = document.getElementById("searchBlock").style.top;
    if (transitionend === "-180px") {
      document.getElementById("headerBg").classList.remove("hover");
    }
  });

  const openSerch = () => {
    if (document.getElementById("searchBlock").style.top === "-180px") {
      document.getElementById("searchBlock").style.top = "180px";
      document.getElementById("searchBlock").style.opacity = "1";
      document.getElementById("headerBg").classList.add("hover");
    } else if (document.getElementById("searchBlock").style.top === "180px") {
      closeSearchBlock();
    }
  };

  const closeSearchBlock = () => {
    document.getElementById("searchBlock").style.top = "-180px";
    document.getElementById("searchBlock").style.opacity = "0";
  };

  const openMenu = () => {
    document.getElementById("darkburger").style.display = "block";

    setTimeout(() => {
      trans();
    }, 0);
  };
  const trans = () => {
    document.getElementById("darkburger").style.opacity = "1";
    document.getElementById("burger").style.margin = "0";
  };

  const closeMenu = () => {
    document.getElementById("burger").style.marginLeft = "-500px";
    document.getElementById("darkburger").style.opacity = "0";

    document.addEventListener("transitionend", function (e) {
      let opacity = document.getElementById("darkburger").style.opacity;
      if (opacity === "0") {
        document.getElementById("darkburger").style.display = "none";
      }
    });
  };

  const Search = () => {
    let val = document.getElementById("serchData").value;
    if (val) {
      window.location = "/search/" + val + "/";
    }
  };

  let name, linkOnRegProf;
  if (props.profileName === undefined) {
    linkOnRegProf = "registration";
  } else {
    linkOnRegProf = "profile";
    name = (
      <li style={{ width: "auto" }} className="ProfileName">
        {props.profileName}
      </li>
    );
  }

  // }
  return (
    <div className="header" id="header" style={{ top: "0px" }}>
      <div id="headerBg">
        <div className="phoneBg" id="phoneBg">
          <div className="content">
            <div className="right phone">
              <p id="phoneOnHeader">
                <a href={"tel:" + global.components.phone[0]}>
                  {global.components.phone[1]}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          className="darkburger"
          id="darkburger"
          style={{ display: "none", opacity: "0" }}
        >
          <ul className="burger" id="burger">
            <div className="title">
              <Link to="/">
                <div className="logo"></div>
              </Link>
              <button onClick={closeMenu} className="cross">
                <span></span>
              </button>
            </div>
            <div className="links">
              <li id="Catalog">
                <Link to="/catalog/">Каталог</Link>
              </li>
              <li id="Sale">
                <Link to="/discounts/">Распродажа</Link>
              </li>
              <li id="Delivery">
                <Link to="/garantdeliveryandpayment/">Доставка и оплата</Link>
              </li>
              <li id="Master_classes">
                <a target="_blank" href={global.components.course[0]}>
                  Мастер-классы и курсы
                </a>
              </li>
              <li id="Contacts">
                <Link to="/contacts/">Контакты</Link>
              </li>
            </div>
            <div className="likedProd">
              <Link to="/likedProducts/">
                <div className="img"></div>
                <p>Отложенные товары</p>
              </Link>
            </div>
            <div className="telephone">
              <a href={"tel:" + global.components.phone[0]}>
                {global.components.phone[1]}
              </a>
            </div>
          </ul>
        </div>

        <div className="content">
          <header>
            <div className="header__soc">
              <a
                target="_blank"
                href={global.components.whatsapp[0]}
                className="whatsapp"
              ></a>
              <a
                target="_blank"
                href={global.components.tg[0]}
                className="telegram"
              ></a>
              <a
                target="_blank"
                href={global.components.vk[0]}
                className="vk"
              ></a>
              <a href={"tel:" + global.components.phone[0]}>
                {global.components.phone[1]}
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=R0UEIE5ape4"
              >
                Как оформить заказ?
              </a>
            </div>
            <div id="scrollHeader" style={{ display: "none" }}>
              <div className="left">
                <button id="scroll" onClick={openMenu} className="burger">
                  <span></span>
                </button>
                <Link to="/">
                  <div className="logo" id="logo"></div>
                </Link>
                <ul id="ul">
                  <li id="Catalog">
                    <Link to="/catalog/">Каталог</Link>
                  </li>
                  <li id="Sale">
                    <Link to="/discounts/">Распродажа</Link>
                  </li>
                  <li id="Delivery">
                    <Link to="/garantdeliveryandpayment/">
                      Доставка и оплата
                    </Link>
                  </li>
                  <li id="Master_classes">
                    <a target="_blank" href={global.components.course[0]}>
                      Мастер-классы и курсы
                    </a>
                  </li>
                  <li id="Contacts">
                    <Link to="/contacts/">Контакты</Link>
                  </li>
                </ul>
              </div>
              <div className="right">
                <ul>
                  <li className="search" id="search" onClick={openSerch}></li>
                  <Link to="/basket/">
                    <li className="basket" id="basket">
                      {quantityOnBasket}
                      {/* <p className='quantityOnBasket'>
                      {QOnBasket}
                    </p> */}
                    </li>
                  </Link>
                  <Link to="/likedProducts/" className="likedProducts">
                    <li className="liked" id="liked">
                      {quantityOnLiked}
                    </li>
                  </Link>
                  <Link to={"/" + linkOnRegProf + "/"}>
                    <li className="profile" id="profile"></li>
                    {name}
                  </Link>
                </ul>
              </div>
            </div>

            <div id="staticHeader" style={{ display: "flex" }}>
              <div className="left">
                <button id="static" onClick={openMenu} className="burger">
                  <span></span>
                </button>
                <Link to="/">
                  <div className="logo" id="logo"></div>
                </Link>
                <ul id="ul">
                  <li id="Catalog">
                    <Link to="/catalog/">Каталог</Link>
                  </li>
                  <li id="Sale">
                    <Link to="/discounts/">Распродажа</Link>
                  </li>
                  <li id="Delivery">
                    <Link to="/garantdeliveryandpayment/">
                      Доставка и оплата
                    </Link>
                  </li>
                  <li id="Master_classes">
                    <a target="_blank" href={global.components.course[0]}>
                      Мастер-классы и курсы
                    </a>
                  </li>
                  <li id="Contacts">
                    <Link to="/contacts/">Контакты</Link>
                  </li>
                </ul>
              </div>
              <div className="right">
                <ul>
                  <li className="search" id="search" onClick={openSerch}></li>
                  <Link to="/basket/">
                    <li className="basket" id="basket">
                      {quantityOnBasket}
                      {/* <p className='quantityOnBasket'>
                      {QOnBasket}
                    </p> */}
                    </li>
                  </Link>
                  <Link to="/likedProducts/" className="likedProducts">
                    <li className="liked" id="liked">
                      {quantityOnLiked}
                    </li>
                  </Link>
                  <Link to={"/" + linkOnRegProf + "/"}>
                    <li className="profile" id="profile"></li>
                    {name}
                  </Link>
                </ul>
              </div>
            </div>
          </header>
        </div>

        <div className="clearfix"></div>
      </div>
      <div className="searchBlock" id="searchBlock" style={{ top: "-180px" }}>
        <div className="content">
          <form
            onSubmit={(e) => {
              Search();
              e.preventDefault();
            }}
            className="search"
          >
            <input id="serchData" placeholder="Поиск"></input>
            <button type="submit" id="sub"></button>
          </form>
          <div className="right" id="closeSearchBlock">
            <button className="cross" onClick={closeSearchBlock}></button>
          </div>
        </div>
      </div>
    </div>
  );
}
