import React, { Component } from "react";
import "./css/GaleryProducts.css";
import PopUpGalery from "./Slider/PopUpGalery";
import { Link } from "react-router-dom";

import SliderJq from "./Slider/SliderJq";

export default class GaleryProducts extends Component {
  state = {
    error: null,
    isLoaded: false,
    items: [],

    quantityToPup: 1,
    priceToPup: null,
  };

  componentDidMount() {
    fetch(global.components.url + "/product/api/product/?format=json&page_size=45")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            items: result["results"],
            isLoaded: true,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  updateData = (ProductInfo, quantity, price) => {
    this.setState({
      ProductPopUp: ProductInfo,
      quantityToPup: quantity,
      priceToPup: price,
    });
  };

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <p>Error {error.message} </p>;
    } else if (!isLoaded) {
      return <p>Loading...</p>;
    } else {
      return (
        <div className="GaleryProducts">
          <div className="content">
            <div className="topOfGalery">
              <div className="left">
                <h2>{this.props.title}</h2>
              </div>
              <div className="right">
                <p>{this.props.p}</p>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="line"></div>
            <SliderJq
              basket={this.props.basket}
              favourite={this.props.favourite}
              items={this.state.items}
              updateData={this.updateData}
            />
            <div className="moreProducts">
              <Link to="/catalog/">Посмотреть больше товаров</Link>
            </div>
          </div>

          <div className="popUpDataProduct" id="popUpDataProduct"></div>
        </div>
      );
    }
  }
}
