import "./nullstyle.css";
import "./normalize.css";
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import "./config"
import App from "./App";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>
);
