import React, {useState} from 'react'
import Way from '../Components/Pages/globalComponents/Way';
import MakingAnOrderContent from '../Components/Pages/MakingAnOrder/MakingAnOrderContent';
import ProfileMethods from "../Methods/ProfileMethods";
import ScrollUp from '../Components/StaticComponents/ScrollUp';

export default function MakingAnOrder(props) {
    const [title] = useState("Оформление заказа");
    const [way] = useState(["Mary Furnitura", "Оформление заказа"]);



    return (
        <div className='MakingAnOrder' id='body'><ScrollUp/>
            <Way
                title={title}
                way={way}
            />
            <MakingAnOrderContent
                profileData={props.profile}
                idPersone={props.profile.id}
            />
        </div>
    )
}
