import React, { useState, useCallback, useEffect } from 'react'
import OrderProductComponent from '../../OrderProduct/OrderProductComponent'
import './css/InfoOrder.css'
export default function InfoOrder(props) {

    // info Order
    const [NumberOrder, setNumberOrder] = useState()
    const [StartData, setStartData] = useState()
    const [Cost, setCost] = useState()
    const [ProductsInOrder, setProductsInOrder] = useState()
    const [Products, setProductS] = useState()
    const [FinishData, setFinishData] = useState()

    const [shadowId, setshadowId] = useState()
    const [moreInfoOrder, setmoreInfoOrder] = useState()

    const OpenShadowInfo = () => {
        if (document.getElementById(shadowId).style.display === "none") {
            document.getElementById(shadowId).style.display = "block";
            document.getElementById(moreInfoOrder).style.backgroundColor = "#fff";
            document.getElementById(moreInfoOrder).style.color = "#323334";
            document.getElementById(moreInfoOrder).style.borderColor = "#323334";
            document.getElementById(moreInfoOrder).textContent = "Скрыть";
        } else {
            document.getElementById(shadowId).style.display = "none";
            document.getElementById(moreInfoOrder).style.backgroundColor = "#5C7D69";
            document.getElementById(moreInfoOrder).style.color = "#fff";
            document.getElementById(moreInfoOrder).style.borderColor = "#5C7D69";
            document.getElementById(moreInfoOrder).textContent = "Подробнее о заказе";
        }
    }

    useEffect(() => {
        if (props.OrderProductInfo !== undefined && props.OrderProductInfo !== null) {
            setshadowId("shadowId" + props.OrderProductInfo.id)
            setmoreInfoOrder("moreInfoOrder" + props.OrderProductInfo.id)
            if (NumberOrder === undefined) {

                // console.log(props.OrderProductInfo)
                setNumberOrder(props.OrderProductInfo.id);
                setStartData(props.OrderProductInfo.date);

                let cost,
                    quantity,
                    fullCost = 0,
                    product = [];

                props.OrderProductInfo.basket.positions.forEach((item) => {
                    let infor = { info: item.product, quantity: item.quantity }
                    product.push(infor)
                    quantity = item.quantity
                    if (item.product.discount === null) {
                        cost = item.product.price
                    } else {
                        cost = item.product.discount_price.new_price
                    }
                    fullCost = fullCost + (quantity * cost);
                })
                setProductS(product)
                setCost(fullCost)
                setProductsInOrder(props.OrderProductInfo.basket.positions.length)
                setFinishData(props.OrderProductInfo.date)
            }
        }
    })

    if (props.OrderProductInfo !== null) {
        return (
            <div className='infoOrder'>
                <div className='titleInfo'>
                    <div className='title'>
                        <h3>Заказ №{NumberOrder}</h3>
                    </div>
                    <div className='info'>
                        <div className='StatusOrder'>
                            <p>Дата оформления: {StartData}</p>
                            <p className='status'>Статус заказа: <span>{props.status}</span></p>
                            <p className='cost'>Сумма заказа: <span>{Cost} руб.</span></p>
                            <p className='ProductsInOrder'>Товаров - {ProductsInOrder}</p>
                        </div>
                        <div className='FinishDataOrder'>
                            <p>Заказ выполнен {FinishData}</p>
                        </div>
                        <div className='button'>
                            <button onClick={OpenShadowInfo} id={moreInfoOrder} className='moreInfoOrder'>Подробнее о заказе</button>
                        </div>
                    </div>
                </div>
                <div className='ShadowInfo' id={shadowId} style={{ display: "none" }}>
                    <OrderProductComponent
                        ParrentName={"infoOrder"}
                        OrderProductInfo={props.OrderProductInfo}
                        updateProductsOrders={props.updateProductsOrders}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <p>Загрузка...</p>
        )
    }
}