import React, { useState, useEffect } from 'react'
import eyeOpen from './img/eye.svg'
import eyeClose from './img/eye-closed.svg'

const Password = (props) => {
    const [eye, setEye] = useState(eyeClose);
    const [typeInput, setType] = useState("password");

    const changeEye = (e) => {
        if(eye === eyeClose){
            setEye(eyeOpen);
            setType("text")
        }else {
            setEye(eyeClose);
            setType("password")
        }

    }

    return (
        <span style={{display: "inline-grid", width: props.width || "fit-content" }}>
            <input required className={`passwordEye ${props.className}`} id={props.id}
                   onChange={props.onChange} onKeyPress={props.onKeyPress}
                   name={props.name} type={typeInput}>
            </input>

            <button style={{backgroundImage: `url(${eye})`,
                position: "relative",
                margin: "-25px 0 0px 90%",
                width: "15px", height: "15px"}}
                onClick={(e) => {changeEye(e)}}
            />

        </span>


    )
}

export default Password;