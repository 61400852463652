import React, { useState } from 'react'
import './css/AboutUsContent.css'

export default function AboutUsContent() {
  return (
    <div className='AboutUsContent'>
      <div className='content'>
        <div className='ava'></div>
        <div className='informmation'>
          <div className='mary'>
            <div className='topContent'>
              <div className='img'></div>
              <div className='text'>
                <div className='buisnes'>
                  <p>Я хотела создать место, где каждый, кто занимается творчеством, смог бы купить натуральные камни для украшений, качественную фурнитуру и </p>
                  <p>все необходимое для своего красивого хобби или бизнеса. </p>
                  <p>Пройдя путь от начинающего автора ювелирной бижутерии до владелицы бренда, чьи изделия продаются на Wildberries и OZON, я поняла, как сложно найти действительно качественные и недорогие материалы, которые поднимут ваши украшения на премиальный уровень, сделают их популярными.</p>
                </div>
                <div className='fio'>
                  <p className='name'>Мария Владыкина</p>
                  <p className='buisnes'>создательница бренда Marianna Jewelry и автор обучающего курса по изготовлению ювелирной бижутерии своими руками  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='shortInfo'>
            <p>Интернет-магазин фурнитуры для бижутерии Mari Jewelry работает, чтобы предоставить своим клиентам доступ к лучшей продукции от производителей со всего мира, а также к эксклюзивным украшениям Marianna Jewelry. </p>
            <p>Мы работаем с физическими и юридическими лицами. </p>
            <p>Продажа товаров ведется оптом и в розницу.</p>
          </div>
        </div>
        <div style={{padding: "50px 0 0 0"}} className='Materials'>
          <h2>Материалы</h2>
          <p style={{padding: "15px 0", lineHeight: "20px"}}>Для продажи мы отбираем фурнитуру для украшений высокого качества от лучших производителей из Южной Кореи, Гонконга и со всего мира. Металлические элементы выполнены из сплавов, безопасных для постоянного ношения, покрытых золотом, серебром или родием. Такая фурнитура не окисляется и не теряет привлекательный внешний вид даже через несколько лет ношения украшений.</p>
          <p style={{lineHeight: "20px"}}>Большой выбор натуральных камней и хрустальных бусин всех цветов радуги, фианитов и цирконов позволит вам подобрать камни для создания ювелирной бижутерии премиум-класса.</p>
        </div>
      </div>
    </div>
  )
}
