import React, { useEffect, useState } from "react";
import "./css/Header.css";
import { Link, useLocation } from "react-router-dom";

export default function StaticHeader(props) {
  const [quantityOnBasket, setQuantityOnBasket] = useState();
  const [quantityOnLiked, setQuantityOnLiked] = useState();
  const location = useLocation();

  useEffect(() => {
    closeMenu();
  }, [location]);

  useEffect(() => {
    if (props.quantityOnBasket) {
      setQuantityOnBasket(
        <p className="quantityOnBasket">{props.quantityOnBasket}</p>
      );
    }
    if (props.quantityOnLiked) {
      setQuantityOnLiked(
        <p className="quantityOnLiked">{props.quantityOnLiked}</p>
      );
    }
  }, [props.quantityOnLiked, props.quantityOnBasket]);

  // fix error basket
  const remoweErorOnBasket = () => {
    if (document.getElementById("HaventOnBasket") !== null) {
      document.getElementById("HaventOnBasket").classList.remove("active");
    }
  };

  document.addEventListener("scroll", function (e) {
    var scroll = window.pageYOffset;
    if (document.getElementById("header") !== null) {
      if (scroll >= 40) {
        document.getElementById("header").style.top = "-40px";
        document.getElementById("header").style.backgroundColor =
          "rgba(255, 255, 255, 0.7)";
      }

      if (scroll === 0) {
        document.getElementById("header").style.top = "0px";
        document.getElementById("header").style.backgroundColor =
          "rgba(255, 255, 255, 0)";
      }
    }
  });

  document.addEventListener("transitionend", function (e) {
    let transitionend = document.getElementById("searchBlock").style.top;
    if (transitionend === "-125px") {
      document.getElementById("headerBg").classList.remove("hover");
    }
  });

  const openSerch = () => {
    if (document.getElementById("searchBlock").style.top === "-180px") {
      document.getElementById("searchBlock").style.top = "180px";
      document.getElementById("searchBlock").style.opacity = "1";
      document.getElementById("headerBg").classList.add("hover");
    } else if (document.getElementById("searchBlock").style.top === "180px") {
      closeSearchBlock();
    }
  };
  const closeSearchBlock = () => {
    document.getElementById("searchBlock").style.top = "-180px";
    document.getElementById("searchBlock").style.opacity = "0";
  };

  const openMenu = () => {
    document.getElementById("darkburger").style.display = "block";

    setTimeout(() => {
      trans();
    }, 0);
  };
  const trans = () => {
    document.getElementById("darkburger").style.opacity = "1";
    document.getElementById("burger").style.margin = "0";
  };

  const closeMenu = () => {
    document.getElementById("burger").style.marginLeft = "-500px";
    document.getElementById("darkburger").style.opacity = "0";

    document.addEventListener("transitionend", function (e) {
      let opacity = document.getElementById("darkburger").style.opacity;
      if (opacity === "0") {
        document.getElementById("darkburger").style.display = "none";
      }
    });
  };

  const Search = () => {
    let val = document.getElementById("serchDataState").value;
    window.location = "/search/" + val + "/";
  };

  let name, linkOnRegProf;
  if (props.profileName === undefined) {
    linkOnRegProf = "registration";
  } else if (props.profileName) {
    linkOnRegProf = "profile";
    name = (
      <li style={{ width: "auto" }} className="ProfileName">
        {props.profileName}
      </li>
    );
  }

  if (props.link !== undefined) {
    let id = "Dark" + props.link;
    if (document.getElementById(id) !== null) {
      document.getElementById(id).classList.add("active");
    }
  }

  return (
    <div className="header" id="header" style={{ top: "0px" }}>
      <div id="scrollHeader" style={{ position: "fixed" }}></div>
      <div id="staticHeader" style={{ position: "fixed" }}></div>
      <div id="headerBg">
        <div className="phoneBg" id="phoneBg">
          <div className="content">
            <div className="right phone">
              <p id="phoneOnHeader" style={{ color: "#000" }}>
                <a href={"tel:" + global.components.phone[0]}>
                  {global.components.phone[1]}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          className="darkburger"
          id="darkburger"
          style={{ display: "none", opacity: "0" }}
        >
          <ul className="burger" id="burger">
            <div className="title">
              <Link to="/">
                <div className="logo"></div>
              </Link>
              <button onClick={closeMenu} className="cross">
                <span></span>
              </button>
            </div>
            <div className="links">
              <li>
                <Link to="/catalog" id="DarkCatalog">
                  Каталог
                </Link>
              </li>
              <li>
                <Link to="/discounts" id="DarkSale">
                  Распродажа
                </Link>
              </li>
              <li>
                <Link to="/garantdeliveryandpayment" id="DarkDelivery">
                  Доставка и оплата
                </Link>
              </li>
              <li>
                <a
                  target="_blank"
                  href={global.components.course[0]}
                  id="DarkMaster_classes"
                >
                  Мастер-классы и курсы
                </a>
              </li>
              <li>
                <Link to="/contacts" id="DarkContacts">
                  Контакты
                </Link>
              </li>
            </div>
            <div
              onClick={() => (document.location = "/likedProducts")}
              className="likedProd"
            >
              <Link to="/likedProducts">
                <div className="img"></div>
                <p>Отложенные товары</p>
              </Link>
            </div>
            <div className="telephone">
              <a href={"tel:" + global.components.phone[0]}>
                {global.components.phone[1]}
              </a>
            </div>
          </ul>
        </div>

        <div className="content">
          <header>
            <div className="header__soc">
              <a
                target="_blank"
                href={global.components.whatsapp[0]}
                className="whatsapp"
              ></a>
              <a
                target="_blank"
                href={global.components.tg[0]}
                className="telegram"
              ></a>
              <a
                target="_blank"
                href={global.components.vk[0]}
                className="vk"
              ></a>
              <a href={"tel:" + global.components.phone[0]}>
                {global.components.phone[1]}
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=R0UEIE5ape4"
              >
                Как оформить заказ?
              </a>
            </div>
            <div id="Header" style={{ display: "flex" }}>
              <div className="left">
                <button id="scroll" onClick={openMenu} className="burger">
                  <span></span>
                </button>
                <Link to="/">
                  <div className="logo" id="logo"></div>
                </Link>
                <ul id="ul">
                  <li id="Catalog">
                    <Link to="/catalog/">Каталог</Link>
                  </li>
                  <li id="Sale">
                    <Link to="/discounts/">Распродажа</Link>
                  </li>
                  <li id="Delivery">
                    <Link to="/garantdeliveryandpayment/">
                      Доставка и оплата
                    </Link>
                  </li>
                  <li id="Master_classes">
                    <a target="_blank" href={global.components.course[0]}>
                      Мастер-классы и курсы
                    </a>
                  </li>
                  <li id="Contacts">
                    <Link to="/contacts/">Контакты</Link>
                  </li>
                </ul>
              </div>
              <div className="right">
                <ul>
                  <li className="search" id="search" onClick={openSerch}></li>
                  <Link to="/basket/" onClick={remoweErorOnBasket}>
                    <li className="basket" id="basket">
                      {quantityOnBasket}
                    </li>
                  </Link>
                  <Link
                    to="/likedProducts/"
                    className="likedProducts"
                    onClick={remoweErorOnBasket}
                  >
                    <li className="liked" id="liked">
                      <span>{quantityOnLiked}</span>
                    </li>
                  </Link>
                  <Link to={"/" + linkOnRegProf + "/"}>
                    <li className="profile" id="profile"></li>
                    <span>{name}</span>
                  </Link>
                </ul>
              </div>
            </div>
          </header>
        </div>

        <div className="clearfix"></div>
      </div>

      <div className="searchBlock" id="searchBlock" style={{ top: "-180px" }}>
        <div className="content">
          <form
            onSubmit={(e) => {
              Search();
              e.preventDefault();
            }}
            className="search"
          >
            <input id="serchDataState" placeholder="Поиск"></input>
            <button type="submit" id="sub"></button>
          </form>
          <div className="right" id="closeSearchBlock">
            <button className="cross" onClick={closeSearchBlock}></button>
          </div>
        </div>
      </div>
    </div>
  );
}
