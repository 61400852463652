import React, {useState, useEffect} from 'react'
import CatalogContent from '../Components/Pages/catalog/CatalogContent';
import Way from '../Components/Pages/globalComponents/Way';
//import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';

const SearchCatalogData = (props) => {
    const [input] = useState(decodeURIComponent(window.location.href.split('/')[4]));

    const [products, setproducts] = useState([])
    const [serchData, setserchData] = useState()
    const [category, setCategory] = useState([])
    const [way] = useState(["Mary Furnitura", "Каталог", input])
    const [title] = useState("Каталог товаров")
    const [isProduct, setIsProduct] = useState(false)
    const [isCategory, setIsCategory] = useState(false)
    const [filtres, setFiltres] = useState();

    useEffect(() => {
        if (category == false) {
            fetch(global.components.url + "/product/api/category/get/?format=json")
                .then(res => res.json())
                .then((result) => {
                    setCategory(result['result'])
                    setIsCategory(true)
                })
        }
    }, [setCategory,])

    useEffect(() => {

        fetch(global.components.url + "/product/api/characteristic/?format=json")
            .then(res => res.json())
            .then(
                (result) => {
                    setFiltres(result)
                })

    }, [])


    useEffect(() => {
        if (input !== serchData) {
            setserchData(input)
            fetch(global.components.url + '/product/api/product/?name__icontains=' + input)
                .then(res => res.json())
                .then((result) => {
                    setproducts(result)
                    setIsProduct(true)
                })
        }
    }, [setserchData])

    if (!isProduct || !isCategory) {
        return (<p>Loading...</p>)
    } else {
        return (<div className='SearchCatalogData'>
            <Helmet>
                <title>Поиск</title>
            </Helmet>
            <Way
                title={title}
                way={way}
            />

            <CatalogContent
                ParrentName={'SearchCatalogData'}
                products={products}
                category={category}
                setSearchProducts={setserchData}
            />
        </div>)
    }
}

export default SearchCatalogData;