import ProfileMethods from "./ProfileMethods";
import Favourite from "./localStorage/Favourite";
export default function setFavouriteByProfile() {
    let profile = new ProfileMethods()
    let favourite = new Favourite()
    fetch(global.components.url + "/user/api/user/favourite/", {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(res => res.json())
        .then(
            (result) => {
                let articles = favourite.profileUpdateFavourite(result[0]["products"])
                localStorage.id = result[0].id
                if(articles){
                    favourite.changeProfileFavourite(articles)
                }
            },);
}