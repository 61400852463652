import React, { Component } from 'react'
import './css/Banner.css';

import { Link } from "react-router-dom";
const Banner = (props) => {

  return (<div><div className='banner'>
        <div className='content'>
          <div className='left'>
            <div className='bannerText'>
              <h1>{props.h1}</h1>
              <h4>от создательницы собственного бренда ювелирной бижутерии <p>Marianna Jewelry</p></h4>
            </div>
            <Link to="/catalog/готовые-украшения/">Перейти в каталог</Link>
          </div>
          <div className='right'>
            <div className='soc'>
              <ul>
                <li><a target="_blank" href={global.components.whatsapp[0]} className='whatsapp'></a></li>
                <li><a target="_blank" href={global.components.tg[0]} className='telegram'></a></li>
                <li><a target="_blank" href={global.components.vk[0]} className='vk'></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className='clearfix'></div>
  </div>
  </div>
  )

}
export default Banner;