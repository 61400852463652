import React, {useEffect, useState} from 'react';
import "./pagination.css"

const Pagination = (props) => {
    const params = new URLSearchParams(window.location.search);
    const [nowPage, setNowPage] = useState(parseInt(params.get("page")));
    const [pages, setPages] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.page) {
            if (props.page <= 6){

                setPages(Array.from({ length: props.page }, (_, i) => i + 1))
            }else if (props.page === nowPage) {
                setPages([1, dot, nowPage - 3, nowPage - 2, nowPage - 1, props.page])
            }else if(nowPage > props.page-3){
                setPages([1, dot, nowPage - 2, nowPage - 1, nowPage, nowPage+1, props.page])
            }else if (nowPage && nowPage > 2) {
                setPages([1, dot, nowPage - 1, nowPage, nowPage + 1, nowPage + 2, dot, props.page])
            } else {
                setPages([1, 2, 3, dot, props.page])
            }
        }
        if (!nowPage){
            setNowPage(1);
        }
        setIsLoading(true)
    }, [setIsLoading])


    const setParams = (index) => {
        if(typeof index !== 'number'){
            return true
        }
        if (index === 1) {
            params.set("page", index)
        } else {
            params.set("page", index)
        }
        window.location.search = params
    }

    let dot =
        <div className="pagination__item">
            <p style={{paddingBottom: "8px"}} className="pagination__number">...</p>
        </div>;

    if (!isLoading) {
        return (<div></div>)
    } else {
        return (
            <div className="pagination">
                {
                    pages.map((item, index) => (
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {nowPage === item || (index === 0 && (nowPage === item)) ?
                                <div className="pagination__item pagination__item--active">
                                    <button onClick={() => setParams(item)} className="pagination__number pagination__number--active"
                                            id={"pagination__" + item}>{item}</button>
                                </div>
                                :
                                item !== dot ?
                                <div className="pagination__item">
                                    <button onClick={() => setParams(item)} className="pagination__number"
                                            id={"pagination__" + item}>{item}</button>
                                </div> : item
                            }
                        </div>
                    ))}





            </div>
        )
    }
}
export default Pagination;