import React, { Component } from 'react'
import './css/ScrollUp.css'

export default class ScrollUp extends Component {
  render() {
    document.addEventListener('scroll', function (e) {
      let scroll = window.pageYOffset;
      if (document.getElementById('scrolUp') !== null) {
        if (scroll === 0 || scroll >= 2000) {
          document.getElementById('scrolUp').style.opacity = "0";
        }
        else if (scroll >= 40) {
          document.getElementById('scrolUp').style.opacity = "1";
        }
      }
    });

    function ScrollUp() {
      window.scrollTo(0, 0);
    }

    return (
      <div className='scrolUpBlock'>
        <div className='content'>
          <div className='right' id='scrolUp'>
            <button onClick={ScrollUp} id='scrolUpButton'></button>
          </div>
        </div>
      </div>
    )
  }
}
