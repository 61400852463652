import React, {useEffect, useState} from 'react'
import Way from '../Components/Pages/globalComponents/Way'
import ProfileContent from '../Components/Pages/Profile/ProfileContent'
import Footer from '../Components/StaticComponents/Footer'

export default function Profile(props) {

    // TODO-сделать валидацию полей

    useEffect(() => {
        if (!props.profile.is_authenticated) {
            window.location = "/registration"
        }
    })


    return (
        <div className='Profile'>
            <div className='content'>
                <Way
                    title={"Кабинет пользователя"}
                    way={["Mary Furnitura", "Кабинет пользователя", "Личные данные"]}
                />

                <ProfileContent
                    profileData={props.profile}
                />
            </div>
        </div>
    )
}
