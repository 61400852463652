import React, { useState, useEffect } from "react";
import Way from "../globalComponents/Way";

import "./css/ProductCard.css";
import Like from "../img/likeActive.svg";
import LikeGreenActive from "../img/LikeGreenActive.svg";
import DolyameBanner from "../Dolyame/DolyameBanner";
//import MetaTags from "react-meta-tags";
import { Helmet } from 'react-helmet';

export default function ProductCard(props) {
  const [way, setway] = useState([]);
  const [information, setinformation] = useState([]);
  const [error, seterror] = useState(null);
  const [isLoaded, setisLoaded] = useState(false);
  const [takedImg, settakedImg] = useState("Little" + 0);
  const [secTakedImg, setsecTakedImg] = useState("Little" + 0);
  const [quantity, setquantity] = useState(1);
  const [onBasket, setonBasket] = useState(false);
  // const [ToLikeProduct, setToLikeProduct] = useState(0)

  const [like, setlike] = useState(null);

  useEffect(() => {
    if (information.length === 0) {
      fetch(
        global.components.url +
          "/product/api/product/?article__in=" +
          props.idProduct
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setisLoaded(true);
            setinformation(result["results"][0]);
            setway(["Mari Furnitura", "Каталог", result["results"][0].name]);
          },
          (error) => {
            setisLoaded(true);
            seterror(error);
          }
        );
    }
    let product = props.basket.getProduct(props.idProduct);
    if (product) {
      setonBasket(true);
      setquantity(product["quantity"]);
    }

    if (props.favourite.isFavourite(props.idProduct)) {
      setlike(true);
    }
  }, []);

  const zoomImage = () => {
    document.getElementById("toZoomBigImg").style.display = "flex";
  };
  const CloseZoomImage = () => {
    document.getElementById("toZoomBigImg").style.display = "none";
  };

  const activateTakedImage = (e) => {
    // получает порядковый id маленького изображения
    setsecTakedImg(e.currentTarget.id);
    console.log(e.currentTarget.id, takedImg);

    if (e.currentTarget.id !== takedImg) {
      // active
      document.getElementById(e.currentTarget.id).style.borderColor = "#323334";
      // nonactive
      document.getElementById(takedImg).style.borderColor = "#ECEBE9";

      let indexImage = e.currentTarget.id.replace(/[^\d]/g, "");

      // Замена большого изображения
      document.getElementById(
        "toZoomImg"
      ).style.backgroundImage = `url(${information.image[indexImage].image})`;
      document.getElementById(
        "imgOnZoomBigImg"
      ).style.backgroundImage = `url(${information.image[indexImage].image})`;
      settakedImg(e.currentTarget.id);
    }
  };

  const QuantityMinus = () => {
    if (quantity <= 1) {
      setquantity(1);
      alert("Нельзя заказать меньше 1-го товара");
    } else {
      setquantity(quantity - 1);
    }
  };

  const QuantityPlus = () => {
    if (quantity < information.amount) {
      setquantity(quantity + 1);
    } else {
      alert("Превышен лимит товаров");
      setquantity(information.amount);
    }
  };

  // посредством eventa взять имя класса отца и добавить в конце Information
  const openInfo = (e) => {
    let idName = e.target.id;
    let nameArr = "arrDown" + idName;
    let information = idName + "Information";

    let arrDeg = document.getElementById(nameArr).style.transform;
    if (arrDeg === "rotate(180deg)") {
      document.getElementById(nameArr).style.transform = "rotate(0deg)";
      // document.getElementById(information).style.overflow = "hidden"
      document.getElementById(information).style.maxHeight = "0";
      setTimeout(() => {
        document.getElementById(information).style.marginTop = "0";
      }, 300);
    } else {
      document.getElementById(nameArr).style.transform = "rotate(180deg)";
      document.getElementById(information).style.maxHeight = "100vh";
      document.getElementById(information).style.marginTop = "20px";
    }
  };

  const likedProduct = (e) => {
    // like Продукта в popup окне
    let likedIdProduct = e.currentTarget.className;

    // likedIdProduct = article
    setlike(props.favourite.updateFavourite(information.article));
  };

  const getToBusket = () => {
    let quantitys;

    /*if (quantity !== 0) {
            quantitys = quantity;
        } else {
            quantitys = 1
        }*/

    let button = document.getElementById("getProduct");
    let button_width = button.style.width;
    button.style.width = "150px";
    button.textContent = "Добавлен в корзину";
    let total = document.getElementById("total");
    total.style.display = "none";

    props.basket.updateBasket(information.article, quantity);

    setTimeout(() => {
      button.textContent = "В корзине";
      button.style.width = button_width;
      total.style.display = "flex";
    }, 800);
  };

  if (information.length !== 0) {
    let idquantity = "productCard_" + information.article;
    let is_favorite,
      isAgree = like;

    if (isAgree === true) {
      is_favorite = (
        <div
          id="likeProductCard"
          className={information.article}
          onClick={likedProduct}
          style={{ backgroundImage: `url(${LikeGreenActive})` }}
        ></div>
      );
    } else {
      is_favorite = (
        <div
          id="likeProductCard"
          className={information.article}
          onClick={likedProduct}
          style={{ backgroundImage: `url(${Like})` }}
        ></div>
      );
    }

    let haveOnShop, total, img, zoom;

    if (information.image.length > 0) {
      img = `url(${information.image[0].image})`;
      zoom = (
        <div className="zoom">
          <button onClick={zoomImage}></button>
        </div>
      );
    }

    if (information.amount > 0 && !information.is_discontinued) {
      haveOnShop = (
        <div className="availableInStock">
          <div className="img"></div>
          <span>Есть в наличии</span>
        </div>
      );
      total = (
        <div className="quantity">
          <div id="total" className="total">
            <button id="minus" onClick={QuantityMinus}>
              -
            </button>
            <input id={idquantity} value={quantity} disabled></input>
            <button id="plus" onClick={QuantityPlus}>
              +
            </button>
          </div>
          {onBasket ? (
            <button id="getProduct" onClick={getToBusket}>
              В корзине
            </button>
          ) : (
            <button id="getProduct" onClick={getToBusket}>
              В корзину
            </button>
          )}
        </div>
      );
    } else {
      haveOnShop = (
        <div className="availableInStock">
          <span>Нет в наличии</span>
        </div>
      );
    }

    let infoDescription = [];
    let ArrInfoDescription = [];
    // let updarr = []
    if (information.description !== undefined) {
      let regex = /(https?:\/\/[^\s]+)/g;
      ArrInfoDescription = information.description.split("<br />");
      ArrInfoDescription.forEach((item) => {
        if (item !== "" && item !== " ") {
          infoDescription.push(
            item.replace(
              regex,
              `<a style="text-decoration: underline; color: blue" target="_blank" href="$&">переход на страницу</a>`
            )
          );
        }
        if (item === " ") {
          infoDescription.push(<br />);
        }
      });

      infoDescription.forEach((item, index) => {
        let next = infoDescription[index + 1];
        if (typeof next === "object") {
          next = next.type;
        }
        if (item.type === "br") {
          if (next !== item.type) {
            delete infoDescription[index];
          }
        }
      });
    }

    if (error) {
      return <p>Error {error.message} </p>;
    } else if (!isLoaded) {
      return <p>Loading...</p>;
    } else {
      return (
        <div className="ProductCard">
          <Helmet>
            <title>{information.name} купить в СПб</title>
          </Helmet>
          <Way title={information.name} way={way} />
          <div className="content">
            <div className="left">
              <div
                className="bigImage"
                id="toZoomImg"
                style={{
                  backgroundImage: img,
                }}
              >
                {zoom}
              </div>

              <div className="toZoomBigImg" id="toZoomBigImg">
                <button className="cross" onClick={CloseZoomImage}>
                  <span></span>
                </button>
                <div
                  className="bigimage"
                  id="imgOnZoomBigImg"
                  style={{
                    backgroundImage: img,
                  }}
                ></div>
              </div>

              {/* Вывод малленьких изображений */}
              <div className="allImages">
                {information.image.map((item, idex) => (
                  <div
                    className="LittleImage nonactive"
                    key={"ProductCard allImages LittleImage" + idex}
                    id={"Little" + idex}
                    onClick={activateTakedImage}
                  >
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${item.image})`,
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>

            <div className="right">
              <h2 className="product__title">{information.name}</h2>
              <p>
                Артикул: <span>{information.article}</span>
              </p>

              {haveOnShop}
              <DolyameBanner clasName="product-card" />
              <div className="total">
                <div className="left">
                  {information.discount ? (
                    <div className="cost">
                      <p id="cost">
                        {information.discount_price.new_price * quantity}
                      </p>
                      <p>руб</p>
                      
                      <div
                        style={{ fontSize: "12px", display: "inline-block" }}
                        className="oldCost"
                      >
                        <p
                          style={{
                            marginRight: 0,
                            textDecoration: "line-through",
                          }}
                        >
                          {information.discount_price.old_price}
                        </p>
                        <p style={{ textDecoration: "line-through" }}>руб/шт</p>
                      </div>
                    </div>
                  ) : (
                    <div className="cost">
                      <p id="cost">{information.price * quantity}</p>
                      <p>руб</p>
                    </div>
                  )}
                  {total}

                  <div className="postpone">
                    {is_favorite}
                    {isAgree ? <p>Товар отложен</p> : <p>Отложить товар</p>}
                  </div>

                  <div className="bigInformation">
                    <div className="characteristic">
                      <div
                        className="title"
                        id="Characteristic"
                        onClick={openInfo}
                      >
                        
                        <div className="left">
                          <p>Характеристики</p>
                        </div>
                        <div className="right">
                          <div
                            className="arrDown"
                            id="arrDownCharacteristic"
                          ></div>
                        </div>
                      </div>
                      <div className="clearfix"></div>

                      <div
                        className="characteristicInformation"
                        id="CharacteristicInformation"
                        style={{
                          // display: "none"
                          overflow: "hidden",
                          maxHeight: "0",
                          marginTop: "0",
                        }}
                      >
                        {information.characteristic.map((item, index) => (
                          <div key={"characteristicInformation" + index}>
                            <p className="attribute">{item.attribute}:</p>
                            <p className="value">{item.value}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                    
                    <div className="description">
                      <div
                        className="title"
                        id="Description"
                        onClick={openInfo}
                      >
                        <div className="left">
                          <p>Описание</p>
                          
                        </div>
                        <div className="right">
                          <div
                            className="arrDown"
                            id="arrDownDescription"
                          ></div>
                          
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div
                        className="descriptionInformation"
                        id="DescriptionInformation"
                        style={{
                          // display: "none"
                          overflow: "hidden",
                          maxHeight: "0",
                          marginTop: "0",
                        }}
                      >
                        {infoDescription.map((item, key) => (
                          <div
                            dangerouslySetInnerHTML={{ __html: item }}
                            key={"itemDeskp" + key}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix"></div>

            <div className="shadowInfo">
              <div className="bigInformation">
                <div className="characteristic">
                  <div
                    className="title"
                    id="Characteristics"
                    onClick={openInfo}
                  >
                    <div className="left">
                      <p>Характеристики</p>
                    </div>
                    <div className="right">
                      <div
                        className="arrDown"
                        id="arrDownCharacteristics"
                      ></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>

                  <div
                    className="characteristicInformation"
                    id="CharacteristicsInformation"
                    style={{
                      // display: "none"
                      overflow: "hidden",
                      maxHeight: "0",
                      marginTop: "0",
                    }}
                  >
                    {information.characteristic.map((item, index) => (
                      <div key={"characteristicInformations" + index}>
                        <p className="attribute">{item.attribute}:</p>
                        <p className="value">{item.value}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="description">
                  <div className="title" id="Descriptions" onClick={openInfo}>
                    <div className="left">
                      <p>Описание</p>
                    </div>
                    <div className="right">
                      <div className="arrDown" id="arrDownDescriptions"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div
                    className="descriptionInformation"
                    id="DescriptionsInformation"
                    style={{
                      // display: "none"
                      overflow: "hidden",
                      maxHeight: "0",
                      marginTop: "0",
                    }}
                  >
                    {infoDescription.map((item, key) => (
                      <p key={"itemDeskps" + key}>{item}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return <p></p>;
  }
}
