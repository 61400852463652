import "./nullstyle.css";
import "./normalize.css";
import React, { useEffect, useState } from "react";

import { Routes, Route, useLocation } from "react-router-dom";

import Registration from "./Routes/Registration";
import Authorization from "./Routes/Authorization";
import DolyameSuccess from "./Routes/DolyameSuccess"
import DolyameBanner from "./Components/Pages/Dolyame/DolyameBanner";
import GarantDeliveryAndPayment from "./Routes/GarantDeliveryAndPayment";
import MasterClassesAndCourses from "./Routes/MasterClassesAndCourses";
import Contacts from "./Routes/Contacts";
import AboutUs from "./Routes/AboutUs";
import "./config";
import ScrollToTop from "./ScrollToTop";
import OrderConfirm from "./Components/Pages/OrderConfirm/OrderConfirm";
import Footer from "./Components/StaticComponents/Footer";
import Header from "./Components/StaticComponents/Header";
import StaticHeader from "./Components/StaticComponents/StaticHeader";
import BasketStorage from "./Methods/localStorage/BasketStorage";
import Basket from "./Routes/Basket";
import Home from "./Routes/Home";
import Favourite from "./Methods/localStorage/Favourite";
import ProfileMethods from "./Methods/ProfileMethods";
import Catalog from "./Routes/Catalog";
import Disconts from "./Routes/Disconts";
import ProductInformation from "./Routes/ProductInformation";
import SearchCatalogData from "./Routes/SearchCatalogData";
import Profile from "./Routes/Profile";
import MakingAnOrder from "./Routes/MakingAnOrder";

const App = () => {
  const [profile, setProfile] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [header, setHeader] = useState();
  const location = useLocation();
  const [loadProfile, setLoadProfile] = useState(false);

  const setLocalStorage = () => {
    if (!localStorage.getItem("basket")) {
      localStorage.basket = JSON.stringify({
        basket: [],
        quantity: 0,
        done: false,
      });
    }
    if (!localStorage.getItem("favourite")) {
      localStorage.favourite = JSON.stringify({
        articles: [],
        quantity: 0,
        done: false,
      });
    }
  };

  const changeHeader = () => {
    let header;
    let basket = new BasketStorage();
    let favourite = new Favourite();
    let name = undefined;
    if (profile.is_authenticated) {
      name = profile.name;
    }
    if (document.location.pathname === "/") {
      header = (
        <Header
          quantityOnBasket={basket.basket.quantity}
          quantityOnLiked={favourite.favourite.quantity}
          profileName={name}
          //setSearchProducts={props.setSearchProducts}
        />
      );
    } else {
      header = (
        <StaticHeader
          quantityOnBasket={basket.basket.quantity}
          quantityOnLiked={favourite.favourite.quantity}
          profileName={name}
        />
      );
    }
    setHeader(header);
  };

  useEffect(() => {
    fetch(
      global.components.url + "/user/api/user/is_authenticated/?format=json"
    )
      .then((res) => res.json())
      .then((result) => {
        let profile;
        let test = false;
        if (test) {
          profile = {
            is_authenticated: true,
            id: 6,
            phone: "9770473964",
            region: "+7",
            surname: "White",
            name: "Dime",
            middlename: "Андреевич",
            email: "dimewhite228@gmail.com",
            mailing: null,
          };
          localStorage.id = 4;
          setProfile(profile);
          setLoadProfile(true);
        } else {
          if (result["is_authenticated"]) {
            localStorage.id = result.id;
          }
          setProfile(result);
          setLoadProfile(true);
        }
      });
  }, []);

  useEffect(() => {
    if (loadProfile) {
      setLocalStorage();
      changeHeader();
      setIsLoaded(true);
    }
  }, [location, loadProfile]);

  window.addEventListener("storage", (e) => {
    changeHeader();
  });

  return (
    <div className="App">
      {!isLoaded ? (
        <div>Loading</div>
      ) : (
        <div>
          <ScrollToTop />
          {header}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/catalog/" element={<Catalog />} />
            <Route path="/catalog/:id" element={<Catalog />} />
            <Route path="/discounts/" element={<Disconts />} />
            <Route path="/discounts/:id" element={<Disconts />} />
            
            <Route
              path="/basket/"
              element={
                <Basket
                  href={"OnBasket"} //liked
                />
              }
            />
            <Route
              path="/likedProducts/"
              element={
                <Basket
                  href={"OnLiked"} //liked
                />
              }
            />
            <Route
              path="/makingAnOrder/"
              element={<MakingAnOrder profile={profile} />}
            />
            <Route
              path="/product/:idProduct/"
              element={<ProductInformation />}
            />
            <Route
              path="/search/:registrationInput/"
              element={<SearchCatalogData />}
            />
            <Route path="/profile/" element={<Profile profile={profile} />} />
            <Route path="/registration/" element={<Registration />} />
            <Route path="/authorization/" element={<Authorization />} />
            <Route path="/order/dolyame/success/" element={<DolyameSuccess status='success'/>}/>
            <Route path="/order/dolyame/fail/" element={<DolyameSuccess status='fail'/>}/>
              {/* <Route path="/test/" element={<DolyameBanner />} /> */}
            {/* <Route path="/order/dolyame/fail/" element={}/> */}

            {/* static pages */}
            <Route
              path="/garantdeliveryandpayment/"
              element={<GarantDeliveryAndPayment />}
            />
            <Route
              path="/master_classes_and_courses/"
              element={<MasterClassesAndCourses />}
            />
            <Route path="/contacts/" element={<Contacts />} />
            <Route path="/about_us/" element={<AboutUs />} />
            <Route path="/confirm_order/:id/" element={<OrderConfirm />} />
          </Routes>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default App;
