import React, { useState } from 'react'
import AboutUsContent from '../Components/Pages/AboutUs/AboutUsContent'
import Way from '../Components/Pages/globalComponents/Way';
//HelmetetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
export default function AboutUs() {

  const [title] = useState("О нас");
  const [way] = useState(["Mary Furnitura", "О нас"]);

  return (
    <div className='AboutUs'>
        <Helmet>
            <title>О нас</title>
        </Helmet>
      <Way
        title={title}
        way={way}
      />
      <AboutUsContent />
    </div>
  )
}
