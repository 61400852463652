import React, { useState, useEffect } from 'react'

import BelCountry from '../../registration/img/bel.png'
import RuCountry from '../../registration/img/ru.png'

export default function PersonData(props) {

  const [tel, setTel] = useState("+7");
  const [id, setID] = useState();
  const [phone, setPhone] = useState();
  const [surname, setSurname] = useState();
  const [name, setName] = useState();
  const [middlename, setMiddlename] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (props.profileData.is_authenticated) {
      if (phone === undefined && surname === undefined && name === undefined && middlename === undefined && email === undefined) {
        setID(props.profileData.id)
        setPhone(props.profileData.phone)
        setSurname(props.profileData.surname)
        setName(props.profileData.name)
        setMiddlename(props.profileData.middlename)
        setEmail(props.profileData.email)

        document.getElementById('PersonDataNewtelephonenumber').value = props.profileData.phone;
        document.getElementById('PersonDataNewCodeCountry').textContent = props.profileData.region;
        document.getElementById('PersonDataSurname').value = props.profileData.surname;
        document.getElementById('PersonDataName').value = props.profileData.name;
        document.getElementById('PersonDataMiddlename').value = props.profileData.middlename;
        document.getElementById('PersonDataEmail').value = props.profileData.email;
      }
    }
  })

  const checkKey = (event) => {
    if (!/[А-Я,а-я]/.test(event.key)) {
        event.preventDefault();
        window.alert("Пишите кирилицей и без пробелов")
    }
    document.getElementById(event.target.id).classList.remove("error")
}
  const openShadowNewTelephoneCountry = () => {
    let statusShadow = document.getElementById("shadowNewTelephoneCountry").style.display;

    if (statusShadow === "none") {
      document.getElementById("shadowNewTelephoneCountry").style.display = "inline-block";
    } else {
      document.getElementById("shadowNewTelephoneCountry").style.display = "none";
    }
  }

  const DefaultPersonDate = () => {

  }

  const takeCountry = (e) => {
    let id = e.currentTarget.id;

    if (id == "ruCountry") {
      setTel("+7");
      document.getElementById("imageCountry").style.backgroundImage = `url(${RuCountry})`
      document.getElementById("codeCountry").textContent = "+7"
      document.getElementById("codeCountry").style.width = "20px"
      document.getElementById("telephonenumber").style.width = "188px"

    } else if (id == "ruNewCountry") {
      setTel("+7");
      document.getElementById("imageNewCountry").style.backgroundImage = `url(${RuCountry})`
      document.getElementById("PersonDataNewCodeCountry").textContent = "+7"
      document.getElementById("PersonDataNewCodeCountry").style.width = "20px"
      document.getElementById("PersonDataNewtelephonenumber").style.width = "188px"
    } else if (id == "belCountry") {
      setTel("+375");
      document.getElementById("imageCountry").style.backgroundImage = `url(${BelCountry})`
      document.getElementById("codeCountry").textContent = "+375"
      document.getElementById("codeCountry").style.width = "38px"
      document.getElementById("telephonenumber").style.width = "170px"
    } else if (id == "belNewCountry") {
      setTel("+375");
      document.getElementById("imageNewCountry").style.backgroundImage = `url(${BelCountry})`
      document.getElementById("PersonDataNewCodeCountry").textContent = "+375"
      document.getElementById("PersonDataNewCodeCountry").style.width = "38px"
      document.getElementById("PersonDataNewtelephonenumber").style.width = "170px"
    }

    document.getElementById("shadowNewTelephoneCountry").style.display = "none";
  }

  return (
    <div className='PersonData MakingAnOrder'>
      <h3>Личные данные</h3>
      <div className='selects'>
        <div className='telephone'>

          <p>Телефон<span className='red'>*</span></p>
          <span id='PersonDataTelephoneInput' className='telephoneInput'>
            <button id='countryNew' onClick={openShadowNewTelephoneCountry}>
              <div className='img' id='imageNewCountry'></div>
              <div className='arrDown'></div>
            </button>
            <div id='shadowNewTelephoneCountry' style={{ display: "none" }}>
              <button id='ruNewCountry' onClick={takeCountry}>
                <div className='img'></div>
              </button>
              <button id='belNewCountry' onClick={takeCountry}>
                <div className='img'></div>
              </button>
            </div>
            <span id='PersonDataNewCodeCountry'>+7</span>
            <input
              id='PersonDataNewtelephonenumber'
              className=''
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={10}
            ></input>
          </span>

        </div>
        <div className='surname'>
          <p>Фамилия<span className='red'>*</span></p>
          <input  onKeyPress={(event) => {
                                checkKey(event)
                            }} id='PersonDataSurname'></input>
        </div>
      </div>
      <div className='selects'>
        <div className='name'>
          <p>Имя<span className='red'>*</span></p>
          <input onKeyPress={(event) => {
                              checkKey(event)
                            }} id='PersonDataName'></input>
        </div>
        <div className='middlename'>
          <p>Отчество<span className='red'>*</span></p>
          <input onKeyPress={(event) => {
                                checkKey(event)
                            }} id='PersonDataMiddlename'></input>
        </div>
      </div>
      <div className='selects'>
        <div className='email'>
          <p>Email<span className='red'>*</span></p>
          <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id='PersonDataEmail'></input>
        </div>
      </div>
      <div className='selects'>
        <div className='comment'>
          <p>Комментарии к заказу</p>
          <textarea id='PersonDataComment' placeholder='напишите комментарий'></textarea>
        </div>
      </div>
    </div>
  )
}
