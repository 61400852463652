import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ContentDolyameFail() {
    const [timeLeft, setTimeLeft] = useState(5); // Начальное значение таймера - 5 секунд
    const navigate = useNavigate();

    useEffect(() => {
        // Устанавливаем интервал, который будет уменьшать значение timeLeft на 1 каждую секунду
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        // Очистка интервала после завершения таймера
        if (timeLeft === 0) {
            clearInterval(timer);
            navigate('/profile'); // Перенаправление на страницу профиля
        }

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(timer);
    }, [timeLeft, navigate]);

    return (
        <div className='content' >
            <h1 style={{ fontSize: '20px' }}>Что-то пошло не так :( повторите попытку позже, вас перенаправит на страницу профиля через ... <span id='timer'>{timeLeft}</span> </h1>
        </div>
    );
}