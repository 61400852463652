import React, {Component, useEffect, useState} from 'react';

import Marquee from 'react-double-marquee';
import './css/SaleLine.css'

const SaleLine = () => {
  const [promo, setPromo] = useState()
  const [promoText, setPromoText] = useState()


  useEffect(() => {
    fetch(global.components.url + "/order/api/promo/?type=first")
        .then(res => res.json())
        .then((result) => {
          setPromo(result[0])
        })
  }, [])

  useEffect(() => {
    let text = []
    if (promo) {
      for (let i = 0; i < 11; i++) {
        text.push(<li>ПОЛУЧИТЕ СКИДКУ {promo.percent}% ПО ПРОМОКОДУ "{promo.code.toUpperCase()}" НА ПЕРВЫЙ ЗАКАЗ</li>)
        text.push(<li>MARY FURNITURA</li>)
      }
      setPromoText(text)
    }
  }, [promo, setPromo])

    return (
      <div className='SaleLine'>
        <Marquee
          direction="left"
          scrollWhen="always"
        >
          { promoText }
        </Marquee>
      </div>
    )

}
export default SaleLine;