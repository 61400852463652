import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./css/MakingAnOrderComponent.css";

import { Link } from "react-router-dom";
import Adress from "./components/Adress";
import Delivery from "./components/Delivery";
import PaymentMethod from "./components/PaymentMethod";
import PersonData from "./components/PersonData";
import ProductsInCart from "./components/ProductsInCart";
import BasketStorage from "../../../Methods/localStorage/BasketStorage";
//import { MetaTags } from "react-meta-tags";
import { Helmet } from 'react-helmet';

export default function MakingAnOrderContent(props) {
  const [DeliveryMethod, setDeliveryMethod] = useState("PICKUP");
  const [Products, setProducts] = useState();
  const [Cost, setCost] = useState(0);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [address, setAddress] = useState(
    "197375, г Санкт - Петербург, Суздальское шоссе 28 к 2"
  );
  const [Gr, setGr] = useState();
  const [promo, setPromo] = useState(null);
  const [term, setTerm] = useState(2);
  const [FullCost, setFullCost] = useState(0);
  const [APILoad, setAPILoad] = useState(false);
  const [basket, setBasket] = useState(new BasketStorage());

  useEffect(() => {
    if (basket.getProductsArticles().length === 0) {
      window.location = "/catalog/";
    }
    if (APILoad) {
      return;
    }
    if (document.getElementById("ChecboxrPickup").checked === true) {
      document.getElementById("AdressInput").value =
        "197375, г Санкт - Петербург, Суздальское шоссе 28 к 2";
    }

    if (Products === undefined) {
      let prodCost = 0;
      let prodGr = 0;
      let productsArr = [];

      fetch(
        global.components.url +
          "/product/api/short_product/?article__in=" +
          basket.getProductsArticles().toString() +
          "&format=json"
      )
        .then((res) => res.json())
        .then((result) => {
          result.forEach((item) => {
            let article = item.article;
            let quantity = basket.getProduct(article)["quantity"];
            if (item.amount > 0) {
              productsArr.push({ article, quantity });

              prodGr = prodGr + item.weight;
              let cost = 0;
              if (item.discount !== null) {
                cost = item.discount_price.new_price;
              } else {
                cost = item.price;
              }

              prodCost += cost * quantity;
            }
          });
          setCost(prodCost);
          setGr(prodGr);
        });
      setProducts(productsArr);
    }
    let popUpId, popUInfo;

    if (
      document.getElementsByClassName("CDEK-widget__popup-mask").length !== 0
    ) {
      popUpId = document.getElementsByClassName("CDEK-widget__popup-mask")[0]
        .id;
      popUInfo = document.getElementById(popUpId);
    }

  }, [Products, DeliveryMethod, APILoad]);

  useEffect(() => {
    if (Cost !== 0) {
      let full;
      if (localStorage.getItem("promo")) {
        let promo_cash = JSON.parse(localStorage.getItem("promo"));
        setPromo(promo_cash);
        full = Cost - (Cost / 100) * promo_cash["percent"];
      } else {
        full = Cost;
      }
      setFullCost(() => full);
    }
  }, [Cost]);

  var intervalTimer;
  function placeAnOrderButton() {
    let err = [];
    

    // VALIDATE
    if (document.getElementById("PersonDataNewtelephonenumber").value === "") {
      err.push("PersonDataTelephoneInput");
    }
    if (document.getElementById("PersonDataSurname").value === "") {
      err.push("PersonDataSurname");
    }
    if (document.getElementById("PersonDataName").value === "") {
      err.push("PersonDataName");
    }
    if (document.getElementById("PersonDataMiddlename").value === "") {
      err.push("PersonDataMiddlename");
    }
    if (document.getElementById("PersonDataEmail").value === "") {
      err.push("PersonDataEmail");
    }
    if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test()) {
      err.push("PersonDataEmail");
    }
    if (document.getElementById("AdressInput").value === "") {
      err.push("AdressInput");
    }

    if (err.length === 0) {
      let promo_id,
        user_id = null;
      if (promo) {
        promo_id = promo.id;
      }
      if (props.idPersone) {
        user_id = props.idPersone;
      }

      if (!APILoad) {
        setAPILoad(true);
        window.scrollTo(0, -200);
        intervalTimer = setInterval(updateTime, 1000);
        fetch(global.components.url + "/order/api/order/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // "x-csrf-token": token,
          },
          body: JSON.stringify({
            personality: {
              phone: document.getElementById("PersonDataNewtelephonenumber")
                .value,
              region: document.getElementById("PersonDataNewCodeCountry")
                .textContent,
              surname: document.getElementById("PersonDataSurname").value,
              name: document.getElementById("PersonDataName").value,
              middlename: document.getElementById("PersonDataMiddlename").value,
              email: document.getElementById("PersonDataEmail").value,
              comment: document.getElementById("PersonDataComment").value,
            },
            delivery: {
              delivery: DeliveryMethod,
              price: deliveryCost,
              period: term,
              status: "WAIT-PAY",
            },
            user: user_id,
            promo: promo_id,
            total_price: changeFullCost,
            basket: Products,
            address: address,
            country: "RUSSIA",
            payments: document.querySelector('input[name="PaymentMethod"]:checked').value,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            
            if (result.create === false) {
              // fix
              if (result.user === "unique" || result.error.user === "unique") {
                alert(
                  "Вы уже зарегистрированы на сайте MaryFurnitura. Чтобы оформить заказ войдите в личный кабинет."
                );
              }
              setAPILoad(false);
              clearInterval(intervalTimer);
            }
            console.log(result)
            if (result.create === true) {

              basket.deleteAllProduct();
              clearInterval(intervalTimer);


              window.location = "/confirm_order/" + btoa(result.id);
            }
          });
      } else {
      }
    } else {
      err.forEach((item) => {
        document.getElementById(item).classList.add("Error");
      });
      alert("Вы заполнили поля не корректно");
    }
  }

  const changeFullCost = useMemo(() => {
    if (promo) {
      return Math.round(Cost - (Cost / 100) * promo["percent"] + deliveryCost);
    } else {
      return Cost + deliveryCost;
    }
  }, [Cost, deliveryCost, promo]);

  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  function updateTime() {
    seconds++;
    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }

    document.getElementById(
      "timer"
    ).textContent = `${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  return !APILoad ? (
    <div className="MakingAnOrderContent">
      <Helmet>
        <title>Оформление заказа</title>
      </Helmet>
      <div className="content" id="makeOrder">
        <div className="right">
          <div className="cost">
            <div className="inf">
              <div className="title">
                <h2>Ваш заказ</h2>
                <Link to="/basket">Изменить</Link>
              </div>

              <div className="clearfix"></div>

              <div className="allCost">
                <div className="left">
                  <p>Товаров на</p>
                  <p>Доставка</p>
                  {promo ? <p>Промокод: {promo["code"]}</p> : null}
                  <p className="lastCost">Итого</p>
                </div>
                <div className="right">
                  <p id="allCost">{Cost} руб.</p>
                  <p id="delivery">{deliveryCost} руб.</p>
                  {promo ? <p id="promo">{promo["percent"]} %</p> : null}
                  <p id="lastC">{changeFullCost} руб.</p>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="button">
              <div className="costLastOnBut">
                <div className="left">
                  <p className="lastCostOnBut">Итого</p>
                </div>
                <div className="right">
                  <p id="lastCOnBut"> {changeFullCost} руб.</p>
                </div>
                <div className="clearfix"></div>
              </div>
              <button onClick={placeAnOrderButton} id="placeAnOrderButton">
                Оформить заказ
              </button>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="left">
          <Delivery
            setDelivery={setDeliveryMethod}
            setDeliveryCost={setDeliveryCost}
            setTerm={setTerm}
            setAddress={setAddress}
            
          />
          <Adress />
          <PersonData profileData={props.profileData} />
          <PaymentMethod />
          <ProductsInCart />
          <div className="placeAnOrder">
            <div className="inputs">
              <input
                defaultChecked
                type="checkbox"
                id="personDate"
                name="personDate"
              ></input>
              <label htmlFor="personDate">
                <p>
                  Я согласен на{" "}
                  <a href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true">
                    обработку персональных данных
                  </a>{" "}
                  и принимаю{" "}
                  <a href="https://docs.google.com/document/d/10VOZC_407ZJOP_3K5WbxV0iArkgtsbr6/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true">
                    оферту
                  </a>
                </p>
              </label>
            </div>
            <button onClick={placeAnOrderButton} id="placeAnOrderButton">
              Оформить заказ
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ position: "relative", zIndex: "99999999" }}>
      <div
        style={{
          width: "95%",
          padding: "15px",
          backgroundColor: "#EBE8DE",
          fontSize: "20px",
          lineHeight: "30px",
          margin: "-45px 2vw",
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <p>
          Уважаемый клиент!
          <br />
          Ваш заказ отрабатывается, это займёт несколько минут, пожалуйста,
          <br /> <strong>не покидайте страницу!</strong>
        </p>
        <div id="timer"></div>
      </div>
    </div>
  );
}
