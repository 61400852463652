import React, {Component} from 'react'
import {Link} from "react-router-dom";
import './css/Baners.css'

export default class zBaners extends Component {
    render() {
        let noFollow;
        if (this.props.noFollow === undefined) {
            noFollow =
                <div className='FollowUs'>
                    <h2>Следите за нами вконтакте</h2>
                    <a target="_blank" href={global.components.vk[0]}>Подписаться</a>
                </div>
        }

        return (
            <div className='Baners'>
                <div className='ReadyMadeJewelry'>
                    <h2>Готовые украшения</h2>
                    <p>В нашем интернет-магазине вы можете купить ювелирную бижутерию Marianna Jewelry, каждое изделия
                        которой создается в единственном экземпляре</p>
                    <Link onClick={() => {
                        window.location.href = `/catalog/готовые-украшения/`
                    }}>Перейти</Link>
                </div>
                {noFollow}
                <div className='clearfix'></div>
            </div>
        )
    }
}
