import React, { useState, useEffect } from 'react'
import ProductTable from '../../globalComponents/ProductOutputType/ProductTable.js';
import BasketStorage from "../../../../Methods/localStorage/BasketStorage";

export default function ProductsInCart() {

  const [quantityProducts, setQuantityProducts] = useState();
  const [ProductS, setProductS] = useState( );
  const [basket, setBasket] = useState(new BasketStorage())

  useEffect(() => {
    if (quantityProducts === undefined) {
      setQuantityProducts(basket.basket.quantity);

      let arrProducts = [];
      let infoPS = []

      fetch(global.components.url + "/product/api/short_product/?article__in=" + basket.getProductsArticles().toString() + "&format=json")
        .then(res => res.json())
        .then((result) => {
          result.forEach((item, index) => {
            let article = item.article;
            let quantity =  basket.getProduct(article)["quantity"];
            let info = item;
            if (item.amount > 0) {
              arrProducts.push({ article, quantity });
              infoPS.push({ info, quantity })
            }
          });
          setProductS(infoPS);
        })
    }
  })

  const OpenShadovProducts = () => {
    let display = document.getElementById("ShadovProducts").style.display;
    if (display === "none") {
      document.getElementById("ShadovProducts").style.display = "block"
      document.getElementById("ProductsInCB").style.borderBottom = "none"
      document.getElementById("ShadovProducts").style.display = "block"
      document.getElementById("arrDownProductsInCart").style.transform = "rotate(0deg)"
    } else {
      document.getElementById("ShadovProducts").style.display = "none"
      document.getElementById("ProductsInCB").style.borderBottom = "1px solid #EBE8DE"
      document.getElementById("arrDownProductsInCart").style.transform = "rotate(180deg)"
    }
  }

  var ProductList = '';
  if (typeof (ProductS) !== "undefined") {
    ProductList =
      ProductS.map((item, index) => (
        <ProductTable
          key={"ProductsInCartProductTable" + index}
          ParrentName={"ProductsInCart"}
          id={item.info.article}
          name={item.info.name}
          img= {item.info.image && item.info.image.length > 0 ? (
            <img src={item.info.image[0]} alt="Product" />
          ) : (
            <p>No image available</p>
          )}//{item.info.image[0].image}
          price={item.info.price}
          ProductInfo={item.info}
          quantity={item.quantity}
        />
      ))
  }

  return (
    <div className='ProductsInCart MakingAnOrder'>
      <button id='ProductsInCB' onClick={OpenShadovProducts}>
        <div className='left'><h3>Товары в заказе</h3></div>
        <div className='right'><div id='arrDownProductsInCart' className='img' style={{ transform: "rotate(180deg)" }}></div></div>
        <div className='clearfix'></div>
      </button>
      <div className='ShadovProducts' id='ShadovProducts' style={{
        display: "none"
      }}>
        <p>Товаров - {quantityProducts}</p>
        <div className='Products'>
          {ProductList}
        </div>
      </div>
    </div>
  )
}
