import React, { useState, useEffect, useCallback } from 'react'
import InfoOrder from './InfoOrder/InfoOrder'
export default function OrderHistory(props) {

    const [Close, setClose] = useState();
    const [Canselled, setCanselled] = useState();
    const [prodcts, setprodcts] = useState();

    useEffect(() => {
        let mClose = [],
            mCanselled = [];

        if (props.products.length > 0) {
            if (props.products !== prodcts) {
                props.products.forEach((item) => {
                    if (item['delivery']["status"] === "CLOSED" || item['delivery']["status"] === "REFUNDED") {
                        mClose.push(item)
                    }
                    else if (item['delivery']["status"] === "NOT-PAID" || item['delivery']["status"] === "CANCELLED") {
                        mCanselled.push(item)
                    }
                });
                setprodcts(props.products);

                if (mClose.length === 0) {
                    setClose(null);
                } else {
                    setClose(mClose);
                }
                if (mCanselled.length === 0) {
                    setCanselled(null);
                } else {
                    setCanselled(mCanselled);
                }
            }
        }
    })

    if (props.status === "Close") {
        if (Close !== undefined && Close !== null) {
            return (
                <div className='OrderHistoryClose'>
                    {Close.map((item, index) =>
                        <InfoOrder
                            key={"OrderHistoryClose" + index}
                            OrderProductInfo={item}
                            status={"завершен"}
                            updateProductsOrders={props.UPDStatusOrder}
                        />
                    )}

                </div>
            )
        } else {
            return (
                <InfoOrder
                    key={"DivOrderHistoryClose"}
                    OrderProductInfo={null}
                    status={"завершен"}
                />
            )
        }
    }

    if (props.status === "Canselled") {
        if (Canselled !== undefined && Canselled !== null) {
            return (
                <div className='OrderHistoryCanselled'>
                    {Canselled.map((item, index) =>
                        <InfoOrder
                            key={"OrderHistoryCanselled" + index}
                            OrderProductInfo={item}
                            status={"отменен"}
                            updateProductsOrders={props.UPDStatusOrder}
                        />
                    )}

                </div>
            )
        } else {
            return (
                <InfoOrder
                    key={"DivOrderHistoryCanselled"}
                    OrderProductInfo={null}
                    status={"отменен"}
                />
            )
        }
    }
}
