import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Way from '../Components/Pages/globalComponents/Way'
import ContentDolyameSuccess from '../Components/Pages/Dolyame/ContentDolyameSuccess'; 
import ContentDolyameFail from '../Components/Pages/Dolyame/ContentDolyameFail';


export default function DolyameSuccess(props) {
  const [state, setState] = useState({
    way: ["Mary Furnitura", "Успешная оплата"],
    title: "Успешная оплата",
  });

  return (
    <div className='Avtorization'>
      <Helmet>
        <title>Успешная оплата</title>
      </Helmet>
      <Way
        title={state.title}
        way={state.way}
      />
        {props.status === 'success' ? (
        <ContentDolyameSuccess />
      ) : (
        <ContentDolyameFail />
      )}
      
    </div>
  );
}

