import React, { useEffect } from "react";

export default function PaymentMethod() {
  useEffect(() => {
    document.getElementById("CARD").checked = true;
  });
  return (
    <div className="PaymentMethod MakingAnOrder">
      <h3>Способ оплаты</h3>
      <fieldset>
        <div id="PaymentMethod" className="inputs">
          <input
            defaultChecked
            type="radio"
            name="PaymentMethod"
            value="CARD"
            className="PaymentMethodInput"
            id="CARD"
          ></input>
          <label htmlFor="CARD">
            <div className="title">
              <h4>Оплата картой онлайн</h4>
              <p>
                Ваше соединение защищено, онлайн-оплата производится в
                защищенном режиме с использованием протокола шифрования SSL.
              </p>
            </div>
          </label>
        </div>
        { <div id="PaymentMethod" className="inputs">
          <input
            defaultChecked
            type="radio"
            name="PaymentMethod"
            value="DOLYAME"
            className="PaymentMethodInput"
            id="DOLYAME"
          ></input>
          <label htmlFor="DOLYAME">
            <div className="title">
              <h4>
                Оплата сервисом "Долями".{" "}
                <a
                  href="https://dolyame.ru/help/customer/about/#q1"
                  target="blank"
                >
                  Подробнее.
                </a>
              </h4>
              <p>
                Ваше соединение защищено, онлайн-оплата производится в
                защищенном режиме с использованием протокола шифрования SSL.
              </p>
            </div>
          </label>
        </div> }
      </fieldset>
    </div>
  );
}
