import React from 'react'
import './css/PreLoader.css'

export default function PreLoader() {
    return (
        <div className='PreLoader'>
            <p>Идет загрузка страницы</p>
            <p>Пожалуйста, подождите...</p>
            <div className='img'></div>
        </div>
    )
}
