import React, {useEffect, useState} from 'react'
import Way from '../Components/Pages/globalComponents/Way'
import MasterClassesAndCoursesContent from '../Components/Pages/MasterClassesAndCourses/MasterClassesAndCoursesContent'
//import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import Text from "../Components/StaticForms/Text/Text";

export default function MasterClassesAndCourses() {
    const [way] = useState(["Mary Furnitura", "Мастер-классы и курсы"])
    const [text, setText] = useState();
    const [meta, setMeta] = useState();
    const [h1, setH1] = useState("Мастер-классы и курсы")

    useEffect(() => {
        fetch(global.components.url + "/product/api/page/master_classes_and_courses/?format=json")
            .then(res => res.json())
            .then((result) => {
                setH1(result["h1"])
                setText(result["text"]);
                setMeta(<Helmet>
                    <title>{result["title"]}</title>
                    <meta name="description" content={result['description']}/>
                </Helmet>)
            })
    }, [])
    return (
        <div>
            {meta}
            <Way
                title={h1}
                way={way}
                noBorder={true}
            />
            <MasterClassesAndCoursesContent/>
            {
                text ? <Text text={text} /> : <div></div>
            }
        </div>
    )
}
