import React, { useState, useEffect, useCallback } from 'react'
import "./css/InformationProfile.css"


import BelCountry from '../../registration/img/bel.png'
import RuCountry from '../../registration/img/ru.png'
import Orders from './Orders';
import OrderHistory from './OrderHistory/OrderHistory';
import Subscription from './Subscription';
import Password from "../../../StaticForms/PasswordField/Password";

export default function InformationProfile(props) {

    // profile Data
    const [region, setRegion] = useState(props.profileData.region);
    const [phone, setPhone] = useState(props.profileData.phone);
    const [surname, setSurname] = useState(props.profileData.surname);
    const [name, setName] = useState(props.profileData.name);
    const [middlename, setMiddlename] = useState(props.profileData.middlename);
    const [email, setEmail] = useState(props.profileData.email);

    // Top menu
    const [lastBut, setLastBut] = useState("Payed");
    const [ActiveBH, setActiveBH] = useState("Close");

    // products for history
    const [products, setProducts] = useState([])


    useEffect(() => {
        if (props.link === "personalData") {
            // document.getElementById("regions").textContent = props.profileData.region;
            document.getElementById("phone").value = props.profileData.phone;
            document.getElementById("secName").value = props.profileData.surname;
            document.getElementById("name").value = props.profileData.name;
            document.getElementById("therdName").value = props.profileData.middlename;
            document.getElementById("email").value = props.profileData.email;
            takeCountry({"currentTarget": {"id": "ruCountry"}})
        }

        if (props.link === "currentOrders") {
            document.getElementById(lastBut).classList.add("active")
            if (document.getElementById("Payed").classList.value === "active" && document.getElementById("NotPayed").classList.value === "active") {
                document.getElementById("Payed").classList.remove("active")
                document.getElementById("NotPayed").classList.remove("active")
                document.getElementById(lastBut).classList.add("active")
            }
        }
        if (props.link === "OrderHistory") {
            document.getElementById(ActiveBH).classList.add("active")
            if (document.getElementById("Close").classList.value === "active" && document.getElementById("Canselled").classList.value === "active") {
                document.getElementById("Close").classList.remove("active")
                document.getElementById("Canselled").classList.remove("active")
                document.getElementById(ActiveBH).classList.add("active")
            }
        }

         if (products.length === 0) {
            updateProducts()
        }
    }, [props])

    // Также запускается при отмене и\или при повторении заказа
    const updateProducts = () => {
        let productss = []
        fetch(global.components.url + "/order/api/order/?user=" + props.profileData.id)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('hello information profile')
                    result.forEach((item) => {
                        productss.push(item)
                    })
                    setProducts(productss)
                })
    }

    const noUpdatepage = (e) => {
        e.preventDefault();
    }

    const openShadowTelephoneCountry = () => {
        let statusShadow = document.getElementById("shadowTelephoneCountry").style.display;

        if (statusShadow === "none") {
            document.getElementById("shadowTelephoneCountry").style.display = "inline-block";
        } else {
            document.getElementById("shadowTelephoneCountry").style.display = "none";
        }
    }

    const takeCountry = (e) => {
        let id = e.currentTarget.id;

        if (id === "ruCountry") {
            setRegion("+7");
            document.getElementById("imageCountry").style.backgroundImage = `url(${RuCountry})`
        } else if (id === "belCountry") {
            setRegion("+375");
            document.getElementById("imageCountry").style.backgroundImage = `url(${BelCountry})`
        }
        document.getElementById("shadowTelephoneCountry").style.display = "none";
    }

    // form's sub
    const updatePersonInfo = () => {
        setEmail(document.getElementById("email").value);
        setRegion(document.getElementById("regions").textContent);
        setPhone(document.getElementById("phone").value);
        setSurname(document.getElementById("secName").value);
        setName(document.getElementById("name").value);
        setMiddlename(document.getElementById("therdName").value);

        let UpdateData = [{
            email: document.getElementById("email").value,
            region: region,
            phone: document.getElementById("phone").value,
            surname: document.getElementById("secName").value,
            name: document.getElementById("name").value,
            middlename: document.getElementById("therdName").value,
        }]

        UpdateData.forEach((item) => {
            console.log(item);
        })

        // PATCH
        // console.log(props.profileData.id);
        fetch(global.components.url + "/user/api/user/" + props.profileData.id + "/", {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // "x-csrf-token": token,
            },
            body: JSON.stringify({
                "id": props.profileData.id,
                "phone": document.getElementById("phone").value,
                "region": document.getElementById("regions").textContent,
                "surname": document.getElementById("secName").value,
                "name": document.getElementById("name").value,
                "middlename": document.getElementById("therdName").value,
                "email": document.getElementById("email").value
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    alert("Изменения сохранены")
                }
            )
    }

    const updatePersonPassword = () => {
        if (document.getElementById("pass").value !== document.getElementById("secPass").value) {
            document.getElementById("passError").style.display = "inline-block";
        } else {
            document.getElementById("passError").style.display = "none";
            fetch(global.components.url + "/user/api/user/change_password/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // "x-csrf-token": token,
                },
                body: JSON.stringify({
                    "new_password": document.getElementById("secPass").value,
                }),
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        if(result["new_password"]){
                            let error = document.getElementById("passError")
                            error.style.display = "inline-block";
                            error.textContent = result["new_password"].join("\n")
                        }
                        else{
                            alert("Пароль сменён")
                        }
                    }
                )
        }
    }
    const ShadowError = () => {
        document.getElementById("passError").style.display = "none";
    }

    const TakeOrders = (e) => {
        let activeB = e.currentTarget.id;
        if (activeB !== lastBut) {
            document.getElementById(activeB).classList.add("active");
            document.getElementById(lastBut).classList.remove("active");
            setLastBut(activeB);
        }
    }
    const TakeHistory = (e) => {
        let activeBH = e.currentTarget.id;
        if (activeBH !== ActiveBH) {
            document.getElementById(activeBH).classList.add("active");
            document.getElementById(ActiveBH).classList.remove("active");
            setActiveBH(activeBH);
        }
    }


    // Отмена заказа
    const UPDStatusOrder = useCallback((id, status) => {
        let NewArrProductsWithMoveOrders = []
        products.forEach((item) => {
            if (item.id === id) {
                if (status === "CANCELLED") {
                    item.status = "CANCELLED"
                } else if (status === "ACTIVE") {
                    item.status = "ACTIVE"
                }
            }
            NewArrProductsWithMoveOrders.push(item);
        })
        setProducts(NewArrProductsWithMoveOrders);
    })

    if (props.link === "personalData") {
        return (
            <div className='right InformationProfile'>
                <div className='personalData'>
                    <h3>Личные данные</h3>
                    <div className='personality information'>
                        <form className='FormUpdatepersInfo' onSubmit={noUpdatepage}>
                            <div className="field telephone">
                                <span className='inf'>
                                    <p>Телефон<span>*</span></p>
                                    <p className='information'>Необходим для уточнения деталей заказа</p>
                                </span>
                                <span className='telephoneInput'>
                                    <button id='country'
                                        onClick={openShadowTelephoneCountry}
                                    >
                                        <div className='img' id='imageCountry'></div>
                                        <div className='arrDown'></div>
                                    </button>

                                    <div id='shadowTelephoneCountry' style={{ display: "none" }}>
                                        <button id='ruCountry'
                                            onClick={takeCountry}
                                        >
                                            <div className='img'></div>
                                        </button>
                                        {/*
                                        <button id='belCountry'
                                            onClick={takeCountry}>
                                            <div className='img'></div>
                                        </button>
                                        */}
                                    </div>
                                    <span id='regions'>
                                        {region}
                                    </span>
                                    <input id='phone'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        maxLength={10}
                                    ></input>
                                </span>
                            </div>
                            <div className="field secName">
                                <span className='inf'>
                                    <p>Фамилия<span>*</span></p>
                                    <p className='information'>Необходимо для доставки</p>
                                </span>
                                <input id='secName'></input>
                            </div>
                            <div className="field name">
                                <span className='inf'>
                                    <p>Имя<span>*</span></p>
                                    <p className='information'></p>
                                </span>
                                <input id='name'></input>
                            </div>

                            <div className="field therdName">
                                <span className='inf'>
                                    <p>Отчество<span>*</span></p>
                                    <p className='information'></p>
                                </span>
                                <input id='therdName'></input>
                            </div>

                            <div className="field email">
                                <span className='inf'>
                                    <p>Email<span>*</span></p>
                                    <p className='information'>Получение уведомлений о статусе заказа</p>
                                </span>
                                <input id='email'></input>
                            </div>

                            <div className='sub'>
                                <p className='safeEdit' style={{ display: "none" }}>Изменения сохранены </p>
                                <button type='submit' className='buttonUpdateInfo' onClick={updatePersonInfo}>Сохранить изменения</button>
                            </div>

                            <div className='clearfix'></div>
                        </form>
                    </div>
                    <h3>Смена пароля</h3>
                    <div className='personality password'>
                        <form className='UpdatePassword' onSubmit={noUpdatepage}>
                            <div className="field pass">
                                <span className='inf'>
                                    <p>Новый пароль<span>*</span></p>
                                    <p className='information'></p>
                                </span>
                                <Password id='pass' onChange={ShadowError}/>
                            </div>

                            <div className="field secPass">
                                <span className='inf'>
                                    <p>Новый пароль ещё раз<span>*</span></p>
                                    <p className='information' id='secPassError'></p>
                                </span>
                                <Password id='secPass' onChange={ShadowError}/>
                            </div>

                            <div className='sub'>
                                <p className='safeEdit' style={{ display: "none" }}>Изменения сохранены </p>
                                <p className='passError' id='passError' style={{ display: "none" }}>Пароли не совпадают</p>
                                <button type='submit' className='buttonUpdateInfo' onClick={updatePersonPassword}>Сохранить изменения</button>
                            </div>

                            <div className='clearfix'></div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
    if (props.link === "currentOrders") {
        return (
            <div className='right InformationProfile'>
                <div className='current Orders'>
                    <div className='title'>
                        <h3>Текущие заказы</h3>
                        <div className='buttons Orders'>
                            <button onClick={TakeOrders} id="Payed" className=''>Оплаченные заказы</button>
                            <button onClick={TakeOrders} id="NotPayed" className=''>Неоплаченные заказы</button>
                        </div>
                    </div>
                    <Orders
                        profileData={props.profileData}
                        activeB={lastBut}
                        products={products}
                        updateProducts={updateProducts}
                        UPDStatusOrder={UPDStatusOrder}
                    />
                </div>
            </div>
        )
    }
    if (props.link === "OrderHistory") {
        return (
            <div className='right InformationProfile'>
                <div className='current OrderHistory'>
                    <div className='title'>
                        <h3>История заказов</h3>
                        <div className='buttons OrderHistory'>
                            <button onClick={TakeHistory} id="Close" className=''>Завершенные заказы</button>
                            <button onClick={TakeHistory} id="Canselled" className=''>Отмененные заказы</button>
                        </div>
                    </div>
                    <OrderHistory
                        profileData={props.profileData}
                        status={ActiveBH}
                        products={products}
                        updateProducts={updateProducts}
                        UPDStatusOrder={UPDStatusOrder}
                    />
                </div>
            </div>
        )
    }
    if (props.link === "Subscription") {
        return (
            <div className='right InformationProfile'>
                <div className='Subscription'>
                    <h3>Подписка на новости магазина</h3>
                    <Subscription
                        id={props.profileData.id}
                        profileData={props.profileData}
                        email={props.profileData.email}
                    />
                </div>
            </div>
        )
    }
}