import React, { Component } from 'react'

import FormRegistration from '../Components/Pages/registration/FormRegistration';
import Way from '../Components/Pages/globalComponents/Way';
//import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';

export default class Registration extends Component {
    // TODO-сделать валидацию полей
    state = {
        way: ["Mary Furnitura", "Авторизация", "Регистрация"],
        title: "Регистрация",
    }

    render() {
        return (
            <div className='Registration'>
                <Helmet>
                    <title>Регистрация</title>
                </Helmet>
                <Way
                    title={this.state.title}
                    way={this.state.way}
                />
                <FormRegistration />
                {/* <StaticScrollUp /> */}
            </div>
        )
    }

}
