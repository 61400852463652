import React, {useEffect, useState} from 'react'
import './css/Mailing.css'
import { Link } from "react-router-dom";
import Global from "../../../Global";

const Mailing = () =>{
    const [email, setEmail] = useState(null)

    function SendMail(event) {
        event.preventDefault()
        let mail = event.target[0].value
        setEmail(mail);
    }

    return (
        <div className='Mailing'>
            <div className='content'>
                <div className='title'>
                    <div className='left'>
                        <h3>Приятная рассылка</h3>
                    </div>
                    <div className='right'>
                        <p>Узнавайте первыми о новинках, будьте в курсе всех акций, получите доступ к уникальным предложениям</p>
                    </div>
                    <div className='clearfix'></div>
                </div>
                {email ?
                    <div>
                        <Global email={email} link="HomeMailing"/>
                        <p onLoad={() => window.alert("Провертьте почту")}>Проверьте почту</p>
                    </div>
                    :
                    <form onSubmit={(event) => {SendMail(event); return true;}} id="mailing" className='mail'>
                        <div className='flex'>
                            <div>
                                <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required name='mail' id='mail' placeholder='Ваш email'></input>
                                <button type="submit">Подписаться</button>
                            </div>
                        </div>
                        <div className='inputs'>
                            <input defaultChecked required type="checkbox" id='personDates' name='personDate'></input>
                            <label htmlFor="personDates"><p>Я согласен на <a target="_blank" href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true">обработку персональных данных</a></p></label>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}
export default Mailing;