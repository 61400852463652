import React, { useState, useEffect } from 'react';
import "./css/Dolyame.css";
import bannerImage from "./img/banner.jpg";

export default function DolyameBanner(props) {
   
    return (
        <div className={props.clasName}>
            <p> <span className='dolyame-content'> Купи сейчас, плати потом. </span> <img src={bannerImage} alt="" /></p>
        </div>
    );
}