import React, { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import "./css/Footer.css";

import { Link } from "react-router-dom";
import Global from "../../Global";

const Footer = () => {
  const [display, setdisplay] = useState("block");
  const [catalog, setCatalog] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailMob, setEmailMob] = useState(null);

  function SendMail(event) {
    event.preventDefault();
    let mail = event.target[0].value;
    setEmail(mail);
  }
  function SendMailMobile(event) {
    event.preventDefault();
    let mail = event.target[0].value;
    setEmailMob(mail);
  }

  useEffect(() => {
    fetch(global.components.url + "/product/api/category/?format=json").then(
      async (res) => {
        // setCatalog(await res.json());
        let result = await res.json();
        let catalogs = [];
        let count = result.length <= 6 ? result.length - 1 : 5;
        for (let i = 0; i <= count; i++) {
          catalogs.push(
            <li>
              <Link
                onClick={() => {
                  window.location.href = `/catalog/${result[i].slug}/`;
                }}
              >
                {result[i].name}
              </Link>
            </li>
          );
        }
        setCatalog(catalogs);
      }
    );
  }, []);

  const scrollUpNone = () => {
    if (document.getElementById("scrolUp") !== null) {
      document.getElementById("scrolUp").style.display = "none";
    }
    document.getElementById("scrolUpButton").style.display = "block";
  };
  const scrollUpBlock = () => {
    if (document.getElementById("scrolUp") !== null) {
      document.getElementById("scrolUp").style.display = "block";
    }
  };
  const ScrollUp = () => {
    window.scrollTo(0, 0);
  };

  const OpenShortInfo = (e) => {
    let id = e.currentTarget.id + "Img",
      mobileInfo = e.currentTarget.id + "mobileInfo";

    if (
      document.getElementById(id).style.transform === "" ||
      document.getElementById(id).style.transform === "rotate(180deg)"
    ) {
      document.getElementById(id).style.transform = "rotate(0deg)";
      document.getElementById(mobileInfo).style.display = "inline-block";
    } else {
      document.getElementById(id).style.transform = "rotate(180deg)";
      document.getElementById(mobileInfo).style.display = "none";
    }
  };

  return (
    <div className="Footer" id="Footer">
      <div className="content">
        <div className="right" id="scrolUpStatic">
          <button
            onClick={ScrollUp}
            id="scrolUpButton"
            // style={{ display: "none" }}
          ></button>
        </div>
        <div className="clearfix"></div>
        <div className="Waypoint">
          <Waypoint
            onEnter={scrollUpNone.bind(this, "")}
            onLeave={scrollUpBlock.bind(this, "")}
          />
        </div>

        <div className="clearfix"></div>

        <div className="doubleLine"></div>
        <div className="copyLogo">
          <div className="logo"></div>
          <div className="copyrate">©Mary Furnitura, 2022</div>
        </div>

        <div className="Info">
          <ul className="catalog">
            <h4>Каталог</h4>

            {catalog ? catalog.map((i) => i) : <div></div>}
            <Link to={"/catalog/"}>
              <li>
                <button>Больше категорий</button>
              </li>
            </Link>
          </ul>
          <ul className="info">
            <h4>Инфо</h4>
            <li>
              <Link to={"/discounts/"}>Распродажа</Link>
            </li>
            <li>
              <Link to={"/about_us/"}>О нас</Link>
            </li>
            <li>
              <a target="_blank" href={global.components.course[0]}>
                Мастер классы и курсы
              </a>
            </li>
            <li>
              <Link to={"/garantdeliveryandpayment/"}>Доставка и оплата</Link>
            </li>
            <li>
              <a href="https://docs.google.com/document/d/10VOZC_407ZJOP_3K5WbxV0iArkgtsbr6/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true">
                Оферта
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true">
                Политика
                <br />
                конфиденциальности
              </a>
            </li>
          </ul>
          <ul className="contacts">
            <h4>Контакты</h4>
            <li>
              <a href={"tel:" + global.components.phone[0]}>
                {global.components.phone[1]}
              </a>
            </li>
            <li>
              <a href={"mailto:" + global.components.gmail[0]}>
                {global.components.gmail[1]}
              </a>
            </li>
            <li>
              <a target="_blank" href={global.components.whatsapp[0]}>
                <strong>{global.components.whatsapp[1]}</strong>
              </a>
            </li>
            <li>
              <a target="_blank" href={global.components.vk[0]}>
                <strong>{global.components.vk[1]}</strong>
              </a>
            </li>
            <li>
              <a target="_blank" href={global.components.tg[0]}>
                <strong>{global.components.tg[1]}</strong>
              </a>
            </li>
            <li>
              <a target="_blank" href={global.components.viber[0]}>
                <strong>{global.components.viber[1]}</strong>
              </a>
            </li>
            <li>
              <a
                rel="nofollow"
                target="_blank"
                href={global.components.fair[0]}
              >
                <strong>{global.components.fair[1]}</strong>
              </a>
            </li>
          </ul>
        </div>

        <div className="mobileInfo">
          <ul className="catalog">
            <h4 id="catalog" onClick={OpenShortInfo}>
              Каталог<div className="img" id="catalogImg"></div>
            </h4>
            <div className="LiInfoMobile" id="catalogmobileInfo">
              <ul>
                {catalog ? catalog.map((i) => i) : <div></div>}
                <Link to={"/catalog/"}>
                  <li>
                    <button>Больше категорий</button>
                  </li>
                </Link>
              </ul>
            </div>
          </ul>
          <ul className="info">
            <h4 id="info" onClick={OpenShortInfo}>
              Инфо<div className="img" id="infoImg"></div>
            </h4>
            <div className="LiInfoMobile" id="infomobileInfo">
              <ul>
                <li>
                  <a target="_blank" href={global.components.course[0]}>
                    Мастер классы и курсы
                  </a>
                </li>
                <li>
                  <Link to={"/about_us/"}>О нас</Link>
                </li>
                <li>
                  <Link to={"/disconts/"}>Распродажа</Link>
                </li>
                <li>
                  <Link to={"/garantdeliveryandpayment/"}>
                    Доставка и оплата
                  </Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true"
                  >
                    Политика конфиденциальности
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://docs.google.com/document/d/10VOZC_407ZJOP_3K5WbxV0iArkgtsbr6/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true"
                  >
                    Оферта
                  </a>
                </li>
              </ul>
            </div>
          </ul>
          <ul className="contacts">
            <h4 id="contacts" onClick={OpenShortInfo}>
              Контакты<div className="img" id="contactsImg"></div>
            </h4>
            <div className="LiInfoMobile" id="contactsmobileInfo">
              <ul>
                <li>
                  <a href={"tel:" + global.components.phone[0]}>
                    {global.components.phone[1]}
                  </a>
                </li>
                <li>
                  <a href={"mailto:" + global.components.gmail[0]}>
                    {global.components.gmail[1]}
                  </a>
                </li>
                <li>
                  <a target="_blank" href={global.components.whatsapp[0]}>
                    <strong>{global.components.whatsapp[1]}</strong>
                  </a>
                </li>
                <li>
                  <a target="_blank" href={global.components.vk[0]}>
                    <strong>{global.components.vk[1]}</strong>
                  </a>
                </li>
                <li>
                  <a target="_blank" href={global.components.tg[0]}>
                    <strong>{global.components.tg[1]}</strong>
                  </a>
                </li>
                <li>
                  <a target="_blank" href={global.components.viber[0]}>
                    <strong>{global.components.viber[1]}</strong>
                  </a>
                </li>
                <li>
                  <a
                    rel="nofollow"
                    target="_blank"
                    href={global.components.fair[0]}
                  >
                    <strong>{global.components.fair[1]}</strong>
                  </a>
                </li>
              </ul>
            </div>
          </ul>

          <ul className="soc">
            <li className="copyLogo">
              <p>©Mary Furnitura, 2022</p>
            </li>
          </ul>
        </div>

        <ul className="mailing">
          <h4>Следите за новинками и скидками</h4>
          <li>
            {email ? (
              <div>
                <Global email={email} link="HomeMailing" />
              </div>
            ) : (
              <form
                id="footer__mailing"
                className="footer__mail"
                onSubmit={(event) => {
                  SendMail(event);
                  return true;
                }}
              >
                <input required placeholder="Ваш email"></input>
                <button type="submit"></button>
                <div className="inputs">
                  <input
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    required
                    name="footer__mail"
                    id="footer__mail"
                    type="checkbox"
                    defaultChecked
                  ></input>
                  <label htmlFor="personDate">
                    <p>
                      Я согласен на{" "}
                      <a
                        target="_blank"
                        href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true"
                      >
                        обработку персональных данных
                      </a>
                    </p>
                  </label>
                </div>
              </form>
            )}
          </li>
          <li className="soc">
            <a
              target="_blank"
              href={global.components.whatsapp[0]}
              className="whatsapp"
            ></a>
            <a
              target="_blank"
              href={global.components.tg[0]}
              className="telegram"
            ></a>
            <a
              target="_blank"
              href={global.components.vk[0]}
              className="vk"
            ></a>
          </li>
          <div className="clearfix"></div>
        </ul>

        <div className="centerFlexFooter">
          <div className="mobileInfo">
            <ul className="catalog">
              <h4 id="catalogMob" onClick={OpenShortInfo}>
                Каталог<div className="img" id="catalogMobImg"></div>
              </h4>
              <div className="LiInfoMobile" id="catalogMobmobileInfo">
                <ul>
                  {catalog ? catalog.map((i) => i) : <div></div>}
                  <li>
                    <Link to={"/catalog/"}>Больше категорий</Link>
                  </li>
                </ul>
              </div>
            </ul>
            <ul className="info">
              <h4 id="infoMob" onClick={OpenShortInfo}>
                Инфо<div className="img" id="infoMobImg"></div>
              </h4>
              <div className="LiInfoMobile" id="infoMobmobileInfo">
                <ul>
                  <li>
                    <a target="_blank" href={global.components.course[0]}>
                      Мастер классы и курсы
                    </a>
                  </li>
                  <li>
                    <Link to={"/about_us/"}>О нас</Link>
                  </li>
                  <li>
                    <Link to={"/discounts/"}>Распродажа</Link>
                  </li>
                  <li>
                    <Link to={"/garantdeliveryandpayment/"}>
                      Доставка и оплата
                    </Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true"
                    >
                      Политика конфиденциальности
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://docs.google.com/document/d/10VOZC_407ZJOP_3K5WbxV0iArkgtsbr6/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true"
                    >
                      Оферта
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
            <ul className="contacts">
              <h4 id="contactsMob" onClick={OpenShortInfo}>
                Контакты<div className="img" id="contactsMobImg"></div>
              </h4>
              <div className="LiInfoMobile" id="contactsMobmobileInfo">
                <ul>
                  <li>
                    <a href={"tel:" + global.components.phone[0]}>
                      {global.components.phone[1]}
                    </a>
                  </li>
                  <li>
                    <a href={"mailto:" + global.components.gmail[0]}>
                      {global.components.gmail[1]}
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={global.components.whatsapp[0]}>
                      <strong>{global.components.whatsapp[1]}</strong>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={global.components.vk[0]}>
                      <strong>{global.components.vk[1]}</strong>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={global.components.tg[0]}>
                      <strong>{global.components.tg[1]}</strong>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={global.components.viber[0]}>
                      <strong>{global.components.viber[1]}</strong>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="nofollow"
                      target="_blank"
                      href={global.components.fair[0]}
                    >
                      <strong>{global.components.fair[1]}</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </ul>

            <ul className="soc">
              <li className="copyLogo">
                <p>©Mary Furnitura, 2022</p>
              </li>
            </ul>
          </div>

          <ul className="mailing">
            <h4>Следите за новинками и скидками</h4>
            <li>
              {emailMob ? (
                <div>
                  <Global email={emailMob} link="HomeMailing" />
                </div>
              ) : (
                <form
                  onSubmit={(event) => {
                    SendMailMobile(event);
                    return true;
                  }}
                >
                  <input
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    required
                    placeholder="Ваш email"
                  ></input>
                  <button></button>
                  <div className="inputs">
                    <input
                      required
                      type="checkbox"
                      defaultChecked
                      id="personDateMob"
                      name="personDateMob"
                    ></input>
                    <label htmlFor="personDateMob">
                      <p>
                        Я согласен на{" "}
                        <a
                          target="_blank"
                          href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true"
                        >
                          обработку персональных данных
                        </a>
                      </p>
                    </label>
                  </div>
                </form>
              )}
            </li>
            <li className="soc">
              <a
                target="_blank"
                href={global.components.whatsapp[0]}
                className="whatsapp"
              ></a>
              <a
                target="_blank"
                href={global.components.tg[0]}
                className="telegram"
              ></a>
              <a
                target="_blank"
                href={global.components.vk[0]}
                className="vk"
              ></a>
            </li>
            <div className="clearfix"></div>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default Footer;
