import React, { useEffect, useState, useCallback } from "react";
import OrderProductComponent from "./OrderProduct/OrderProductComponent";

export default function Orders(props) {
  const [Payed, setPayed] = useState();
  const [NotPayed, setNotPayed] = useState();
  const [prodcts, setprodcts] = useState();

  useEffect(() => {
    let mPayed = [];
    let mNotPayed = [];

    if (props.products.length > 0) {
      if (props.products !== prodcts) {
        props.products.forEach((item) => {
          let PAID_STATUSES = [
            "PAID",
            "COLLECTED",
            "TRANSFERRED",
            "DELIVERED",
            "REQUEST-REFUND",
          ];
          if (
            item["delivery"]["status"] === "WAIT-PAY" ||
            item["delivery"]["status"] === "WAIT-OPERATOR" ||
            item["delivery"]["status"] === "CHANGE-WAIT-PAY" ||
            item["delivery"]["status"] === "CONFIRM-WAIT-PAY"
          ) {
            mNotPayed.push(item);
          } else if (PAID_STATUSES.includes(item["delivery"]["status"])) {
            mPayed.push(item);
          }
        });
        setprodcts(props.products);

        if (mPayed.length === 0) {
          setPayed(null);
        } else {
          setPayed(mPayed);
        }

        if (mNotPayed.length === 0) {
          setNotPayed(null);
        } else {
          setNotPayed(mNotPayed);
        }
      }
    }
  }, [prodcts, props.products]);

  const updateProductsOrders = useCallback((pay) => {
    if (pay === "NotPayed") {
      setNotPayed(undefined);
    } else if (pay === "Payed") {
      setPayed(undefined);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const SendCloseOrder = useCallback((id, status) => {
    props.UPDStatusOrder(id, status);

    fetch(global.components.url + "/order/api/order/change_delivery/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "x-csrf-token": token,
      },
      body: JSON.stringify({
        order_id: id,
        status: status,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('hello Orders')
        console.log(result);
      });
  });

  if (props.activeB === "Payed") {
    if (Payed !== undefined && Payed !== null) {
      return (
        <div className="Payed" key="DivPayed">
          {Payed.map((item, index) => (
            <OrderProductComponent
              key={"DivPayed" + index}
              OrderProductInfo={item}
              pay={"Payed"}
              SendCloseOrder={SendCloseOrder}
              updateProducts={props.updateProducts}
              updateProductsOrders={updateProductsOrders}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="Orders">
          <OrderProductComponent
            key={"DivPayed"}
            OrderProductInfo={null}
            pay={"Payed"}
          />
        </div>
      );
    }
  }
  // }
  if (props.activeB === "NotPayed") {
    if (NotPayed !== undefined && NotPayed !== null) {
      return (
        <div className="NotPayed" key="DivNotPayed">
          {NotPayed.map((item, index) => (
            <OrderProductComponent
              key={"DivNotPayed" + index}
              OrderProductInfo={item}
              pay={"NotPayed"}
              SendCloseOrder={SendCloseOrder}
              updateProducts={props.updateProducts}
              updateProductsOrders={updateProductsOrders}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="NotPayed">
          <OrderProductComponent
            key={"DivNotPayed"}
            OrderProductInfo={null}
            pay={"NotPayed"}
          />
        </div>
      );
    }
  }
}
