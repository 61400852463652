import React, {Component} from 'react'
import Home from './Routes/Home';
import Catalog from './Routes/Catalog';
import ProductInformation from './Routes/ProductInformation';
import Basket from './Routes/Basket';
import Disconts from './Routes/Disconts';
import Header from './Components/StaticComponents/Header';
import StaticHeader from './Components/StaticComponents/StaticHeader';
import Profile from './Routes/Profile';
import MakingAnOrder from './Routes/MakingAnOrder';
import PreLoader from './Components/StaticComponents/PreLoader';
import SearchCatalogData from './Routes/SearchCatalogData';
import "./config"



export default class Global extends Component {
    state = {
        // Для запроса к серваку
        error: null,
        isLoaded: false,

        // basket data
        productListInBasket: [],
        reapeat: null,
        productListOnLiked: [],
        // Для группировки в корзине. количество товаров
        quantityOnBasket: 0,
        quantityOnLiked: 0,
        quantityHaventOnBasket: 0,
        // Для подсчета стоимости
        cost: 0,
        discount: 0,

        // products data
        products: [],
        // Для данных
        discontProducts: [],

        // filtres
        filtres: [],
        searchFiltreData: [],
        category: [],
        // Для запроса
        characteristic__id: '',
        category__in: '',

        // Для входа в аккаунт
        is_authenticated: '',
        profileData: [],
        isLoadedProfile: false,

        // searchProducts: ''
    }

/*    constructor(props) {
        super(props);




    }*/


    componentDidUpdate(prevProps){
        if (prevProps.quantityOnBasket !== this.props.quantityOnBasket) {
            if (this.props.quantityOnBasket) {
                this.setState({
                    quantityOnBasket: this.props.quantityOnBasket,
                })
            }
        }
        if (prevProps.quantityOnLiked !== this.props.quantityOnLiked) {
            if (this.props.quantityOnLiked) {
                this.setState({
                    quantityOnLiked: this.props.quantityOnLiked,
                })
            }
        }
    }

    updateDataProducts = (productL) => {
        let arr = [];
        let newArr = [];

        arr = this.state.productListInBasket;
        let deleteIndex = null;

        arr.forEach((item, index) => {
            if (item.id === productL.id) {
                item.quantity = productL.quantity;
                deleteIndex = index;
            }
            newArr.push(item);
        })

        if (deleteIndex !== null) {
            this.setState({
                productListInBasket: newArr
            }, function () {
                let arr = [];
                arr = this.state.productListInBasket;
                let yAmount = []
                let noAmount = []
                arr.forEach((item) => {
                    if (item.info.amount > 0) {
                        yAmount.push(item)
                    } else {
                        noAmount.push(item)
                    }
                })
                this.UpdateCostOnBasket();
            })
        } else {
            this.setState({
                productListInBasket: [...this.state.productListInBasket, productL]
            }, function () {
                let arr = [];
                arr = this.state.productListInBasket;
                let yAmount = []
                let noAmount = []
                arr.forEach((item) => {
                    if (item.info.amount > 0) {
                        yAmount.push(item)
                    } else {
                        noAmount.push(item)
                    }
                })
                this.setState({
                    quantityOnBasket: yAmount.length,
                    quantityHaventOnBasket: noAmount.length,
                })
                this.UpdateCostOnBasket();
            })
        }

    }
    updateLikeProduct = () => {
        let quantityLiked;
        if (localStorage.getItem('LikedProducts').split(',')[0] !== '') {
            quantityLiked = localStorage.getItem('LikedProducts').split(',').length;
        } else {
            quantityLiked = 0
        }

        this.setState({
            quantityOnLiked: quantityLiked
        })
    }
    deleteProductOnList = (newArr) => {
        this.setState({
            productListInBasket: newArr,
        }, function () {
            let arr = [];
            arr = this.state.productListInBasket;
            let yAmount = []
            let noAmount = []
            arr.forEach((item) => {
                console.log(item.info.amount);
                if (item.info.amount > 0) {
                    yAmount.push(item)
                } else {
                    noAmount.push(item)
                }
            })
            this.setState({
                quantityOnBasket: yAmount.length,
                quantityHaventOnBasket: noAmount.length,
            }, function () {
                this.UpdateCostOnBasket();
            })
        })

    }
    updateQuantityProducts = (idProd, updateQuantity, productListInBasket) => {
        let arr = [];
        let newArr = [];
        arr = productListInBasket;


        arr.forEach(function (item) {
            if (item.id === idProd) {
                item.quantity = updateQuantity;
            }
            newArr.push(item);
        });

        this.setState({
            productListInBasket: newArr,
        })

        this.UpdateCostOnBasket();
    }
    setSearchProducts = (input) => {
        console.log("inputData", input)
        window.location = '/Search/' + input
    }
    UpdateCostOnBasket = () => {
        let arr = [];
        arr = this.state.productListInBasket;

        let cost = 0;
        let discount = 0;

        arr.forEach(function (item) {
            let f_cost;
            let f_discount;

            if (item.info.discount !== null) {
                f_discount = item.quantity * item.info.discount_price.difference;
                f_cost = item.quantity * item.info.discount_price.new_price;
            } else {
                f_cost = item.quantity * item.info.price;
                f_discount = 0;
            }

            cost = cost + f_cost;
            discount = discount + f_discount
        });

        this.setState({
            cost: cost,
            discount: discount,
        }, function () {
            this.setBasketProductsOnLocalStorage();
        })
    }

    /*searchFiltres = (filtres) => {

        let NewArr = [];
        let OldArr = this.state.searchFiltreData;
        let type;

        filtres.forEach((item) => {
            if (NewArr[0] === null) {
                NewArr = []
            }
            if (item.value !== null) {
                NewArr.push(item.value);
            } else {
                NewArr.push(item.value)
            }
            type = item.type
        });

        let obj;

        if (filtres.length !== 0 && filtres[0].id !== undefined) {
            obj = { attribute_id: filtres[0].id, items: NewArr, type: type }
        }

        let indToPush = true;
        let num = OldArr.length - 1
        let arrToPuh = [];

        if (OldArr.length === 0) {
            OldArr.push(obj);
        } else {
            OldArr.forEach((item, index) => {
                if (item.attribute_id !== undefined && item.attribute_id === obj.attribute_id) {
                    OldArr[index] = obj;
                    indToPush = false;
                }
                if (obj.type === "category__in" && item.type === "category__in") {
                    OldArr[index] = obj;
                    indToPush = false;
                }
                if (index === num && indToPush === true) {
                    OldArr.push(obj);
                }
            })

            OldArr.forEach((item, index) => {
                if (item.items === null) {
                    delete OldArr[index];
                }
            })
        }

        OldArr.forEach((item, index) => {
            if (item !== undefined && item.items[0] !== null) {
                arrToPuh.push(item);
            } else {
                arrToPuh = []
            }
        })

        this.setState({
            searchFiltreData: arrToPuh
        },
            function () {
                let characteristic__id = "";
                let category__in = "";
                let stringCharacteristic__id = [];

                console.log("searchFiltreData", this.state.searchFiltreData);

                this.state.searchFiltreData.forEach((item) => {
                    if (item.type === "category__in") {
                        category__in = item.attribute_id;
                    } else if (item.type === "characteristic__id") {
                        // characteristic__id = item.items.toString() + "," + characteristic__id;
                        stringCharacteristic__id.push("&characteristic__id=" + item.items.toString())

                    }
                });
                console.log(stringCharacteristic__id.toString())
                this.setState({
                    category__in: category__in,
                    characteristic__id: stringCharacteristic__id,
                }, function () {
                    this.fetchFiltres();
                })
            }
        )
    }

    fetchFiltres = () => {
        let arrDiscont = []

        fetch(
            global.components.url + "/product/api/product/?price__lte=&price__gte=&category__in=" + this.state.category__in + this.state.characteristic__id + "&article__in="
        )
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        products: result,
                    }, function () {
                        // only discont's
                        this.state.products.forEach(item => {
                            if (item.discount !== null) {
                                arrDiscont.push(item);
                            }
                        })
                        this.setState({
                            discontProducts: arrDiscont,
                        })
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
*/

    setBasketProductsOnLocalStorage = () => {
        let arrArticlesProducts = [];
        let arrQuantityProducts = [];

        this.state.productListInBasket.forEach(item => {
            arrArticlesProducts.push(item.id);
            arrQuantityProducts.push(item.quantity);
        })

        localStorage.setItem('productsArticles', arrArticlesProducts);
        localStorage.setItem('productsQuantity', arrQuantityProducts);
    }

    creteBasketObject = () => {
        if (localStorage.getItem("productsArticles") !== '') {
            let arrArticlesProducts = localStorage.getItem("productsArticles").split(',');
            let arrQuantityProducts = localStorage.getItem("productsQuantity").split(',');
            let arrProductsInfo = [];
            let ProductsInfo;

            let cost = 0;
            let discount = 0;

            fetch(global.components.url + "/product/api/product/?article__in=" + localStorage.getItem("productsArticles") + "&format=json")
                .then(res => res.json())
                .then((result) => {

                    ProductsInfo = result;
                    let objectProduct;

                    arrArticlesProducts.forEach((item, index) => {
                        let article = item;
                        let indexx = index;
                        ProductsInfo.forEach((item) => {
                            if (item.article === article) {
                                let Article, Info, Quantity;
                                Article = article;
                                Info = item;
                                Quantity = arrQuantityProducts[indexx]
                                objectProduct = { id: Article, info: Info, quantity: Number(Quantity) };
                                arrProductsInfo.push(objectProduct);
                            }
                        })
                    })

                    for (let i = 0; i < arrArticlesProducts.length; i++) {
                        let f_cost,
                            f_discount;
                        if (ProductsInfo[i].discount !== null) {
                            f_cost = arrQuantityProducts[i] * ProductsInfo[i].discount_price.new_price;
                            f_discount = arrQuantityProducts[i] * ProductsInfo[i].discount_price.difference;
                        } else {
                            f_cost = arrQuantityProducts[i] * ProductsInfo[i].price;
                            f_discount = 0;
                        }

                        cost = cost + f_cost;
                        discount = discount + f_discount;
                    }

                    let arr = [];
                    arr = result;
                    let yAmount = []
                    let noAmount = []
                    arr.forEach((item) => {
                        if (item.amount > 0) {
                            yAmount.push(item)
                        } else {
                            noAmount.push(item)
                        }
                    })
                    this.setState({
                        productListInBasket: arrProductsInfo,
                        quantityOnBasket: yAmount.length,
                        quantityHaventOnBasket: noAmount.length,
                        cost: cost,
                        discount: discount,
                    })
                })
        }
    }
    setLocalStorage = () => {
        if (this.state.productListInBasket.length <= 1) {
            if (localStorage.getItem("productsArticles") === null) {
                localStorage.setItem('productsArticles', '');
            }
            if (localStorage.getItem("productsQuantity") === null) {
                localStorage.setItem('productsQuantity', '');
            }
            this.creteBasketObject();
        }

        // set like
        if (localStorage.getItem("LikedProducts") === null) {
            localStorage.setItem('LikedProducts', '');
        }
    }
    setFullProducts = () => {
        // discont's
        let arrDiscont = [];
        if (this.state.products.length === 0) {
            fetch(global.components.url + "/product/api/product/?format=json")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            products: result
                        }, function () {
                            // only discont's
                            this.state.products.forEach(item => {
                                if (item.discount !== null) {
                                    arrDiscont.push(item);
                                }
                            })
                            this.setState({
                                discontProducts: arrDiscont,
                            })
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
            // console.log("set Full Products");
        }
    }
    /*setFiltres = () => {
        let id = window.location.href.split('/')[4]
        if (!id){
            fetch(global.components.url + "/product/api/characteristic/?format=json")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            filtres: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }else {
            fetch(global.components.url + "/product/api/category/" + id + "/?format=json")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            filtres: result["filter"],
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
        // console.log("Filtres Is Set")
    }*/
    setCategory = () => {
        fetch(global.components.url + "/product/api/category/?format=json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        category: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        // console.log("Category Is Set")
    }
    setPersonAuntificated = () => {
        fetch(global.components.url + "/user/api/user/is_authenticated/?format=json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoadedProfile: true,
                        is_authenticated: result.is_authenticated,
                    }, function () {
                        if (this.state.is_authenticated !== false) {
                            this.setState({
                                profileData: result,
                            }, function () {
                                // basket Liked product's

                                let diff;

                                fetch(global.components.url + "/user/api/user/favourite/?format=json")
                                    .then(res => res.json())
                                    .then(
                                        (result) => {
                                            let arrGlobalLikeResult = [result];

                                            let LocalLiked = [];
                                            let globalLikes = [];
                                            let newArr = [];
                                            let setArrToLocalStorage = []

                                            if (localStorage.getItem('LikedProducts').split(',')[0] !== '') {
                                                LocalLiked = localStorage.getItem('LikedProducts').split(',');
                                            }

                                            // Берем лайки с бд
                                            arrGlobalLikeResult[0].forEach(index => {
                                                console.log('arrGlobalLikeResult', index)

                                                if (index.profile === this.state.profileData.id) {
                                                    globalLikes = [...new Set(index.products)]

                                                    console.log("global", globalLikes)
                                                    console.log("local", LocalLiked)
                                                    newArr = [...globalLikes, ...LocalLiked];

                                                    // Создаем массив без повторений
                                                    setArrToLocalStorage = [...new Set(newArr)]

                                                    // Те артикли товаров которых нету в глобалке получаем и отправляем в глобалку
                                                    diff = LocalLiked.filter(x => !globalLikes.includes(x));

                                                    if (diff.length !== 0) {
                                                        diff.forEach((item) => {
                                                            console.log("fetch", item);
                                                            fetch(global.components.url + "/user/api/user/favourite/", {
                                                                method: 'POST',
                                                                headers: {
                                                                    Accept: 'application/json',
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                                    "profile": this.state.profileData.id,
                                                                    "products": [item]
                                                                }),
                                                            })
                                                                .then(res => res.json())
                                                                .then(
                                                                    (result) => {
                                                                        console.log("result likeProduct", item, result);
                                                                        if (result.status === 'remove') {
                                                                            fetch(global.components.url + "/user/api/user/favourite/", {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    Accept: 'application/json',
                                                                                    'Content-Type': 'application/json',
                                                                                },
                                                                                body: JSON.stringify({
                                                                                    "profile": this.state.profileData.id,
                                                                                    "products": [item]
                                                                                }),
                                                                            })
                                                                        }
                                                                    },
                                                                    (error) => {
                                                                        console.log("error", error);
                                                                        console.log("profile:", this.state.profileData.id);
                                                                        console.log("products:", item);
                                                                    });
                                                        })
                                                        console.log('diff', diff)
                                                    }

                                                    console.log('setArrToLocalStorage', setArrToLocalStorage)
                                                    console.log('')


                                                    localStorage.setItem('LikedProducts', setArrToLocalStorage)
                                                }
                                            })
                                        });
                            });
                        }
                    });
                },
                (error) => {
                    this.setState({
                        isLoadedProfile: true,
                        error
                    })
                    console.log("fetch person", error)
                }
            )
    }
    setTestPersonAuntificated = () => {
        if (this.state.isLoadedProfile === false) {
            this.setState({
                isLoadedProfile: true,
                profileData: {
                    "id": 3,
                    "phone": "9088098904",
                    "region": "+7",
                    "surname": "Сидельников",
                    "name": "Дмитрий",
                    "middlename": "Андреевич",
                    "email": "dimsid05@bk.ru",
                    "mailing": null
                },
                is_authenticated: true,
            }, function () {
                // basket Liked product's

                let diff;

                fetch(global.components.url + "/user/api/user/favourite/?format=json")
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let arrGlobalLikeResult = [result];

                            let LocalLiked = [];
                            let globalLikes = [];
                            let newArr = [];
                            let setArrToLocalStorage = []

                            if (localStorage.getItem('LikedProducts').split(',')[0] !== '') {
                                LocalLiked = localStorage.getItem('LikedProducts').split(',');
                            }

                            // Берем лайки с бд
                            arrGlobalLikeResult[0].forEach(index => {
                                console.log('arrGlobalLikeResult', index)

                                if (index.profile === this.state.profileData.id) {
                                    globalLikes = [...new Set(index.products)]

                                    console.log("global", globalLikes)
                                    console.log("local", LocalLiked)
                                    newArr = [...globalLikes, ...LocalLiked];

                                    // Создаем массив без повторений
                                    setArrToLocalStorage = [...new Set(newArr)]

                                    // Те артикли товаров которых нету в глобалке получаем и отправляем в глобалку
                                    diff = LocalLiked.filter(x => !globalLikes.includes(x));

                                    if (diff.length !== 0) {
                                        diff.forEach((item) => {
                                            console.log("fetch", item);
                                            fetch(global.components.url + "/user/api/user/favourite/", {
                                                method: 'POST',
                                                headers: {
                                                    Accept: 'application/json',
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({
                                                    "profile": this.state.profileData.id,
                                                    "products": [item]
                                                }),
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    (result) => {
                                                        console.log("result likeProduct", item, result);
                                                        if (result.status === 'remove') {
                                                            fetch(global.components.url + "/user/api/user/favourite/", {
                                                                method: 'POST',
                                                                headers: {
                                                                    Accept: 'application/json',
                                                                    'Content-Type': 'application/json',
                                                                },
                                                                body: JSON.stringify({
                                                                    "profile": this.state.profileData.id,
                                                                    "products": [item]
                                                                }),
                                                            })
                                                        }
                                                    },
                                                    (error) => {
                                                        console.log("error", error);
                                                        console.log("profile:", this.state.profileData.id);
                                                        console.log("products:", item);
                                                    });
                                        })
                                        console.log('diff', diff)
                                    }

                                    console.log('setArrToLocalStorage', setArrToLocalStorage)
                                    console.log('')


                                    localStorage.setItem('LikedProducts', setArrToLocalStorage)
                                }
                            })
                        });



            });
        }
    }

    componentDidMount() {
        this.setLocalStorage();
        this.setPersonAuntificated()

        // this.setTestPersonAuntificated()

/*
        this.setFiltres();
*/


        if (this.props.link === "Home") {
            this.setState({
                isLoaded: true,
            });
            return 1;
        }else if (this.props.link === "Catalog") {
            this.setFullProducts();
            this.setCategory();
        }else if (this.props.link === "Disconts") {
            this.setFullProducts();
            this.setCategory();
        }else if (this.props.link === "SearchCatalogData"){
            this.setState({
                isLoaded: true,
            });
            return 1;
        }else if (this.props.link === "ProductInformation") {
            this.setState({
                isLoaded: true,
            });
            return 1;
        } else if (this.props.link === "Logout"){
            this.setState({
                profileData: null,
            });
        }

        this.setFullProducts();
        this.setCategory();
        this.updateLikeProduct();
        this.setState({
            isLoaded: true,
        });
    }



    render() {
        const { error, isLoaded } = this.state;

        if (this.props.link !== "Profile") {
            if (error) {
                return (<p>Error {error.message} </p>)
            } else if (!isLoaded) {
                return (
                    <PreLoader />
                )
            } else {
                if (this.props.link === "Home") {

                }

                if (this.props.link === "Catalog") {
                    return (
                        <Catalog
                            updateDataProducts={this.updateDataProducts}
                            updateLikeProduct={this.updateLikeProduct}

                            // data
                            products={this.state.products}
/*
                            filtres={this.state.filtres}
*/
                            category={this.state.category}

                            idPersone={this.state.profileData.id}

                            quantityOnBasket={this.state.quantityOnBasket}
                            quantityOnLiked={this.state.quantityOnLiked}
                            setSearchProducts={this.setSearchProducts}
                        />
                    )
                }

                if (this.props.link === "Disconts") {
                    return (
                        <Disconts
                            // functions
                            updateDataProducts={this.updateDataProducts}
                            updateLikeProduct={this.updateLikeProduct}
                            searchFiltres={this.searchFiltres}

                            // data
                            products={this.state.discontProducts}
                            filtres={this.state.filtres}
                            category={this.state.category}

                            idPersone={this.state.profileData.id}
                        />
                    )
                }

                if (this.props.link === "Basket") {
                    return (
                        <div>
                            <Basket
                                href={this.props.href} //liked

                                productListInBasket={this.state.productListInBasket}
                                productListOnLiked={this.state.productListOnLiked}

                                deleteProductOnList={this.deleteProductOnList}
                                updateQuantityProducts={this.updateQuantityProducts}
                                updateLikeProduct={this.updateLikeProduct}

                                quantityOnBasket={this.state.quantityOnBasket}
                                quantityOnLiked={this.state.quantityOnLiked}
                                quantityHaventOnBasket={this.state.quantityHaventOnBasket}

                                cost={this.state.cost}
                                discount={this.state.discount}
                                updateDataProducts={this.updateDataProducts}

                                idPersone={this.state.profileData.id}
                            />
                        </div>
                    )
                }
                if (this.props.link === "SearchCatalogData") {

                    return (
                        <SearchCatalogData
                            updateDataProducts={this.updateDataProducts}
                            updateLikeProduct={this.updateLikeProduct}

                            category={this.state.category}

                            idPersone={this.state.profileData.id}
                            setSearchProducts={this.setSearchProducts}
                        />
                    )
                }


                if (this.props.link === "MakingAnOrder") {
                    return (
                        <MakingAnOrder
                            profileData={this.state.profileData}
                            idPersone={this.state.profileData.id}
                        />
                    )
                }

            }
        }
        // Header
        if (this.state.is_authenticated !== false) {
            if (this.props.header === "Header") {
                return (
                    <Header
                        linkOnRegProf={"profile"}
                        profileName={this.state.profileData.name}
                        quantityOnBasket={this.state.quantityOnBasket}
                        quantityOnLiked={this.state.quantityOnLiked}
                        setSearchProducts={this.setSearchProducts}
                    />
                )
            }
            if (this.props.header === "StaticHeader") {
                return (
                    <StaticHeader
                        linkOnRegProf={"profile"}
                        profileName={this.state.profileData.name}
                        link={this.props.headerLink}
                        quantityOnBasket={this.state.quantityOnBasket}
                        quantityOnLiked={this.state.quantityOnLiked}
                        setSearchProducts={this.setSearchProducts}
                    />
                )
            }
            if (this.props.link === "HomeMailing"){
                fetch(global.components.url + "/user/api/user/mailing/",{
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            // "x-csrf-token": token,
                        },
                        body: JSON.stringify({
                            "profile": this.state.profileData['id'],
                            "email": this.props.email,
                            "mailing": true
                        })
                    }
                ).then(res => res.json()).then((result) => {
                        if(result){
                            console.log(result)
                            return (<p>Вам на почту пришло подтверждение</p>)
                        }
                        else {
                            console.log('error', result)
                        }
                    }
                )
            }
        } else {
            if (this.props.header === "Header") {
                return (
                    <Header
                        linkOnRegProf={"registration"}
                        quantityOnBasket={this.state.quantityOnBasket}
                        quantityOnLiked={this.state.quantityOnLiked}
                        setSearchProducts={this.setSearchProducts}
                    />
                )
            }
            if (this.props.header === "StaticHeader") {
                return (
                    <StaticHeader
                        linkOnRegProf={"registration"}
                        link={this.props.link}
                        quantityOnBasket={this.state.quantityOnBasket}
                        quantityOnLiked={this.state.quantityOnLiked}
                        setSearchProducts={this.setSearchProducts}
                    />
                )
            }
            if (this.props.link === "HomeMailing"){
                fetch(global.components.url + "/user/api/user/mailing/",{
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            // "x-csrf-token": token,
                        },
                        body: JSON.stringify({
                            "profile": null,
                            "email": this.props.email,
                            "mailing": true
                        })
                    }
                ).then(res => res.json()).then((result) => {
                        if(result){
                            return 0;
                        }
                        else {
                            console.log('error', result)
                        }
                    }
                )
            }
        }
        if (this.props.link === "Profile") {
            if (error) {
                return (<p>Error {error.message} </p>)
            } else if (!this.state.isLoadedProfile) {
                return <PreLoader />
            } else {

            }
        }
        if (this.props.link === "Logout"){
            return (
                <Home
                    updateDataProducts={this.updateDataProducts}
                    updateLikeProduct={this.updateLikeProduct}
                    idPersone={this.state.profileData.id}
                />
            )
        }
    }
}
