import React, {Component} from 'react'
import './css/leftCatalog.css'

import {Link} from "react-router-dom";

import Baners from "../../globalComponents/Baners"
import Global from "../../../../Global";

export default class LeftCatalog extends Component {
    state = {
        category: "",
        defaultActiveLink: "personalData"
    }

    setCategory = (e) => {
        document.getElementsByClassName("catalog_active")[0].classList.remove("catalog_active");
        e.currentTarget.classList.add("catalog_active");
        document.getElementById("CategoryMenu").classList.add("closeCategoryMenu")
    }

    setDiscount = (e) => {
        this.props.changeDiscount(e.currentTarget.id);
        document.getElementsByClassName("catalog_active")[0].classList.remove("catalog_active");
        e.currentTarget.classList.add("catalog_active");
    }

    componentDidMount() {
        if (this.props.link !== undefined) {
            document.getElementById(this.props.link).classList.add("active");
        }
    }

    setActiveLink = (e) => {
        let setActiveButton = e.currentTarget.id;

        if (setActiveButton !== this.state.defaultActiveLink) {
            //active
            document.getElementById(setActiveButton).classList.add("active");
            //nonACtive
            document.getElementById(this.state.defaultActiveLink).classList.remove("active");

            this.setState({
                defaultActiveLink: setActiveButton,
            }, function () {
                this.props.setWindowData(this.state.defaultActiveLink);
            })
        }
    }

    logOut = () => {
        fetch(global.components.url + "/user/api/user/logout/?format=json")
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.logout === true) {
                        localStorage.id = undefined;
                        window.location = "/"
                    }
                }
            )
    }

    closeCategoryMenu = () => {
        document.getElementById("CategoryMenu").classList.add("closeCategoryMenu")
    }
    CloseProfileMenu = () => {
        document.getElementById("ProfileMenu").classList.add("closeCategoryMenu")
    }

    render() {
        if (this.props.profile !== undefined) {
            return (
                <div className='LeftCatalog closeCategoryMenu left' id='ProfileMenu'>
                    <div className='CategoryMenu'>
                        <div className='title'>
                            <Link to="/">
                                <div className='logo'></div>
                            </Link>
                            <button className='crossMenu' id='closeProfileMenu' onClick={this.CloseProfileMenu}>
                                <span></span></button>
                        </div>
                        <div className='info'>
                            <h2>Разделы кабинета пользователя</h2>

                            <ul>
                                <li onClick={this.setActiveLink} id="personalData" className=''>Личные данные</li>
                                <li onClick={this.setActiveLink} id="currentOrders" className=''>Текущие заказы</li>
                                <li onClick={this.setActiveLink} id="OrderHistory" className=''>История заказов</li>
                                <li><Link to="/basket/" className='link'>Корзина</Link></li>
                                <li onClick={this.setActiveLink} id="Subscription" className=''>Подключение рассылки
                                </li>
                            </ul>
                            <button className='logout' onClick={this.logOut}>Выйти</button>
                        </div>
                    </div>

                    <Baners/>
                </div>
            )
        } else {
            return (
                <div className='LeftCatalog closeCategoryMenu' id='CategoryMenu'>

                    <div className='CategoryMenu' id='CategoryMenu'>
                        <div className='title'>
                            <Link to="/">
                                <div className='logo'></div>
                            </Link>
                            <button className='crossMenu' id='closeCategoryMenu'
                                    onClick={this.closeCategoryMenu}><span></span></button>
                        </div>
                        {this.props.ParrentName === "discount" ?
                            <div className="info">
                                <p style={{marginBottom: "21px", cursor: "pointer"}} id="ALL"
                                   onClick={() => window.location = '/discounts/'}
                                   className={!this.props.disountNow ? "allProduct catalog_active" : "allProduct"}>Все</p>
                                <ul>
                                    <li className={this.props.disountNow === "season" ? "catalog_active" : ""}
                                        id="SEASON" onClick={() => window.location = '/discounts/season/'}>SEASON SALE
                                    </li>
                                    <li className={this.props.disountNow === "sale" ? "catalog_active" : ""} id="SALE"
                                        onClick={() => window.location = '/discounts/sale/'}>SALE
                                    </li>
                                </ul>
                            </div> :
                            <div className='info'>
                                <h2>Категории каталога</h2>
                                {
                                    this.props.category__in ?
                                        <p onClick={this.setCategory} className="allProduct">
                                            <p onClick={() => {
                                                window.location.href = `/catalog/`
                                            }}>Все товары</p>
                                        </p> :
                                        <p onClick={this.setCategory} className="catalog_active allProduct">
                                            <p>Все товары</p>
                                        </p>
                                }
                                <ul>
                                    {this.props.category.map((item, index) => (
                                        this.props.category__in === item.parent.slug ?
                                            <li  id={item.parent.slug}
                                                key={"LeftCatalog" + item.parent.slug + index}>
                                                <p className="catalog_active">{item.parent.name}</p>
                                                {item.sub.length > 0 ?
                                                    <ul className="sub-category__list">{item.sub.map((sub, sub_index) =>
                                                        (
                                                            <li
                                                                className="sub-category__block"
                                                                id={sub.slug}
                                                                key={"LeftCatalog" + sub.slug + sub_index}>
                                                                <p onClick={() => {
                                                                    window.location.href = `/catalog/${sub.slug}/`
                                                                }}>
                                                                    {sub.name}
                                                                </p>
                                                            </li>

                                                        ))}</ul> : <p></p>}
                                            </li>
                                            :
                                            <li className="" id={item.parent.slug}
                                                key={"LeftCatalog" + item.parent.slug + index}>
                                                <p onClick={() => {
                                                    window.location.href = `/catalog/${item.parent.slug}/`
                                                }}>
                                                    {item.parent.name}
                                                </p>
                                                {item.sub.length > 0 ?
                                                    <ul className="sub-category__list">{item.sub.map((sub, sub_index) =>
                                                        (

                                                            this.props.category__in === sub.slug ?
                                                                <li className="sub-category__block catalog_active"
                                                                    id={sub.slug}
                                                                    key={"LeftCatalog" + sub.slug + sub_index}>
                                                                    <p>{sub.name}</p>
                                                                </li> :
                                                                <li onClick={(e) => this.setCategory(e)}
                                                                    className="sub-category__block"
                                                                    id={sub.slug}
                                                                    key={"LeftCatalog" + sub.slug + sub_index}>
                                                                    <p onClick={() => {
                                                                        window.location.href = `/catalog/${sub.slug}/`
                                                                    }}>
                                                                        {sub.name}
                                                                    </p>
                                                                </li>

                                                        ))}</ul> : <p></p>}

                                            </li>
                                    ))}
                                </ul>
                            </div>
                        }
                    </div>

                    <Baners
                    />
                </div>
            )
        }
    }
}
