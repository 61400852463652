import React, { useState, useEffect } from 'react'

import "./css/FormAvtorization.css"

import { Link } from "react-router-dom";
import setFavouriteByProfile from "../../../Methods/setFavouriteByProfile";


import BelCountry from '../registration/img/bel.png'
import RuCountry from '../registration/img/ru.png'
import Password from "../../StaticForms/PasswordField/Password";

export default function FormAuthorization() {

    const [login, setlogin] = useState(null)
    const [pass, setpass] = useState(null)
    const [data, setdata] = useState(null)

    // const [telToNewPass, settelToNewPass] = useState(null)
    const [country, setcountry] = useState("+7")
    // const [newNumber, setnewNumber] = useState("")

    const [code, setcode] = useState(null)
    const [emailToSendCode, setemailToSendCode] = useState()


    const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    // const openShadowTelephoneCountry = () => {
    //     let statusShadow = document.getElementById("shadowTelephoneCountry").style.display;

    //     if (statusShadow === "none") {
    //         document.getElementById("shadowTelephoneCountry").style.display = "inline-block";
    //     } else {
    //         document.getElementById("shadowTelephoneCountry").style.display = "none";
    //     }
    // }
    // const openShadowNewTelephoneCountry = () => {
    //     let statusShadow = document.getElementById("shadowNewTelephoneCountry").style.display;

    //     if (statusShadow === "none") {
    //         document.getElementById("shadowNewTelephoneCountry").style.display = "inline-block";
    //     } else {
    //         document.getElementById("shadowNewTelephoneCountry").style.display = "none";
    //     }
    // }
    const takeCountry = (e) => {
        let id = e.currentTarget.id;

        if (id === "ruCountry") {
            setcountry("+7")

            document.getElementById("imageCountry").style.backgroundImage = `url(${RuCountry})`
            document.getElementById("codeCountry").textContent = "+7"
            document.getElementById("codeCountry").style.width = "20px"
            document.getElementById("telephonenumber").style.width = "202px"
        } else if (id === "ruNewCountry") {
            setcountry("+7")
            document.getElementById("imageNewCountry").style.backgroundImage = `url(${RuCountry})`
            document.getElementById("NewCodeCountry").textContent = "+7"
        } else if (id === "belCountry") {
            setcountry("+375")
            document.getElementById("imageCountry").style.backgroundImage = `url(${BelCountry})`
            document.getElementById("codeCountry").textContent = "+375"
            document.getElementById("codeCountry").style.width = "32px"
            document.getElementById("telephonenumber").style.width = "190px"
        } else if (id === "belNewCountry") {
            setcountry("+375")

            document.getElementById("imageNewCountry").style.backgroundImage = `url(${BelCountry})`
            document.getElementById("NewCodeCountry").textContent = "+375"
        }

        document.getElementById("shadowTelephoneCountry").style.display = "none";
        document.getElementById("shadowNewTelephoneCountry").style.display = "none";
    }
    const authorization = () => {

        let error = []

        if (document.getElementById("email").value === "") {
            error.push("email");
        }

        if (document.getElementById("pass").value === "") {
            error.push("pass");
        }
        if (error.length === 0) {
            setlogin(document.getElementById("email").value)
            setpass(document.getElementById("pass").value)

            fetch(global.components.url +'/user/api/user/authorization/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // "x-csrf-token": token,
                },
                body: JSON.stringify({
                    username: document.getElementById("email").value,
                    password: document.getElementById("pass").value,
                }),
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success === false) {
                            alert("Логин или пароль неверен")
                            return;
                        }

                        setdata(result)
                        localStorage.id = result.id
                        setFavouriteByProfile();
                        window.location = "/profile"


                    })
        } else {
            console.log(error)
            error.forEach((item) => {
                document.getElementById(item).classList.add("error");
                console.log(document.getElementById(item))
            })
        }

    }
    const openNewPassword = () => {
        document.getElementById("newPassword").style.display = "flex";
    }
    const closeNewPassword = () => {
        document.getElementById("newPassword").style.display = "none";
    }
    const sendUrlToNewPass = () => {

        if (document.getElementById("emailBlock").value === '') {
            document.getElementById("emailBlock").classList.add("error");
        } else {
            // setemailToSendCode(document.getElementById("emailBlock").value)

            // Получение кода
            fetch(global.components.url +'/user/api/user/lose_password/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: document.getElementById("emailBlock").value,
                    new_password: document.getElementById("setNewPassword").value,
                }),
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                    }
                )

            document.getElementById("newPassword").style.display = "none"
            document.getElementById("AproveCodeNewPassword").style.display = "flex"

        }
    }

    const closeAproveCodeNewPassword = () => {
        document.getElementById("AproveCodeNewPassword").style.display = "none"
    }

    const closeSendNewPassword = () => {
        document.getElementById("sendNewPassword").style.display = "none"
    }

    const sendAproveCodeNewPassword = () => {
        if (document.getElementById('aproveTelNumberCode').value === code) {
            document.getElementById("AproveCodeNewPassword").style.display = "none"
            document.getElementById("sendNewPassword").style.display = "flex"
        } else {
            document.getElementById('NewPassword').classList.add('error');
        }
    }

    const sendNewPass = () => {
        // console.log(document.getElementById('NewPassword'))

        let error = []

        if (document.getElementById("setNewPassword").value === "") {
            document.getElementById("setNewPassword").style.borderColor = "#DB2700"
            error.push('Пустой пароль')
        } else if (document.getElementById("setNewPassword").value.length <= 8) {
            document.getElementById("seNewPassword").style.borderColor = "#DB2700"
            error.push('Символов меньше 8-ми')
        }

        if (error.length === 0) {
            fetch(global.components.url +'/user/api/user/lose_password/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: emailToSendCode,
                    new_password: document.getElementById("setNewPassword").value,
                }),
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        if (result.success === true) {
                            alert("вы успешно сменили пароль")
                        }
                    }
                )
        } else {
            alert(error)
        }

    }

    return (
        <div className='content'>

            <div className='newPassword' id='newPassword' style={{ display: "none" }}>
                <div className='information'>
                    <div className='title'>
                        <h3>Восстановление пароля</h3>
                        <div className='cross' onClick={closeNewPassword}></div>
                    </div>
                    <p>После заполнения формы мы отправим специальную ссылку на указанный email.</p>
                    <div className='left'>
                        {/* <p>Телефон<span>*</span></p> */}
                        <p>Email<span>*</span></p>
                        <p>Пароль<span>*</span></p>
                    </div>
                    <div className='right'>
                        {/* <div className="field telephone">
                                <span className='telephoneInput' id='telephoneInputBlock'>
                                    <button id='countryNew' onClick={openShadowNewTelephoneCountry}>
                                        <div className='img' id='imageNewCountry'></div>
                                        <div className='arrDown'></div>
                                    </button>
                                    <div id='shadowNewTelephoneCountry' style={{ display: "none" }}>
                                        <button id='ruNewCountry' onClick={takeCountry}>
                                            <div className='img'></div>
                                        </button>
                                        <button id='belNewCountry' onClick={takeCountry}>
                                            <div className='img'></div>
                                        </button>
                                    </div>
                                    <span id='NewCodeCountry'>+7</span>
                                    <input
                                        id='Newtelephonenumber'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            } else {
                                                document.getElementById("telephoneInputBlock").classList.remove("error");
                                            }
                                        }
                                        }
                                        maxLength={10}
                                    ></input>
                                </span>
                            </div> */}
                        <div className="field email">
                            <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id='emailBlock'
                                onKeyPress={(event) => {
                                    document.getElementById("email").classList.remove("error");
                                }}
                            ></input>
                        </div>
                        <div className="field email">
                            <input id='setNewPassword'
                                onKeyPress={(event) => {
                                    document.getElementById("email").classList.remove("error");
                                }}
                            ></input>
                        </div>
                        <button onClick={sendUrlToNewPass}>Продолжить</button>
                    </div>
                    <div className='clearfix'></div>
                </div>
            </div>

            <div className='DarkPopUp' id='AproveCodeNewPassword' style={{ display: "none" }}>
                <div className='information'>
                    <div className='title'>
                        <h3>Восстановление пароля</h3>
                        <div className='cross' onClick={closeAproveCodeNewPassword}></div>
                    </div>
                    <div className='number'>
                        <p>На <span>{emailToSendCode}</span> выслана ссылка для подтверждения.</p>
                    </div>
                    <p>Подтверждение почты</p>

                    <div className='clearfix'></div>
                </div>
            </div>


            <form className='FormLogin' onSubmit={handleFormSubmit}>

                <div className="field email">
                    <p>Email<span>*</span></p>
                    <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id='email'></input>
                </div>

                <div className="field pass">
                    <p>Пароль<span>*</span></p>
                    <Password id='pass' onKeyPress={() => {
                        document.getElementById("pass").classList.remove("error");
                    }}/>
                    {/*<input id='pass' type="password"/>*/}

                </div>

                <div className='sub'>
                    <button type='submit' className='buttonLog' onClick={authorization}>Войти</button>
                    <button className='newPass' onClick={openNewPassword}>Забыли пароль?</button>
                </div>
            </form>

            <div className='goReg'>
                <p>Нет аккаунта?</p>
                <Link to="/registration">Зарегистрироваться</Link>
            </div>

        </div>
    )
}
