import React, {useState} from 'react'
import {useParams} from "react-router-dom";

import SaleLine from '../Components/Pages/index/SaleLine';
import ProductCard from '../Components/Pages/ProductInformation/ProductCard';
import Recomendation from '../Components/Pages/ProductInformation/Recomendation';
import BasketStorage from "../Methods/localStorage/BasketStorage";
import Favourite from "../Methods/localStorage/Favourite";

export default function ProductInformation(props) {
    const {idProduct} = useParams();
    const [basket, setBasket] = useState(new BasketStorage());
    const [favourite, setFavourite] = useState(new Favourite());

    return (
        <div className='ProductInformation' id='body'>

            <ProductCard
                idProduct={idProduct}
                basket={basket}
                favourite={favourite}
            />
            <SaleLine/>
            <Recomendation
                basket={basket}
                favourite={favourite}
            />
        </div>
    )
}
