import React, {useEffect, useState} from 'react'
import "./css/TypeListCatalog.css"
import ProductsOutputType from './typeListProducts/ProductsOutputType'

import PopUpGalery from '../../index/Slider/PopUpGalery';
import '../../index/Slider/css/PopUpGalery.css';
import Pagination from "../../../StaticForms/Pagination/Pagination";

import BasketStorage from "../../../../Methods/localStorage/BasketStorage";
import Favourite from "../../../../Methods/localStorage/Favourite";

const TypeListCatalog = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [ProductPopUp, setProductPopUp] = useState(props.products[0] || {});
    const [quantityToPup, setQuantityToPup] = useState(0);
    const [priceToPup, setPriceToPup] = useState(0);
    const [basket] = useState(new BasketStorage())
    const [favourite] = useState(new Favourite())

    const tileSort = () => {
        document.getElementById(this.state.lastButton).classList.remove("Active")
        document.getElementById("tile").classList.add("Active")
        // setLastButton("tile")
    }
    const tilelist = () => {
        document.getElementById(this.state.lastButton).classList.remove("Active")
        document.getElementById("list").classList.add("Active")
        // setLastButton("list")
    }
    const tiletable = () => {
        document.getElementById(this.state.lastButton).classList.remove("Active")
        document.getElementById("table").classList.add("Active")
        // setLastButton("table")

    }

    const updateData = (ProductInfo, quantity, price) => {
        setProductPopUp(ProductInfo);
        setQuantityToPup(quantity);
        setPriceToPup(price);
    }

    useEffect(() => {

    }, [setProductPopUp, setQuantityToPup, setPriceToPup, ProductPopUp, quantityToPup, priceToPup])


    let nothingDiskonts;
    if (props.products.length === 0) {
        if (props.ParrentName === 'SearchCatalogData') {
            nothingDiskonts =
                <div className='ProductsTile'>
                    <p id='nothingProducts'>
                        Товаров удовлетворяющие запросу, нет
                    </p>
                </div>
        } else {
            nothingDiskonts =
                <div className='ProductsTile'>
                    <p id='nothingProducts'>
                        Пока скидок нет...
                    </p>
                </div>
        }
    }

    if (!props|| !props.products.length ) {
        return (
            <div className='infs ProductsTile'>
                <p id='nothingProducts'>
                    Товаров удовлетворяющие запросу, нет
                </p>
            </div>
        )
    } else {
        return (
            <div className='infs '>
                {/*<div className='TypeListCatalog'>
            <button onClick={this.tileSort} id='tile' className='Active'></button>
            <button style={{opacity: 0}} onClick={this.tilelist} id='list'></button>
            <button style={{opacity: 0}} onClick={this.tiletable} id='table'></button>
          </div>*/}

                {nothingDiskonts}

                <ProductsOutputType
                    products={props.products}

                    updateData={updateData}
                    outputType={"tile"}

                    discont={props.discont}
                    page={props.page}
                    basket={basket}
                    favourite={favourite}
                />


                <PopUpGalery
                    namePopUp="PopUpOnCategory"
                    information={ProductPopUp}
                    quantity={quantityToPup}
                    price={priceToPup}
                    basket={basket}
                    favourite={favourite}

                />
                <div className='clearfix'></div>
                <Pagination
                    page={props.page}
                />
                <div className='clearfix'></div>
            </div>
        )
    }
}


export default TypeListCatalog;