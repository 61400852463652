import React, { useState, useCallback } from 'react'
import LeftCatalog from '../catalog/catalogContent/LeftCatalog'
import InformationProfile from './InformationProfile/InformationProfile'

import "./css/ProfileContent.css"

export default function ProfileContent(props) {
    const [link, setLink] = useState("personalData");

    const updateLink = useCallback((activeLink) => {
        setLink(activeLink);
    }, []);

    const OpenMenu = () => {
        document.getElementById("ProfileMenu").classList.remove("closeCategoryMenu");
    }
    return (
        <div className='ProfileContent'>
            <button id='OpenMenu' onClick={OpenMenu}>Выбрать раздел</button>

            <LeftCatalog
                profile="profileContent"
                setWindowData={updateLink}
                link={link}
            />
            <InformationProfile
                profileData={props.profileData}
                link={link}
            />
            <div className='clearfix'></div>
        </div>
    )
}
