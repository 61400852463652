import React, { useEffect, useState } from "react";
import "./PostAddressStyle.css";
export default function Delivery(props) {
  const [lastActiveDelivery, setLastActiveDelivery] = useState();
  const [country, setCountry] = useState("Russia");
  // const [cityCdekValue, setCityCdekValue] = useState('');
  // const [typeCdekValue, setTypeCdekValue] = useState('');
  // const [tariffCdekValue, setTariffCdekValue] = useState({}); 


  useEffect(() => {
    if (lastActiveDelivery === undefined) {
      if (document.getElementById("ChecboxrPickup") !== null) {
        document.getElementById("ChecboxrPickup").checked = true;
      }
    }

   
  

  },[lastActiveDelivery]);


  const selectDelivery = (e) => {
    
    let id = "SelectPickUpPoint" + e.currentTarget.id,
      idCheckBox = "Checbox" + e.currentTarget.id;

    if (document.getElementById(id) !== null) {
      document.getElementById(id).style.display = "inline-block";
      if (id === "SelectPickUpPointrRussianPost") {
        document.getElementById("PostAddress").style.display = "flex";
        document.getElementById("СourierAddress").style.display = "none";
      } else if (id === "SelectPickUpPointrСourierPiter") {
        document.getElementById("СourierAddress").style.display = "flex";
        document.getElementById("PostAddress").style.display = "none";
      } else {
        document.getElementById("СourierAddress").style.display = "none";
        document.getElementById("PostAddress").style.display = "none";
      }

      if (id !== lastActiveDelivery) {
        if (document.getElementById(lastActiveDelivery) !== null) {
          document.getElementById(lastActiveDelivery).style.display = "none";
        } else {
          document.getElementById("ChecboxrPickup").checked = false;
        }
        document.getElementById(idCheckBox).checked = true;
        // document.getElementById("AdressInput").disabled = false;
        document.getElementById("AdressInput").value = "Выберете пункт выдачи";
      }
    } else {
      document.getElementById(lastActiveDelivery).style.display = "none";
      document.getElementById("ChecboxrPickup").checked = true;
      document.getElementById("СourierAddress").style.display = "none";
      document.getElementById("PostAddress").style.display = "none";
      // document.getElementById("AdressInput").disabled = true;
    }

    setLastActiveDelivery(id);
  };






  const openSdecPVZ = () => {
    var cdekMap = document.getElementById('cdek-map');
    cdekMap.style.width = '800px';
    cdekMap.style.height = '600px';
    window.widget.open();

  };

  function getSDEKPVZ() {


    console.log('hello world')
     let cityCdekValue = document.querySelector('input[name="city-cdek"]').value;
     let typeCdekValue = document.querySelector('input[name="type-cdek"]').value;
     let tariffCdekValue = document.querySelector('input[name="tariff-cdek"]').value;

    if (typeCdekValue == 'office' || typeCdekValue == 'door'){

      if (tariffCdekValue){
        tariffCdekValue = JSON.parse(tariffCdekValue);
      }

      console.log(cityCdekValue);
      console.log(typeCdekValue);
      console.log(tariffCdekValue);


       props.setDeliveryCost(parseInt(tariffCdekValue.delivery_sum));
       props.setTerm(tariffCdekValue.period_max);
       document.getElementById("AdressInput").value = cityCdekValue;
       props.setAddress(cityCdekValue);

      if (typeCdekValue === 'door') {
          props.setDelivery("CDEK-DELIVERY");
      } else {
          props.setDelivery("CDEK-PICKUP");
      }
    }else{
      alert('Выберите пункт доставки!')
    }
    
  
  }


  function getPostAddress(e) {
    e.preventDefault();
    let input = document.getElementsByClassName("PostAddress__input");
    let address =
      input[0].value +
      ", Область " +
      input[1].value +
      ", Город " +
      input[2].value +
      ", " +
      input[3].value +
      " " +
      input[4].value +
      " к. " +
      input[5].value +
      "кв. " +
      input[6].value;
    document.getElementById("AdressInput").value = address;
    props.setDelivery("POCHTA");
    props.setDeliveryCost(350);
    props.setTerm(15);
    props.setAddress(address);
  }

  function getCourierAddress(e) {
    e.preventDefault();
    let input = document.getElementsByClassName("CourierAddress__input");
    let address =
      input[0].value +
      ", Область " +
      input[1].value +
      ", Город " +
      input[2].value +
      ", " +
      input[3].value +
      " " +
      input[4].value +
      " к. " +
      input[5].value +
      "кв. " +
      input[6].value;
    document.getElementById("AdressInput").value = address;
    props.setDelivery("COURIER-PITER");
    props.setDeliveryCost(500);
    props.setTerm(2);
    props.setAddress(address);
  }

  const setPickUp = () => {
    props.setAddress("197375, г Санкт - Петербург, Суздальское шоссе 28 к 2");
    props.setDelivery("PICKUP");
    document.getElementById("AdressInput").value =
      "197375, г Санкт - Петербург, Суздальское шоссе 28 к 2";
    props.setDeliveryCost(0);
  };

  const openBoxberryPVZ_function = (result) => {
    document.getElementById("AdressInput").value = result.address;
    props.setDeliveryCost(parseInt(result.price));
    props.setTerm(result.period);
    props.setAddress(result.address);
    props.setDelivery("BOXBERRY-PICKUP");
  };




  return (
    <div className="Delivery MakingAnOrder">
      <h3>Способ доставки</h3>
      <div className="selects">
        <div className="country">
          <p>
            Страна:<span className="red">*</span>
          </p>
          <input disabled id="DeliveryCountry" defaultValue="Россия"></input>
        </div>
      </div>
      <div className="DeliveryMethod">
        <div
          onClick={(e) => {
            selectDelivery(e);
            setPickUp();
          }}
          id="rPickup"
          className="inputs"
        >
          <input
            type="radio"
            name="DeliveryMethod"
            value="Pickup"
            className="Pickup"
            id="ChecboxrPickup"
          ></input>
          <label htmlFor="Pickup">
            <div className="left">
              <div className="title">
                <h4>Самовывоз</h4>
                <p>
                  Самовывоз доступен только из Санкт-Петербурга. Вы можете
                  самостоятельно забрать заказ со склада.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="costTime">
                <p>Стоимость: бесплатно</p>
              </div>
            </div>
            <div className="clearfix"></div>
          </label>
        </div>

        <div onClick={selectDelivery} id="rSdekPVZ" className="inputs">
          <input
            type="radio"
            name="DeliveryMethod"
            value="SdekPVZ"
            className="SdekPVZ"
            id="ChecboxrSdekPVZ"
          ></input>
          <label htmlFor="SdekPVZ">
            <div className="left">
              <div className="title">
                <h4>СДЭК (СДЭК ПВЗ)</h4>
                <p>Подождите пока появится кнопка "Выбрать ПВЗ"</p>
                <p>
                  Сроки доставки приблизительные и не окончательные,
                  предоставлены сайтом сдэк.
                </p>
              </div>
              <div
                id="SelectPickUpPointrSdekPVZ"
                className="buttonSelectPicUpPoint"
                style={{ display: "none" }}
              >
                <button onClick={() =>openSdecPVZ()} className="SelectPickUpPoint">Выбрать пункт выдачи</button>
                <button onClick={() => getSDEKPVZ()} className="SelectPickUpPoint">Занести данные</button>
                <input
                  id="getSDEKPVZ"
                  hidden
                  type="text"
                  value="0"
                  onClick={getSDEKPVZ}
                />
                <p>Выбранный ПВЗ указан ниже, в адресе доставки</p>
              </div>
            </div>
            <div className="right">
              <div className="costTime time-price-cdek">
                <p>Стоимость: ~400 руб.</p>
                <p>Срок доставки: ~5 дней</p>
              </div>
            </div>
            <div className="clearfix"></div>
          </label>
        </div>

        {/*<div onClick={selectDelivery} id='boxberryPVZ' className='inputs'>
          <input type="radio" name='DeliveryMethod' value="boxberryPVZ" className="boxberryPVZ" id='ChecboxboxberryPVZ'></input>
          <label htmlFor="SdekDeliveryByCourier">
            <div className='left'>
              <div className='title'>
                <h4>Boxberry (Пункт самовывоза)</h4>
                <p>Подождите пока появится кнопка "Выбрать ПВЗ"
                  Сроки доставки приблизительные и не окончательные, предоставлены сайтом сдэк.</p>
              </div>
              <div id='SelectPickUpPointboxberryPVZ' className='buttonSelectPicUpPoint' style={{ display: "none" }}>
                <button onClick={() =>
                    window.boxberry.open(openBoxberryPVZ_function, "1$yHLFBthAluQl_RQFbgrzzNAV6JKZg_ub",
                        "Санкт-Петербург", "", 100, 50,
                        "")}
                className='SelectPickUpPoint'>Выбрать пункт выдачи</button>
                <p>Выбранный ПВЗ указан ниже, в адресе доставки</p>
              </div>
            </div>
            <div className='right'>
              <div className='costTime'>
                <p>Стоимость: ~400 руб.</p>
                <p>Срок доставки: ~1 день</p>
              </div>
            </div>
            <div className='clearfix'></div>
          </label>
        </div>
*/}

        <div onClick={selectDelivery} id="rRussianPost" className="inputs">
          <input
            type="radio"
            name="DeliveryMethod"
            value="RussianPost"
            className="RussianPost"
            id="ChecboxrRussianPost"
          ></input>
          <label htmlFor="RussianPost">
            <div className="left">
              <div className="title">
                <h4>Почта России (Получение в почтовом отделении)</h4>
                <p>Подождите пока появятся поля для заполнения адреса</p>
                <p>
                  Сроки доставки приблизительные и не окончательные,
                  предоставлены сайтом почты России.
                </p>
              </div>
              <div
                style={{ width: 0 }}
                id="SelectPickUpPointrRussianPost"
                className="buttonSelectPicUpPoint"
              >
                {/*  <button className='SelectPickUpPoint'>Выбрать пункт выдачи</button>
                <p>Выбранный ПВЗ указан ниже, в адресе доставки</p>*/}
              </div>
            </div>
            <div className="right">
              <div className="costTime">
                <p>Стоимость: =350руб.</p>
                <p style={{ whiteSpace: "nowrap" }}>Срок доставки: ~15 дней</p>
              </div>
            </div>
            <div className="clearfix"></div>
            <form
              onSubmit={(e) => {
                getPostAddress(e);
                return false;
              }}
            >
              <ul
                style={{ display: "none" }}
                id="PostAddress"
                className="PostAddress"
              >
                <li className="PostAddress__container">
                  <p>Почтовый индекс</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="PostAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Область</p>
                  <input
                    required
                    id="post_area"
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="PostAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Город</p>
                  <input
                    required
                    id="post_city"
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="PostAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Улица</p>
                  <input
                    required
                    id="post_area"
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="PostAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Дом</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="PostAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Корпус</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="PostAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Квартира</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="PostAddress__input"
                    type="text"
                  />
                </li>
                <li>
                  <button
                    type="submit"
                    className="PostAddress__container PostAddress__button"
                  >
                    Занести данные
                  </button>
                </li>
              </ul>
            </form>
          </label>
        </div>

        <div onClick={selectDelivery} id="rСourierPiter" className="inputs">
          <input
            type="radio"
            name="DeliveryMethod"
            value="СourierPiter"
            className="RussianPost"
            id="ChecboxrСourierPiter"
          ></input>
          <label htmlFor="СourierPiter">
            <div className="left">
              <div className="title">
                <h4>Курьер по Санкт-Петербургу(Получение дома)</h4>
                <p>Подождите пока появятся поля для заполнения адреса</p>
                <p>Сроки доставки приблизительные и не окончательные</p>
              </div>
              <div
                style={{ width: 0 }}
                id="SelectPickUpPointrСourierPiter"
                className="buttonSelectPicUpPoint"
              >
                {/*  <button className='SelectPickUpPoint'>Выбрать пункт выдачи</button>
                <p>Выбранный ПВЗ указан ниже, в адресе доставки</p>*/}
              </div>
            </div>
            <div className="right">
              <div className="costTime">
                <p>Стоимость: =500руб.</p>
                <p style={{ whiteSpace: "nowrap" }}>Срок доставки: ~2 дня</p>
              </div>
            </div>
            <div className="clearfix"></div>
            <form
              onSubmit={(e) => {
                getCourierAddress(e);
                return false;
              }}
            >
              <ul
                style={{ display: "none" }}
                id="СourierAddress"
                className="PostAddress"
              >
                <li className="PostAddress__container">
                  <p>Почтовый индекс</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="CourierAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Область</p>
                  <input
                    required
                    disabled
                    id="post_area"
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    value="Ленинградская"
                    className="CourierAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Город</p>
                  <input
                    required
                    disabled
                    id="post_city"
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    value="Санкт-Петербург"
                    className="CourierAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Улица</p>
                  <input
                    required
                    id="post_area"
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="CourierAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Дом</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="CourierAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Корпус</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="CourierAddress__input"
                    type="text"
                  />
                </li>
                <li className="PostAddress__container">
                  <p>Квартира</p>
                  <input
                    required
                    style={{ width: "150px", height: "25px", margin: 0 }}
                    className="CourierAddress__input"
                    type="text"
                  />
                </li>
                <li>
                  <button
                    type="submit"
                    className="PostAddress__container PostAddress__button"
                  >
                    Занести данные
                  </button>
                </li>
              </ul>
            </form>
          </label>
        </div>
      </div>
    </div>
  );
}
