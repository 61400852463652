import React, {Component, useState} from 'react'
import Title from '../registration/Title'
import './css/way.css'
import {Link} from "react-router-dom";

const Way = (props) => {
    const [wayDict] = useState({
        "Mary Furnitura": "/",
        "Каталог": "/catalog",
        "Авторизация": "/authorization",
        "Регистрация": "/registration",
        "О нас": "/about_us",
        "Корзина": "/basket",
        "Контакты": "/contacts",
        "Распродажа": "/discounts",
        "Доставка и оплата": "/garantdeliveryandpayment",
        "Оформление заказа": "",
        "Мастер-классы и курсы": "/master_classes_and_courses",
        "Кабинет пользователя": "/profile",
    })

    let line;
    if (props.noBorder === undefined) {
        line = <div className='line'></div>
    }
    let title = "Mary Furnitura";
    if(props.title){
        title = props.title
    }
    return (
        <div className='content'>
            <div className='way'>
                <div className='Title'>
                    <h1>
                        {title}
                    </h1>
                </div>
                <div className='wayTo'>
                    {props.way.map((item, index) => (
                        wayDict[item] ?
                            <p><Link to={wayDict[item]}>{item}</Link></p>
                            :
                            <p>{item}</p>
                    ))}
                </div>
                {line}
            </div>
        </div>

    )
}

export default Way;