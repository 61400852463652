import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./css/SliderJq.css";
import ProductTile from "../../globalComponents/ProductOutputType/ProductTile";
import PopUpGalery from "./PopUpGalery";
import basketStorage from "../../../../Methods/localStorage/BasketStorage";
import Favourite from "../../../../Methods/localStorage/Favourite";

export default function SimpleSlider(props) {
  const [slidesToShow, setslidesToShow] = useState();
  const [arrows, setarrows] = useState(true);
  const [width, setwidth] = useState(350);
  const [ProductPopUp, setProductPopUp] = useState(props.items[0] || {});
  const [quantityToPup, setquantityToPup] = useState([]);
  const [priceToPup, setpriceToPup] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [basket] = useState(props.basket || new basketStorage());
  const [favourite] = useState(props.favourite || new Favourite());

  useEffect(() => {
    if (slidesToShow === undefined || arrows === undefined) {
      if (window.innerWidth < 1280) {
        if (window.innerWidth < 1001) {
          setslidesToShow(1);
          setwidth(310);
        } else {
          setslidesToShow(1);
          setwidth(260);
        }
      } else {
        setslidesToShow(1);
      }

      if (window.innerWidth < 1001) {
        setarrows(false);
      } else {
        setarrows(true);
      }
    }

    if (slidesToShow > props.items.length) {
      if (props.items.length - 1 !== 1) {
        setslidesToShow(props.items.length - 1);
      } else {
        setslidesToShow(props.items.length);
      }
    }

    setProductPopUp(props.items[0]);
    setIsLoad(true);
  }, [setProductPopUp]);

  window.onresize = function (event) {
    // console.log(window.innerWidth)
    if (window.innerWidth < 1280) {
      if (window.innerWidth < 1001) {
        setslidesToShow(2);
        setwidth(190);
      } else {
        setslidesToShow(2);
        setwidth(190);
      }
    } else {
      setslidesToShow(3);
    }

    if (window.innerWidth < 1001) {
      setarrows(false);
    } else {
      setarrows(true);
    }
  };

  const settings = {
    className: "slider center variable-width",
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    centerMode: true,
    // autoplay: true,
    autoplaySpeed: 2000,
    arrows: arrows,
    variableWidth: true,
  };

  const updateData = (ProductInfo, quantity, price) => {
    setProductPopUp(ProductInfo);
    setquantityToPup(quantity);
    setpriceToPup(price);
  };

  if (!isLoad) {
    return <div>Загрузка</div>;
  } else {
    return (
      <>
        <div className="SliderJq">
          <PopUpGalery
            namePopUp="PopUpOnCategory"
            information={ProductPopUp}
            quantity={quantityToPup}
            price={priceToPup}
            basket={basket}
            favourite={favourite}
          />

          <Slider {...settings}>
            {props.items.slice(0,15).map((item, index) => (
              <div style={{ width: width }}>
                {/**/}
                <ProductTile
                  width="100%"
                  key={"ProductTile" + index}
                  id={item.article}
                  name={item.name}
                  price={item.price}
                  ProductInfo={item}
                  basket={basket}
                  favourite={favourite}
                  updateData={updateData}
                  discont={props.discont}
                  idPersone={props.idPersone}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="SliderJq">
          <PopUpGalery
            namePopUp="PopUpOnCategory"
            information={ProductPopUp}
            quantity={quantityToPup}
            price={priceToPup}
            basket={basket}
            favourite={favourite}
          />

          <Slider {...settings}>
            {props.items.slice(15,30).map((item, index) => (
              <div style={{ width: width }}>
                {/**/}
                <ProductTile
                  width="100%"
                  key={"ProductTile" + index}
                  id={item.article}
                  name={item.name}
                  price={item.price}
                  ProductInfo={item}
                  basket={basket}
                  favourite={favourite}
                  updateData={updateData}
                  discont={props.discont}
                  idPersone={props.idPersone}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="SliderJq">
          <PopUpGalery
            namePopUp="PopUpOnCategory"
            information={ProductPopUp}
            quantity={quantityToPup}
            price={priceToPup}
            basket={basket}
            favourite={favourite}
          />

          <Slider {...settings}>
            {props.items.slice(35,45).map((item, index) => (
              <div style={{ width: width }}>
                {/**/}
                <ProductTile
                  width="100%"
                  key={"ProductTile" + index}
                  id={item.article}
                  name={item.name}
                  price={item.price}
                  ProductInfo={item}
                  basket={basket}
                  favourite={favourite}
                  updateData={updateData}
                  discont={props.discont}
                  idPersone={props.idPersone}
                />
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}
