import React, { Component } from 'react'
import { Link } from "react-router-dom";

import './css/DeliveryAndPaymentInformation.css'

import Baners from '../../globalComponents/Baners'
import Way from '../../globalComponents/Way'

export default class DeliveryAndPaymentInformation extends Component {
    state = {
        title: "Доставка и оплата",
        way: ["Mary Furnitura", "Доставка и оплата"]
    }
    render() {
        return (
            <div className='DeliveryAndPaymentInformation'>
                <Way
                    title={this.state.title}
                    way={this.state.way}
                />
                <div className='content'>
                    <div className='left'>
                        <Baners />
                    </div>
                    <div className='right'>

                        <div className='DeliveryAndPayment'>
                            <h2>Доставка и оплата</h2>
                            <div className='Payment'>
                                <p><span className='bold'>Оплата: </span>online сразу или в течение 24 часов, оплатить можно картой любого банка.</p>
                                <p>Заказы без оплаты резервируются только на 24 часа, статус заказа можно проверить в личном кабинете на сайте.</p>
                                <p>Мы вышлем электронный чек вам на почту, это гарантия того что товар будет доставлен.</p>
                            </div>
                            <div className='DeliveryServices'>
                                <p><span className='bold'>Службы доставки: </span>самовывоз, СДЭК (получение в пункте выдачи или доставка курьером), Почта России (получение в почтовом отделении).</p>
                            </div>
                            <div className='Pickup'>
                                <p className='bold'>Самовывоз доступен только из Санкт-Петербурга.</p>
                                <p>Вы можете самостоятельно забрать заказ со склада по адресу {global.components.address} в рабочие часы, указанные в уведомлении о готовности заказа.</p>
                            </div>
                        </div>

                        <div className='HowToBuy'>
                            <h2>Как купить фурнитуру для украшений?</h2>
                            <p>Изучите каталог нашего интернет-магазина, чтобы найти необходимую фурнитуру и добавляйте понравившиеся товары в корзину.</p>
                            <p>Оформляйте заказ и ожидайте звонка — наш консультант свяжется с вами, чтобы уточнить подробности заказа и как можно скорее отправить покупки по вашему адресу.</p>
                            <p>Мы доставляем заказы по всей России крупнейшими транспортными компаниями.</p>
                            <p>Если не получается найти нужную фурнитуру для украшений, пишите в соцсети или звоните, подберем для вас товары из наличия или закажем у производителя.  </p>
                        </div>
                        <div className='WarrantyAndRefund'>
                            <h2>Гарантия и возврат</h2>
                            <p>Вы можете запросить у нас <span className='bold'>возврат или обмен</span> приобретенного товара надлежащего качества в течение 14 календарных дней с момента получения товара в случае, если вас не устраивает внешний вид товара или не подошел размер.</p>
                            <p>Для этого свяжитесь с нами любым удобным для вас способом:</p>
                            <div className='soc'>
                                <div className='lefti'>
                                    <p className='tel'><a href={"tel:" + global.components.phone[0]}>{global.components.phone[1]}</a></p>
                                    <p className='mail'><a href={"mailto:" + global.components.gmail[0]}>{global.components.gmail[1]}</a></p>
                                </div>
                                <div className='righti'>
                                    <a target="_blank" href={global.components.whatsapp[0]} className='whatsapp'></a>
                                    <a target="_blank" href={global.components.tg[0]} className='telegram'></a>
                                    <a target="_blank" href={global.components.vk[0]} className='vk'></a>
                                </div>

                            </div>
                            <div className='clearfix'></div>
                            <p>Возврат или обмен товара надлежащего качества возможны только в случае, если товар не находился в эксплуатации клиентом, сохранены его товарный вид и упаковка изделия.</p>
                            <p>После получения нами изделия в товарном виде и полной комплектации в течение 10 рабочих дней мы отправим вам сумму покупки обратно на ваш банковский счет или вышлем замену.</p>
                        </div>

                    </div>
                    <div className='clearfix'></div>
                </div>
            </div>
        )
    }
}
