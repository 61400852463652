import '../Components/StaticComponents/css/App.css';

import ScrollUp from '../Components/StaticComponents/ScrollUp';

import Banner from '../Components/Pages/index/Banner';
import GaleryProducts from '../Components/Pages/index/GaleryProducts';
import SaleLine from '../Components/Pages/index/SaleLine';
import InstagramExamples from '../Components/Pages/index/InstagramExamples';
import Mailing from '../Components/Pages/index/Mailing';
//import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';

import React, {useEffect, useState} from "react";
import Text from "../Components/StaticForms/Text/Text";

export const Home = () => {
    const [text, setText] = useState();
    const [meta, setMeta] = useState();
    const [h1, setH1] = useState()

    useEffect(() => {
        fetch(global.components.url + "/product/api/page/main/?format=json")
            .then(res => res.json())
            .then((result) => {
                setH1(result["h1"])
                setText(result["text"]);
                setMeta(<Helmet>
                    <title>{result["title"]}</title>
                    <meta name="description" content={result['description']}/>
                </Helmet>)
            })
    }, [])

    return (
        <div className="home" id='body'>
            {meta}
            <ScrollUp/>
            <Banner h1={h1}/>
            <GaleryProducts
                title="Популярные товары каталога"
                p="Eсли вы создаете ювелирную бижутерию на заказ или для себя, занимаетесь бизнесом или имеете увлекательное хобби, связанное с украшениями, в нашем интернет-магазине вы найдете всё, что вам необходимо"
            />
            <SaleLine/>
            <InstagramExamples/>
            <Mailing/>
            {
                text ? <Text text={text} /> : <div></div>
            }
        </div>
    )
}

export default Home;