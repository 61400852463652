import React, { useState, useEffect } from "react";
import ProductTable from "../../../globalComponents/ProductOutputType/ProductTable";
import ReactDOM from "react-dom";

import "./css/OrderProductComponent.css";
import "./css/pay.css";
import Pay from "./Pay";

export default function OrderProductComponent(props) {
  // props.productInfo
  // Инфа продуктов
  const [ProductS, setProductS] = useState();
  // Ограничение вывода продуктов
  const [MaxValOutput, setMaxValOutput] = useState(4);
  // Information Order
  const [NumberOfOrder, setNumberOfOrder] = useState();
  const [Date, setDate] = useState();
  const [Status, setStatus] = useState("ожидается отправка в службу доставки");
  const [QuantityProducts, setQuantityProducts] = useState();
  // right Information
  const [DeliveryMethod, setDeliveryMethod] = useState();
  const [Adress, setAdress] = useState();
  const [PaymentMethod, setPaymentMethod] = useState();
  // CostShortInfo
  const [Cost, setCost] = useState(0);
  const [deliveryCost, setdeliveryCost] = useState();
  // PersonInfo
  const [Name, setName] = useState();
  const [Telephone, setTelephone] = useState();
  const [Email, setEmail] = useState();
  const [apiLoad, setAPILoad] = useState(false);
  const [promo, setPromo] = useState(null);
  const [trackNumber, setTrackNumber] = useState(null);
  const [linkDol, setLinkDol] = useState("");

  useEffect(() => {
    if (
      props.OrderProductInfo !== undefined &&
      props.OrderProductInfo !== null
    ) {
      if (props.OrderProductInfo !== ProductS) {
        if (NumberOfOrder === undefined) {
          let product = [props.OrderProductInfo];
          product.forEach((item) => {
            setNumberOfOrder(item.id);
            setDate(item.date);

            setStatus(item.delivery.status);

            setQuantityProducts(item.basket.positions.length);
            setDeliveryMethod(item.delivery.delivery);
            setAdress(item.address);
            setTrackNumber(item.delivery.track_number);
            if (item.payments === "CARD") {
              setPaymentMethod("Оплата картой онлайн");
            } else {
              setPaymentMethod(item.payments);
            }
            setdeliveryCost(() => item.delivery.price);
            setName(
              item.personality.surname +
                " " +
                item.personality.name +
                " " +
                item.personality.middlename
            );
            setTelephone(item.personality.region + item.personality.phone);
            setEmail(item.personality.email);

            let cost,
              quantity,
              fullCost = 0,
              product = [];
            item.basket.positions.forEach((item) => {
              let infor = { info: item.product, quantity: item.quantity };
              product.push(infor);
              quantity = item.quantity;
              if (item.product.discount === null) {
                cost = item.product.price;
              } else {
                cost = item.product.discount_price.new_price;
              }
              fullCost = fullCost + quantity * cost;
            });
            setProductS(product);
            setCost(item.total_price);
            setPromo(item.promo);
          });
        }
      }
    }

    fetch(global.components.url + "/order/api/dolyame/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "x-csrf-token": token,
      },
      body: JSON.stringify({
        pk: NumberOfOrder
      })
    }).then((res) => res.json())
    .then((result) => {
      console.log(result)
      if (result.status == 'ok'){
          setLinkDol(result.link)
      }
    })
  });

  const OpenFullProducts = () => {
    setMaxValOutput(QuantityProducts);
  };

  const CloseOrder = () => {
    props.SendCloseOrder(NumberOfOrder, "CANCELLED");
    props.updateProductsOrders(props.pay);
    document.location.reload();
  };

  const OrderIsPay = (document) => {
    window.pay(document.getElementById(NumberOfOrder));
    /*props.SendCloseOrder(NumberOfOrder, "ACTIVE");
        props.updateProductsOrders(props.pay);*/
  };

  const RepeatOrder = () => {
    let basket = [];
    ProductS.forEach((item) => {
      let article = item.info.article;
      let quantity = item.quantity;
      basket.push({
        article: article,
        quantity: quantity,
      });
    });

    if (!apiLoad) {
      setAPILoad(true);
      let promo = props.OrderProductInfo.promo
        ? props.OrderProductInfo.promo.id
        : null;
      fetch(global.components.url + "/order/api/order/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "x-csrf-token": token,
        },
        body: JSON.stringify({
          personality: {
            phone: props.OrderProductInfo.personality.phone,
            region: props.OrderProductInfo.personality.region,
            surname: props.OrderProductInfo.personality.surname,
            name: props.OrderProductInfo.personality.name,
            middlename: props.OrderProductInfo.personality.middlename,
            email: props.OrderProductInfo.personality.email,
            comment: props.OrderProductInfo.personality.comment,
          },
          delivery: {
            delivery: props.OrderProductInfo.delivery.delivery,
            price: props.OrderProductInfo.delivery.price,
            period: props.OrderProductInfo.delivery.period,
            status: "WAIT-PAY",
          },

          user: props.OrderProductInfo.user,
          basket: basket,
          address: props.OrderProductInfo.address,
          country: props.OrderProductInfo.country,
          payments: props.OrderProductInfo.payments,
          promo: promo,
          total_price: props.OrderProductInfo.total_price,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          
          console.log(result);

          if (result.create === true) {
            setAPILoad(false);
            window.location = "/confirm_order/" + btoa(result.id);
          }

          if (props.ParrentName === undefined) {
            props.updateProducts();
          }
        });
      props.updateProductsOrders(props.pay);
    } else {
      alert("Подождите. Запрос в обработке");
    }
  };

  let ProductList;
  if (typeof ProductS !== "undefined") {
    ProductList =
      // eslint-disable-next-line array-callback-return
      ProductS.map((item, index) => {
        // Ограничение вывода
        if (index < MaxValOutput) {
          return (
            <ProductTable
              key={"ProductsInCartProductTable" + index}
              ParrentName={"ProductsInCart"}
              id={item.info.article}
              name={item.info.name}
              img={item.info.image[0].image}
              price={item.info.price}
              ProductInfo={item.info}
              quantity={item.quantity}
            />
          );
        }
      });
  }
  let PayInfo;
  if (typeof props.pay !== undefined) {
    if (props.pay === "Payed") {
      PayInfo = <p className="PayInfo">Заказ Оплачен </p>;
    } else if (props.pay === "NotPayed") {
      if (Status !== "WAIT-OPERATOR") {
        PayInfo = (
          <button className="PayProduct" onClick={() => {
            if (linkDol !== ""){
              window.location = linkDol;
            }else{
              OrderIsPay(document)
            }
            
            }}>
            Оплатить
          </button>
        );
      }
    }
  }

  let idParrentName;
  let infoAboutOrder;
  let ValProducts;
  let Button;
  let timerCloseOrder;

  let DELIVERY_SERVICE = {
    "CDEK-PICKUP": "СДЕК самовызов",
    "CDEK-DELIVERY": "СДЕК доставка",
    "BOXBERRY-PICKUP": "Boxberry самовызов",
    PICKUP: "Самовызов",
    "COURIER-PITER": "Курьер по Санкт-Петербугру",
    POCHTA: "Почта России",
  };
  let DELIVERY_STATUS = {
    "WAIT-PAY": "Ожидание оплаты",
    "WAIT-OPERATOR": "Ожидание оператора",
    "CONFIRM-WAIT-PAY": "Подтвержден оператором и ожидает оплату",
    "CHANGE-WAIT-PAY": "Ваша корзина изменена оператором и ожидает оплату",
    "NOT-PAID": "Не был оплачен",
    PAID: "Оплачен",

    COLLECTED: "Собран",
    TRANSFERRED: "Доставляется",
    DELIVERED: "Доставлен",
    CLOSED: "Закрыт",
    CANCELLED: "Отменен",

    "REQUEST-REFUND": "Запрос на возврат",
    REFUNDED: "Возвращен",
  };

  // FIXME-хз зачем это было сделанно
  if (props.ParrentName !== undefined) {
    idParrentName = props.ParrentName;
  } else {
    infoAboutOrder = (
      <div className="order">
        <div className="NumberOfOrder">Заказ №{NumberOfOrder}</div>
        <div className="data">Дата оформления: {Date}</div>
        <div style={{ width: "240px" }} className="status">
          Статус заказа: <span>{DELIVERY_STATUS[Status]}</span>
        </div>
        <div className="orderCost">
          Сумма заказа: <span>{Cost} руб.</span>
        </div>
        <div className="status">
          Трек номер:{" "}
          <span>
            {trackNumber ? trackNumber : "Здесь появится ваш трек номер"}
          </span>
        </div>
      </div>
    );
    ValProducts = <p>Товаров - {QuantityProducts}</p>;
    if (props.pay !== "Payed") {
      Button = (
        <button className="CanselOrder" onClick={CloseOrder}>
          Отменить заказ
        </button>
      );
    }

    if (props.OrderProductInfo !== null) {
      if (props.OrderProductInfo.delivery.status === "WAIT-PAY") {
        timerCloseOrder = (
          <div className="timer">
            <p>Заказы без оплаты резервируются только на 24 часа</p>
            <p>
              До отмены заказа осталось:{" "}
              <span id="TimeLeft">{props.OrderProductInfo["timeleft"]}</span>
            </p>
          </div>
        );
      }
    }
  }

  let seeMoreProducts;
  if (MaxValOutput < QuantityProducts) {
    seeMoreProducts = (
      <button onClick={OpenFullProducts}>Посмотреть все товары заказа</button>
    );
  }

  if (props.OrderProductInfo !== null) {
    return (
      <div className="OrderProductComponent" id={idParrentName}>
        <div className="left">
          {/* <div style={{paddingBottom: "15px", fontWeight: "bold"}}>
                        <p>Ваша корзина должно быть подтверждена оператором.</p>
                        <p>(Ваша корзина может быть изменена, проверьте перед оплатой)</p>
                    </div> */}

          <div className="title">
            {infoAboutOrder}
            {timerCloseOrder}
          </div>

          <div className="productsInCartInfo">
            {ValProducts}
            <div className="products">{ProductList}</div>

            {seeMoreProducts}
          </div>
        </div>
        <div className="right">
          <div className="shortInfoCost">
            <h3>Доставка и оплата</h3>
            <p className="DeliveryMethod">
              Служба доставки: <span>{DELIVERY_SERVICE[DeliveryMethod]}</span>
            </p>
            <p className="Adress">{Adress}</p>
            <p className="PaymentMethod">
              Способ оплаты: <span>{PaymentMethod}</span>
            </p>
            <div className="ShortAllInfo">
              <div className="left">
                <p>Итого (без промокода)</p>
                <p>Доставка</p>
                {promo ? <p>Промокод</p> : <p></p>}
                <p className="CostValue">К оплате</p>
              </div>
              <div className="right">
                <p>{Cost} руб.</p>
                <p>{deliveryCost} руб.</p>
                {promo ? (
                  <p>
                    {promo.code} - {promo.percent}%
                  </p>
                ) : (
                  <p></p>
                )}
                <p className="CostValue">{Cost} руб.</p>
              </div>
              <div className="clearfix"></div>
            </div>

            {PayInfo}
          </div>

          <div className="PersonInfo">
            <h3>Информация о покупателе</h3>
            <div className="FIO">
              <p>Фамилия Имя Отчество:</p>
              <p>{Name}</p>
            </div>
            <div className="telephone">
              <p>Телефон:</p>
              <p>{Telephone}</p>
            </div>
            <div className="email">
              <p>Email:</p>
              <p>{Email}</p>
            </div>
          </div>

          <div className="Buttons">
            {Button}
            {/*<button className='RepeatOrder' onClick={RepeatOrder}>Повторить заказ</button>*/}
          </div>
        </div>
        <div className="clearfix"></div>
        <Pay
          id={NumberOfOrder}
          cost={props.OrderProductInfo.total_price}
          name={Name}
          email={Email}
          phone={Telephone}
        ></Pay>
      </div>
    );
  } else {
    return <p>Как-то пусто...</p>;
  }
}
