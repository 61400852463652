import React from 'react'

export default function Adress() {
  return (
    <div className='Adress MakingAnOrder'>
      <h3>Адрес доставки<span className='red'>*</span></h3>
      <input id='AdressInput' className='' disabled></input>
    </div>
  )
}
