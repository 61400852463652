import React, {useState} from 'react'
import "./css/Cost.css"

const Cost = (props) => {
    const param = new URLSearchParams(window.location.search)
    const [displayStyle, setDisplayStyle] = useState("none");
    const [gteValue, setGteValue] = useState(param.get("price__gte"));
    const [lteValue, setLteValue] = useState(param.get("price__lte"));


    const OpenShadowBlock = () => {
        let shadowCost = document.getElementById("shadowBlockCost");
        if (shadowCost.style.display === "none") {
            shadowCost.style.display = "flex";
            setDisplayStyle("flex")
            document.getElementById("arrDownCost").style.transform = "rotate(180deg)";
        } else {
            setDisplayStyle("none")
            shadowCost.style.display = "none";
            document.getElementById("arrDownCost").style.transform = "rotate(0)";
        }

    }

    function pushFiltres() {
        if (!gteValue) {
            param.delete("price__gte")
        } else {
            param.set("price__gte", gteValue)

        }
        if (!lteValue) {
            param.delete("price__lte")
        } else {
            param.set("price__lte", lteValue)

        }
        window.location.search = param

        document.getElementById("filtreMenu").classList.add("closeFiltreMenu");
        document.getElementById("filtreMenu").style.display = "none"
    }

    return (
        <div className='FiltreComponent cost'>
            <button onClick={() => OpenShadowBlock()} id='OpenShadowBlock'>
                <p>Цена</p>
                <div className='arrDown' id='arrDownCost'></div>
            </button>

            <div style={{display: displayStyle}} className="shadowBlock" id='shadowBlockCost'>
                <div className='inputsCost'>
                    <input value={gteValue} type="text" id="price_gte" placeholder='0' onChange={(e) => {
                        setGteValue(e.target.value)
                    }}></input>
                    <div className='line'></div>
                    <input value={lteValue} id="price_lte" placeholder='99999' onChange={(e) => {
                        setLteValue(e.target.value)
                    }}></input>
                </div>
                <button className="buttonCost" onClick={pushFiltres}>Показать</button>
            </div>
        </div>
    )
}
export default Cost;
