import React, {useEffect, useState} from 'react'
import "./css/DiscontContent.css"

import TypeListCatalog from '../catalog/catalogContent/TypeListCatalog'
import LeftCatalog from '../catalog/catalogContent/LeftCatalog'
import Filtres from '../catalog/catalogContent/Filtres'
import {searchFiltres} from "../catalog/CatalogContent";

const DiscontContent = (props) => {
    const [product, setProduct] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const param = new URLSearchParams(window.location.search);

    useEffect(() => {
        let params = param.toString()

        let discount;
        if (!props.discountNow) {
            discount = "ALL"
        } else {
            discount = props.discountNow.toUpperCase()
        }

        if (params) {
            params += `&discount=${discount}`
        } else {
            params = `discount=${discount}`
        }


        fetch(global.components.url + `/product/api/product/?${params}&format=json`)
            .then(res => res.json())
            .then((result) => {
                setProduct(result["results"]);
                setPage(result["pages"])
                setLoading(true)
            },)

    }, [])

    if (!isLoading) {
        return (
            <div>

            </div>
        )
    } else {
        return (
            <div className='DiscontContent'>
                <div className='content'>
                    <LeftCatalog
                        disountNow={props.discountNow}
                        ParrentName={"discount"}
                        searchFiltres={searchFiltres}
                    />
                    <Filtres
                        filtres={props.filtres}

                        ParrentName={props.ParrentName}
                        // setSearchProducts
                    />
                    <TypeListCatalog
                        // searchFiltres={}
                        products={product}
                        discont={"discont"}
                        page={page}
                    />
                </div>
            </div>
        )
    }
}

export default DiscontContent;