class BasketStorage {
    // {"basket": [], "quantity": 0}
    constructor() {
        this.basket = JSON.parse(localStorage.getItem("basket"));
        this.event = new Event('storage');
    }

    setBasket(value = {}) {
        localStorage.basket = JSON.stringify(this.basket)
        this.event.newValue = Object.assign({}, {"object": "basket"}, value);
        window.dispatchEvent(this.event);
        this.setDone(false)
        return true
    }

    getPosition(article) {
        return this.basket.basket.findIndex(item => item.article === article);
    }

    setDone(done) {
        this.basket.done = done
        localStorage.basket = JSON.stringify(this.basket)
    }

    updateBasket(article, quantity) {
        let product = this.getProduct(article)
        let method = ""
        let difference;
        if (!product) {
            this.basket.basket.push({"article": article, "quantity": quantity});
            this.basket.quantity += quantity;
            method = "append"
        } else {
            if (product["quantity"] > quantity) {
                difference = product["quantity"] - quantity
                this.basket.quantity -= (difference);
                method = "minus"
            } else {
                difference = quantity - product["quantity"]
                this.basket.quantity += (difference);
                method = "plus"
            }
            let position = this.getPosition(article)
            this.basket.basket[position]["quantity"] = quantity
        }
        this.setBasket({"method": method, "article": article, "difference": difference});
        return {"method": method, "article": article, "difference": difference}
    }

    getProduct(article) {
        let position = this.getPosition(article);
        if (position === -1) {
            return false;
        } else {
            return this.basket.basket[position]
        }
    }

    isExist(article) {
        return this.getPosition(article) !== -1;

    }

    getProductsArticles() {
        let articles = []
        this.basket.basket.map((item, index) => {
            articles.push(item["article"])
        });
        return articles
    }

    deleteProduct(article) {
        let position = this.getPosition(article)
        let quantity = this.basket.basket[position]["quantity"]
        this.basket.quantity -= quantity;
        this.basket.basket.splice(position, 1)
        this.setBasket({"method": "delete", "article": article, "quantity": quantity});
        return {"method": "delete", "article": article, "quantity": quantity}
    }

    deleteAllProduct() {
        this.basket.basket = []
        this.basket.quantity = 0
        this.setBasket({"method": "deleteAll"});
    }

    getProducts() {
        //
    }

}

export default BasketStorage;