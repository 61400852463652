import React, { useState, useEffect } from 'react'
import './css/ProductTable.css';

import Like from "../../img/likeActive.svg"
import LikeGreenActive from "../../img/LikeGreenActive.svg"
import { Link } from 'react-router-dom';

export default function ProductTable(props) {

    const [infoToPupUp, setinfoToPupUp] = useState()
    const [quantity, setquantity] = useState(1)
    const [price, setprice] = useState()

    const [like, setlike] = useState(null)

    useEffect(() => {
        if (localStorage.getItem("LikedProducts") === null) {
            localStorage.setItem('LikedProducts', '');
        }

        if (infoToPupUp === undefined) {
            setinfoToPupUp(props.ProductInfo)
        }
        if (price === undefined) {
            setprice(props.price)
        }

        if (like === null) {
            setlike(props.ProductInfo.is_favorite)
        }
        if (like === false) {
            let arrLikedProductsnew = localStorage.getItem("LikedProducts").split(',');
            arrLikedProductsnew.forEach((item) => {
                if (props.ProductInfo.article === item) {
                    setlike(true)
                }
            })
        } else {
            let arrLikedProductsnew = localStorage.getItem("LikedProducts").split(',');
            let error = [];
            arrLikedProductsnew.forEach((item) => {
                if (props.ProductInfo.article === item) {
                    error.push(item)
                }
            })
            if (error.length === 0) {
                setlike(props.ProductInfo.is_favorite)
            }
        }
    })

    const openFastChekProduct = () => {
        let scrollPopUpGalery = window.pageYOffset;
        scrollPopUpGalery = scrollPopUpGalery + "px";

        document.getElementById('PopUpOnCategory').style.display = "flex";
        document.getElementById('PopUpOnCategory').style.top = (scrollPopUpGalery);

        document.getElementById('body').classList.add("fixed");
        document.getElementById('body').style.top = ("-" + scrollPopUpGalery);

        document.getElementById('headerBg').classList.add("hover");

        let Price;
        if (infoToPupUp.discount !== null) {
            Price = infoToPupUp.discount_price.new_price;
        } else {
            Price = price;
        }


        props.updateData(infoToPupUp, quantity, Price);
    }

    const QuantityPlus = () => {
        let updQua = quantity + 1
        if (updQua > props.ProductInfo.amount) {
            setquantity(props.ProductInfo.amount)
        } else {
            setquantity(updQua)
        }
    }

    const QuantityMinus = () => {

        let updQua = quantity - 1
        if (updQua <= 0) {
            setquantity(1)
            alert("Нельзя указать товара меньше одного");
        } else {
            setquantity(updQua)
        }
    }

    const getProduct = () => {
        let infoToBasket = { id: props.ProductInfo.article, info: props.ProductInfo, quantity: quantity }
        props.updateDataProducts(infoToBasket);
    }

    const likedProduct = (e) => {
        let likedIdProduct = e.currentTarget.className;

        fetch(global.components.url + "/user/api/user/favourite/", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "profile": props.idPersone,
                "products": [likedIdProduct]
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("result likeProduct", result);
                    // console.log("profile:", props.idPersone, typeof(props.idPersone));
                    // console.log("product:", likedIdProduct, typeof(likedIdProduct));
                    console.log("");
                },
                (error) => {
                    console.log("error", error);
                    console.log("profile:", props.idPersone);
                    console.log("products:", likedIdProduct);
                });



        // likedIdProduct = article
        let arrLikedProducts = localStorage.getItem("LikedProducts").split(',');
        let newArrLiked = []
        if (arrLikedProducts[0] !== '') {
            let error = false;
            arrLikedProducts.forEach((item, index) => {
                if (item === likedIdProduct) {
                    error = index;
                    delete arrLikedProducts[error];
                } else {
                    newArrLiked.push(arrLikedProducts[index]);
                }
            })
            if (error === false) {
                newArrLiked.push(likedIdProduct);
            }
        } else {
            newArrLiked.push(likedIdProduct);
        }

        localStorage.setItem('LikedProducts', newArrLiked);

        if (like === false) {
            console.log(like)
            let arrLikedProductsnew = localStorage.getItem("LikedProducts").split(',');
            arrLikedProductsnew.forEach((item) => {
                if (props.ProductInfo.article === item) {
                    setlike(true)
                }
            })
        } else {
            setlike(false)
        }

        props.updateLikeProduct()
    }


    var display = {
        display: "none"
    },
        flex = {
            display: "none"
        },
        none = {
            display: "block"
        },
        inlineBlock = {
            display: "none"
        },
        difference = "",
        nameSale = "",
        salePercent = "",
        oldCost = "",
        newCost = "",
        cost = props.price

    if (typeof (props.ProductInfo.discount) == "string") {

        difference = props.ProductInfo.discount_price.difference;
        nameSale = props.ProductInfo.discount_price.type;
        salePercent = props.ProductInfo.discount_price.percent;
        oldCost = props.ProductInfo.discount_price.old_price;
        newCost = props.ProductInfo.discount_price.new_price;

        inlineBlock = {
            display: "inline-block"
        };
        cost = newCost;
        if (nameSale == "SEASON") {
            flex = {
                display: "flex",
                backgroundColor: "#7D5C70",
            };
            display = {
                display: "block",
                color: "#7D5C70"
            };
        } else {
            flex = {
                display: "flex",
                backgroundColor: "#E94536",
            };
            display = {
                display: "block",
                color: "#FF4747"
            };
        }

        none = {
            display: "none"
        };
    }

    let is_favorite,
        isAgree = like;
    if (props.ParrentName !== "ProductsInCart") {
        if (isAgree === true) {
            is_favorite = <div id='likeImg' className={props.ProductInfo.article} onClick={likedProduct} style={{ backgroundImage: `url(${LikeGreenActive})` }}></div>
        } else {
            is_favorite = <div id='likeImg' className={props.ProductInfo.article} onClick={likedProduct} style={{ backgroundImage: `url(${Like})` }}></div>
        }
    }

    let fastCheck,
        haveOnShop,
        total,
        quantityBlock,
        costBlock,
        discountOldPrice,
        discontProcent;
    let img;

    if (props.ProductInfo.image.length > 0) {
        img = `url(${props.ProductInfo.image[0].image})`
    }

    if (props.ParrentName !== "ProductsInCart") {
        fastCheck =
            <div className='fastCheck'>
                <button onClick={openFastChekProduct}></button>
            </div>;
        if (props.ProductInfo.amount > 0) {
            haveOnShop =
                <div className='haveOnShop'>
                    <div className='aproveImg'></div>
                    <p>Есть в наличии</p>
                    {is_favorite}
                    <div className='saleName' style={flex}>
                        <p className='name'>{nameSale}</p>
                        <p className='percent'>-{salePercent} %</p>
                    </div>
                    <div className='discontCost' style={display}>
                        <p className='economy'>Экономия </p>
                        <p className='cost'>{difference} руб.</p>
                    </div>
                </div>;
            total =
                <div className='total'>
                    <button id='minus' onClick={() => QuantityMinus()}>-</button>
                    <input id='totalValue' value={quantity} disabled></input>
                    <button id='plus' onClick={() => QuantityPlus()}>+</button>
                    <button id='getProduct' onClick={getProduct}>В корзину</button>
                </div>;
        } else {
            haveOnShop =
                <div className='haveOnShop'>
                    <p>Нет в наличии</p>
                    {is_favorite}
                    <div className='saleName' style={flex}>
                        <p className='name'>{nameSale}</p>
                        <p className='percent'>-{salePercent} %</p>
                    </div>
                    <div className='discontCost' style={display}>
                        <p className='economy'>Экономия</p>
                        <p className='cost'>{difference} руб.</p>
                    </div>
                </div>;
        }


        costBlock =
            <div className='Cost'>
                <div className='oldPropCost'>
                    <p id='costProduct' style={none}>{props.price}</p>
                    <p style={none}>руб/шт</p>
                </div>

                <div className='newCost' style={display}>
                    <p>{newCost} </p>
                    <p>руб/шт</p>
                </div>
                <div className='oldCost' style={display}>
                    <p>{oldCost}</p>
                    <p>руб/шт</p>
                </div>
            </div>


    } else if (props.ParrentName === "ProductsInCart") {
        if (typeof (props.ProductInfo.discount) == "string") {
            discontProcent =
                <span className='right'>
                    <span>Cкидка: </span>
                    <span className='procent'>{salePercent}</span>
                    <span>%</span>
                </span>

            discountOldPrice =
                <span className='oldCost'>
                    <p>
                        <span>{oldCost}</span>
                        <span>руб/шт</span>
                    </p>
                    <span>{discontProcent}</span>
                </span>
        }
        quantityBlock =
            <div className='quantity'>
                <p>Количество: {props.quantity} шт.</p>
                <div>
                    <span>Сумма: </span>
                    <span>{cost * props.quantity}</span>
                    <span>руб.</span>
                    {discountOldPrice}
                </div>
            </div>
    }

    let ProductTable = 'ProductTable' + props.ParrentName;

    return (
        <div className='ProductTable' id={ProductTable}>
            <div className='littleInformationProduct'>

                <Link to={{
                    pathname: `/product/${props.ProductInfo.article}`
                }}>
                    <div className='img'
                        style={{
                            backgroundImage: img
                        }}
                    >
                    </div>
                </Link>

                {fastCheck}



                <div className='information'>

                    <div className='textAboutProduct' id={props.ParrentName}>
                        <Link to={{
                            pathname: `/product/${props.ProductInfo.article}`
                        }}>
                            <h3 id='title'>{props.name}</h3>
                        </Link>
                        {haveOnShop}
                        {quantityBlock}
                    </div>
                    <div className='costInformation'>
                        {costBlock}
                        {total}
                    </div>
                </div>

            </div>
        </div>
    )
}
