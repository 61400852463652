import React, { Component } from 'react'
import "./css/FormRegistration.css"

import { Link } from "react-router-dom";
import Cookies from 'js-cookie'

import BelCountry from './img/bel.png'
import RuCountry from './img/ru.png'
import Password from "../../StaticForms/PasswordField/Password";

export default class FormRegistration extends Component {

    state = {
        telephonenumber: null,
        country: "+7",
        secName: null,
        name: null,
        therdName: null,
        email: null,
        pass: null,

        error: null,

        data: [],
        aproveNumberCode: null,
        profile_id: null,

        profileInformation: null,

        apiLoading: false,
    }

    handleFormSubmit = e => {
        e.preventDefault();
    }

    checkSubmitRegForm = () => {
        let error = []
        if (document.getElementById("pass").value === "") {
            error.push("pass")
            document.getElementById("password").textContent = "Пустой пароль"
            document.getElementById("password").style.color = "#DB2700"
        } else if (document.getElementById("pass").value !== document.getElementById("secPass").value) {
            document.getElementById("password").style.color = ""
            error.push("pass")
            error.push("secPass")
            document.getElementById("secPassError").textContent = "Пароли не совпадают"
        } else {
            error = []
        }

        if (error.length === 0) {
            this.setState({
                telephonenumber: document.getElementById("telephonenumber").value,
                pass: document.getElementById("pass").value,
                country: this.state.country,
                secName: document.getElementById("secName").value,
                name: document.getElementById("name").value,
                therdName: document.getElementById("therdName").value,
                email: document.getElementById("email").value,
            }, function () {
                this.submitForm();
            })
        } else {
            error.forEach((item) => {
                document.getElementById(item).classList.add("error")
            })
        }
    }

    // Отправка данных при регистрации
    submitForm = () => {
        // console.log("error", this.state.error);
        if (this.state.error == null) {
            if (!this.state.apiLoading){
                this.setState({
                    apiLoading: true
                })
                fetch(global.components.url + '/user/api/user/registration/', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user: {
                            username: this.state.email,
                            password: this.state.pass,
                        },
                        profile: {
                            phone: this.state.telephonenumber,
                            region: this.state.country,
                            surname: this.state.secName,
                            name: this.state.name,
                            middlename: this.state.therdName,
                            email: this.state.email,
                        }
                    }),
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("r", result)
                            let error = false;
                            if (result['unique']){
                                alert("Пользовтель существует и подтверждён")
                                error = true;
                            }
                            if (result['user']) {
                                if (result['user']["password"]) {
                                    document.getElementById("password").textContent = result["user"]["password"].join("\n");
                                    document.getElementById("password").style.color = "#DB2700";
                                }
                                if (result['user']['phone']){
                                    window.alert("Телефон уже используется")
                                }
                                error = true;
                            }
                            if (result['profile']){
                                if (result['profile']["email"]){
                                    window.alert("Уже зарегистрирован, но не подтверждён.\nПроверьте почту")
                                }
                                if (result['profile']["phone"]){
                                    window.alert("Телефон уже используется")
                                    /*alert("Уже зарегистрирован, но не подтверждён.\nПроверьте почту")*/
                                }
                                error = true;
                            }
                            if (result["error"]){
                                if (result["error"].length !== 0){
                                    window.alert(result["error"][0])
                                    error = true;
                                }

                            }
                            this.setState({
                                apiLoading: false
                            })
                            if(!error) {
                                window.alert("Проверьте почту");
                                window.location.href = '/authorization';
                            }
                        })
            }
            else {
                alert("Ожидайте обработку")
            }
        }

    }

    openShadowTelephoneCountry = () => {
        let statusShadow = document.getElementById("shadowTelephoneCountry").style.display;

        if (statusShadow === "none") {
            document.getElementById("shadowTelephoneCountry").style.display = "inline-block";
        } else {
            document.getElementById("shadowTelephoneCountry").style.display = "none";
        }
    }
    takeCountry = (e) => {
        let id = e.currentTarget.id;

        if (id === "ruCountry") {
            document.getElementById("imageCountry").style.backgroundImage = `url(${RuCountry})`
            document.getElementById("codeCountry").textContent = "+7"
            this.setState({
                country: "+7"
            })
        } else if (id === "belCountry") {
            document.getElementById("imageCountry").style.backgroundImage = `url(${BelCountry})`
            document.getElementById("codeCountry").textContent = "+375"
            this.setState({
                country: "+375"
            })
        }
        document.getElementById("shadowTelephoneCountry").style.display = "none";
    }

    checkKey = (event) => {
        if (!/[А-Я,а-я]/.test(event.key)) {
            event.preventDefault();
            window.alert("Пишите кирилицей и без пробелов")
        }
        document.getElementById(event.target.id).classList.remove("error")
    }

    render() {
        return (
            <div className='content'>
                <form className='FormRegistration' onSubmit={this.handleFormSubmit}>
                    <div className="field telephone">
                        <div className='info'>
                            <p>Телефон<span>*</span></p>
                            <p id="phone" className='information'>
                                {/* Мы отправим на этот номер код подтверждения */}
                            </p>
                        </div>
                        <span id='telephoneInput' className=''>
                            <button id='country'> {/*onClick={this.openShadowTelephoneCountry}*/}
                                <div className='img' id='imageCountry'></div>
                                <div className='arrDown'></div>
                            </button>
                            <div id='shadowTelephoneCountry' style={{ display: "none" }}>
                                <button id='ruCountry' > {/* onClick={this.takeCountry} */}
                                    <div className='img'></div>
                                </button>
                                {/*
                                <button id='belCountry' onClick={this.takeCountry}>
                                    <div className='img'></div>
                                </button>
                                */}
                            </div>
                            <span id='codeCountry'>+7</span>
                            <input pattern="\d{10}" required id='telephonenumber'
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        window.alert("Нужно вводить только цифры")
                                    }
                                    document.getElementById("telephoneInput").classList.remove("error")
                                }}
                                maxLength={10}
                            ></input>
                        </span>
                    </div>

                    <div className="field secName">
                        <div className='info'>
                            <p>Фамилия<span>*</span></p>
                            <p className='information'>Необходимо для доставки</p>
                        </div>
                        <input required id='secName' className=''
                            onKeyPress={(event) => {
                                this.checkKey(event)
                            }}
                        ></input>
                    </div>

                    <div className="field name">
                        <div className='info'>
                            <p>Имя<span>*</span></p>
                            <p className='information'></p>
                        </div>
                        <input required id='name'
                            onKeyPress={(event) => {
                                this.checkKey(event)
                            }}
                        ></input>
                    </div>

                    <div className="field therdName">
                        <div className='info'>
                            <p>Отчество<span>*</span></p>
                            <p className='information'></p>
                        </div>
                        <input required id='therdName'
                            onKeyPress={(event) => {
                                this.checkKey(event)
                            }}
                        ></input>
                    </div>

                    <div className="field email">
                        <div className='info'>
                            <p>Email<span>*</span></p>
                            <p className='information' id='email__error' >Получение онлайн-чека</p>
                        </div>
                        <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id='email' required></input>
                    </div>

                    <div className="field pass">
                        <div className='info'>
                            <p>Пароль<span>*</span></p>
                            <p className='information' id='password'>Минимум 8 символов</p>
                        </div>
                        <Password width="100%" id='pass' />
                    </div>

                    <div className="field secPass">
                        <div className='info'>
                            <p>Повторите пароль<span>*</span></p>
                            <p className='information' id='secPassError'></p>
                        </div>
                        <Password width="100%" id='secPass'  onKeyPress={(event) => {
                            document.getElementById("secPass").classList.remove("error")
                            document.getElementById("secPassError").textContent = ""
                        }}/>
                      {/*  <input required  id='secPass' type="password"

                        ></input>*/}
                    </div>

                    <div className='sub'>
                        <div className='inputs'>
                            <input required defaultChecked type="checkbox" id='personDate' name='personDate'></input>
                            <label htmlFor="personDate"><p>Я согласен на <a target="_blank" href="https://docs.google.com/document/d/1RJpzgYc6zBzfurUVEL2ZQ4efy8m0HVk3/edit?usp=sharing&ouid=114054014589921802560&rtpof=true&sd=true">обработку персональных данных</a></p></label>
                        </div>
                        <button type='submit' className='buttonReg' onClick={this.checkSubmitRegForm}>Зарегистрироваться</button>
                    </div>

                    <div className='clearfix'></div>

                </form>

                <div className='goLog'>
                    <p>Уже есть аккаунт?</p>
                    <Link to="/authorization">Войти</Link>
                </div>

            </div>
        )
    }
}
