import React, { Component } from 'react'
import './css/InstagramExamples.css'
import {Link} from "react-router-dom";

export default class InstagramExamples extends Component {
  render() {
    return (
      <div className='InstagramExamples'>
        <div className='content'>
          <div className='titles'>
            <div className='left'>
              <h2>Следите за нами <p>в социальных сетях</p></h2>
            </div>
            <div className='right'>
              <div className='info'>
                <p>Подписывайтесь на нас, следите за новинками каталого нашего интернет-магазина, не стесняйтесь спрашивать или связываться с нами, если у вас есть какие-либо вопросы</p>
                {/* <p>наш инстаграм <a href="https://www.instagram.com/furnitura.mjw/">@furnitura.mjw</a></p> */}
              </div>
              <div className='Soc'>
                <a target="_blank" href={global.components.whatsapp[0]} className='WhatsappLink'></a>
                <a target="_blank" href={global.components.tg[0]} className='TelegrammLink'></a>
                <a target="_blank" href={global.components.vk[0]} className='VkLink'></a>
              </div>
            </div>
            <div className='clearfix'></div>
          </div>
          <div className='InstagramPosts'>
              <div className="instagram_post" onClick={() => window.open(global.components.vk[0])}><div className='img in1'></div></div>
              <div className="instagram_post" onClick={() => window.open(global.components.vk[0])}><div className='img in2'></div></div>
              <div className="instagram_post" onClick={() => window.open(global.components.vk[0])}><div className='img in3'></div></div>
              <div className="instagram_post" onClick={() => window.open(global.components.vk[0])}><div className='img in4'></div></div>
            </div>
        </div>
      </div>
    )
  }
}
