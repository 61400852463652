import React, { useState, useEffect } from 'react'
import Way from '../Components/Pages/globalComponents/Way';
import CatalogContent from '../Components/Pages/catalog/CatalogContent'
//import {MetaTags} from "react-meta-tags";
import { Helmet } from 'react-helmet';
import Text from '../Components/StaticForms/Text/Text'
const Catalog = (props) => {

    const [way] = useState(["Mary Furnitura", "Каталог", "Список товаров каталога"]);
    const [title, setTitle] = useState("Каталог товаров");
    const [categoryNow, setCategoryNow] = useState(window.location.pathname.split('/')[2]);
    const [filtres, setFiltres] = useState();
    const [text, setText] = useState();
    const [category, setCategory] = useState([]);
    const [meta, setMeta] = useState(<Helmet><title>Каталог</title></Helmet>);

    useEffect(() => {
        if(category == false){
            fetch(global.components.url + "/product/api/category/get/?format=json")
                .then(res => res.json())
                .then((result) => {
                    setCategory(result['result'])
                })
        }
    }, [setCategory, ])

    useEffect(() => {
        let meta;
        if(!categoryNow){
            fetch(global.components.url + "/product/api/characteristic/?format=json")
                .then(res => res.json())
                .then(
                    (result) => {
                        setFiltres(result)
                    })
        }else {
            fetch(global.components.url + "/product/api/category/" + categoryNow + "/?format=json")
                .then(res => res.json())
                .then(
                    (result) => {
                        setCategoryNow(decodeURI(categoryNow))
                        setFiltres(result["filter"])
                        setText(result["text"])
                        setTitle(result["h1"])
                        setMeta(
                            <Helmet>
                                <title>{result["title"]}</title>
                                <meta name="description" content={result["description"]}/>
                            </Helmet>
                        )
                    })
        }
    }, [setCategoryNow])



    return (
        <div className='catalog' id='body'>
            {meta}
            <Way
                title={title}
                way={way}
            />
            <CatalogContent
                category={category}
                filtres={filtres}
                categoryNow={categoryNow}
                setCategoryNow={setCategoryNow}
            />
            {
                text ? <Text text={text} /> : <div></div>
            }
        </div>
    );
}

export default Catalog;