import React, { useState } from 'react'
import Contact from '../Components/Pages/Contacts/Contact'
import Way from '../Components/Pages/globalComponents/Way'
//import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';

export default function Contacts() {
  const [title] = useState("Контакты");
  const [way] = useState(["Mary Furnitura", "Контакты"]);

  return (
    <div className='Contacts'>
        <Helmet>
            <title>Контакты</title>
        </Helmet>
      <Way
        title={title}
        way={way}
      />
      <Contact />
    </div>
  )
}
