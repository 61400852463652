import React, { useState, useEffect } from "react";
import "./css/ProductList.css";
import { Link } from "react-router-dom";
import BasketStorage from "../../../../Methods/localStorage/BasketStorage";

export default function ProductList(props) {
  const [LastQuantityRes, setLastQuantityRes] = useState(0);

  const [price, setPrice] = useState(props.product.price);
  const [oldPrice, setOldPrice] = useState();
  const [discount, setDiscount] = useState();
  const [CostDiscount, setCostDiscount] = useState();
  const [spanRub, setSpanRub] = useState();

  const [OnCart, setOnCart] = useState({ display: "none" });

  const [classNameBaattonOnLike, setclassNameBaattonOnLike] = useState();
  const [ProductList, setProductList] = useState();
  const [basket, setBasket] = useState(props.basket);
  const [ProdInfo, setProdInfo] = useState();

  useEffect(() => {
    if (classNameBaattonOnLike === undefined) {
      setclassNameBaattonOnLike("ButtonLiked" + props.product.article);
    }
    setProductList(props.product);
    setProdInfo(props.product);
    if (props.ProductList === "ProductListOnBasket") {
      if (props.product.discount_price) {
        setPrice(props.product.discount_price.new_price);
        setOldPrice(props.product.discount_price.old_price);
        setSpanRub("руб/шт");
        setDiscount("Cкидка: ");
        setCostDiscount(props.product.discount_price.percent + "%");
      } else {
        setPrice(props.product.price);
        setSpanRub();
        setDiscount();
        setCostDiscount();
        setOldPrice();
      }
    } else if (props.ProductList === "ProductListOnLiked") {
      setOnCart({ display: "block" });
    }
  });

  useEffect(() => {
    let product = props.basket.getProduct(props.product.article);
    if (product) {
      setLastQuantityRes(product["quantity"]);
    } else {
      setLastQuantityRes(0);
    }
  });

  const QuantityPlus = () => {
    let quantityResUpd = LastQuantityRes + 1;
    setLastQuantityRes(quantityResUpd);
    updateQuantityItem(quantityResUpd);
  };
  const QuantityMinus = () => {
    let quantity = LastQuantityRes - 1;
    setLastQuantityRes(quantity);
    updateQuantityItem(quantity);
  };

  const removeProductItem = (e) => {
    if (props.ProductList === "ProductListOnBasket") {
      let basketValue = basket.deleteProduct(props.product.article);
      props.setPrice(props.product, basketValue["quantity"], "minus");
      props.setProductInBasket(props.product);
    } else {
      props.favourite.updateFavourite(props.product.article);
      props.deleteFavourite(props.product);
    }
  };

  const updateQuantityItem = (quantityResUpd) => {
    let quantity = quantityResUpd;
    if (props.ProductList === "ProductListOnLiked") {
      if (quantity > props.product.amount) {
        quantity = props.product.amount;
        alert("Превышен лимит для этого продукта");
      }
      if (quantity <= 0) {
        quantity = 1;
        alert("Удалите из корзины");
      }
    } else {
      if (quantity > props.product.amount) {
        quantity = props.product.amount;
        alert("Превышен лимит для этого продукта");
      } else if (quantity <= 0) {
        quantity = 1;
        alert("Удалите из корзины");
      }
    }
    setLastQuantityRes(quantity);

    let valueBasket = basket.updateBasket(props.product.article, quantity);
    let new_price = Number(
      document.getElementById("allCost").textContent.split(" ")[0]
    );
    let difference = valueBasket["difference"];
    if (valueBasket["method"] === "plus") {
      props.setPrice(props.product, difference, "plus");
    } else if (valueBasket["method"] === "minus") {
      props.setPrice(props.product, difference, "minus");
    }
  };

  const takeOnOrder = () => {
    if (
      props.product["amount"] <= 0 ||
      props.product["is_discontinued"] !== false
    ) {
      window.alert("Товара в данный момент нет в наличии");
    } else {
      let qua;
      if (LastQuantityRes === 0) {
        qua = 1;
      } else {
        qua = LastQuantityRes;
      }
      setLastQuantityRes(qua);
      basket.updateBasket(props.product.article, qua);
      props.appendProductInBasket(props.product);
    }
  };

  let img;
  if (
    props.product.image.length !== 0 &&
    props.product.image[0].image !== undefined
  ) {
    img = `url(${props.product.image[0].image})`;
  }

  return (
    <div className="ProductTableBasket" id={ProductList}>
      <div className="littleInformationProduct">
        <div
          className="img"
          style={{
            backgroundImage: img,
          }}
        ></div>
        <div className="information">
          <div className="textAboutProduct">
            <h3 id="title">
              <Link to={"/product/" + props.product.article + "/"}>
                {props.product.name}
              </Link>
            </h3>
            <div className="haveOnShop">
              <p>Цена {price} руб/шт</p>
              <p id="crossedOut">
                {oldPrice}
                <span>{spanRub}</span>
              </p>
            </div>
            <p id="discontCost">
              {discount}
              <span>{CostDiscount}</span>
            </p>
          </div>
          <div className="costInformation">
            {LastQuantityRes > 0 ? (
              <div className="Cost">
                <p id="costProduct">
                  {price * LastQuantityRes}
                  <span>руб</span>
                </p>
                {oldPrice ? (
                  <p id="costWithoutDiscont">
                    {oldPrice * LastQuantityRes} <span>руб</span>
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            ) : (
              <div className="Cost">
                <p id="costProduct">
                  {price}
                  <span>руб</span>
                </p>
                {oldPrice ? (
                  <p id="costWithoutDiscont">
                    {oldPrice} <span>руб</span>
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            )}
            {!props.display ? (
              <div>
                {props.ProductList === "ProductListOnBasket" ? (
                  <div className="total">
                    <button id="minus" onClick={QuantityMinus}>
                      -
                    </button>
                    <input
                      id="totalValue"
                      value={LastQuantityRes}
                      disabled
                    ></input>
                    <button id="plus" onClick={QuantityPlus}>
                      +
                    </button>
                    {/*<button id={classNameBaattonOnLike} className="classNameBaattonOnLike" style={OnCart} onClick={takeOnOrder}>В корзине</button>*/}
                  </div>
                ) : (
                  <div className="total">
                    {LastQuantityRes > 0 ? (
                      <span
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <button id="minus" onClick={QuantityMinus}>
                          -
                        </button>
                        <input
                          id="totalValue"
                          value={LastQuantityRes}
                          disabled
                        ></input>
                        <button id="plus" onClick={QuantityPlus}>
                          +
                        </button>
                        {/*<button id={classNameBaattonOnLike} className="classNameBaattonOnLike" style={OnCart} onClick={takeOnOrder}>В корзине</button>*/}
                      </span>
                    ) : (
                      <button
                        id={classNameBaattonOnLike}
                        className="classNameBaattonOnLike"
                        style={OnCart}
                        onClick={takeOnOrder}
                      >
                        В корзину
                      </button>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div
            className="cross"
            id={props.id}
            onClick={removeProductItem}
          ></div>
        </div>
      </div>
    </div>
  );
}
