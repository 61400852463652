import React from "react";

const Pay = (props) => {
    return (
        <div>
            <form id={props.id} className="TinkoffPayForm" name="TinkoffPayForm" >
                <input className="tinkoffPayRow" type="hidden" name="terminalkey" value="1653232011687"/>
                    <input className="tinkoffPayRow" type="hidden" name="frame" value="false"/>
                    <input className="tinkoffPayRow" type="hidden" name="language" value="ru"/>
                    <input value={props.cost} className="tinkoffPayRow" type="text" placeholder="Сумма заказа" name="amount"/>
                    <input value={props.id} className="tinkoffPayRow" type="text" placeholder="Номер заказа" name="order"/>
                    <input value={props.name} className="tinkoffPayRow" type="text" placeholder="ФИО плательщика" name="name"/>
                    <input value={props.email} className="tinkoffPayRow" type="text" placeholder="E-mail" name="email"/>
                    <input value={props.phone} className="tinkoffPayRow" type="text" placeholder="Контактный телефон" name="phone"/>
                    <input className="tinkoffPayRow" type="submit" value="Оплатить" />
            </form>
        </div>
    )
}

export default Pay;