import React, {useState, useEffect} from 'react'
import './css/ProductsOutputType.css'

import ProductTile from '../../../globalComponents/ProductOutputType/ProductTile.js';
import ProductList from '../../../globalComponents/ProductOutputType/ProductList.js';
import ProductTable from '../../../globalComponents/ProductOutputType/ProductTable.js';


export default function ProductsOutputType(props) {

    if (props.outputType === "tile") {
        return (

                <div className='ProductsTile'>
                    {props.products.map((item, index) => (
                        <ProductTile
                            basket={props.basket}
                            favourite={props.favourite}
                            key={"ProductTile" + index}
                            id={item.article}
                            name={item.name}
                            price={item.price}
                            ProductInfo={item}
                            updateData={props.updateData}
                            discont={props.discont}
                        />
                    ))}
                </div>
        )
    }
}

