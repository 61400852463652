import React, {Component} from 'react'
import "./css/CatalogContent.css"

import {useState} from 'react'


import LeftCatalog from './catalogContent/LeftCatalog'
import Filtres from './catalogContent/Filtres'
import TypeListCatalog from './catalogContent/TypeListCatalog'
import async from "async";
import catalog from "../../../Routes/Catalog";
import Pagination from "../../StaticForms/Pagination/Pagination";


export const searchFiltres = (filtres, mob, filterData, setFilterData, setCategoryIn, setCharacteristicIn, setProduct, discount, price, popularity, lte, gte) => {
    function generateQ() {
        let characteristic__id = "";
        let category__in = "";
        filterData.forEach((item) => {
            if (item.type === "category__in") {
                category__in = item.attribute_id;
            } else if (item.type === "characteristic__id") {
                characteristic__id = item.items.toString() + "," + characteristic__id;
            }
        });
        return [category__in, characteristic__id]
    }


    let NewArr = [];
    let OldArr = filterData;
    let type;


    if (filtres) {

        filtres.forEach((item) => {
            if (NewArr[0] === null) {
                NewArr = []
            }
            if (item.value !== null) {
                NewArr.push(item.value);
            } else {
                NewArr.push(item.value)
            }
            type = item.type
        });

        let obj;

        if (filtres.length !== 0 && filtres[0].id !== undefined) {
            obj = {attribute_id: filtres[0].id, items: NewArr, type: type}
        }

        let indToPush = true;
        let num = OldArr.length - 1
        let arrToPuh = [];

        if (OldArr.length === 0) {
            OldArr.push(obj);
        } else {
            OldArr.forEach((item, index) => {
                if (obj) {
                    if (item.attribute_id === obj.attribute_id) {
                        OldArr[index] = obj;
                        indToPush = false;
                    }
                    if (obj.type === "category__in" && item.type === "category__in") {
                        OldArr[index] = obj;
                        indToPush = false;
                    }
                }
                if (index === num && indToPush === true) {
                    OldArr.push(obj);
                }
            })

            OldArr.forEach((item, index) => {
                if (item) {
                    if (item.items === null) {
                        delete OldArr[index];
                    }
                }
            })
        }

        OldArr.forEach((item, index) => {
            if (item !== undefined && item.items[0] !== null) {
                arrToPuh.push(item);
            }
        })

        setFilterData(arrToPuh)
        filterData = arrToPuh
    }
    let sets;
    if (filterData.length) {
        console.log("filterddd", filterData)
        sets = generateQ();
        setCharacteristicIn(sets[1])
    } else {
        if (setCategoryIn) {
            sets = [setCategoryIn("")['id'], ""]
        } else {
            sets = ["", ""]
        }
    }

    if (mob === undefined) {
        fetchFiltres(sets[0], sets[1], setProduct, discount, price, popularity, lte, gte);
    }
}


export const fetchFiltres = async (categoryIn, characteristicIn, setProduct, discount, price, popularity, lte, gte) => {
    let stringCharacteristic__id = [];
    if (characteristicIn) {
        characteristicIn.split(',').filter(Number).forEach((item) => {
            stringCharacteristic__id.push("&characteristic__id=" + item)
        })
    }


    let characteristic__id = stringCharacteristic__id.join('');

    let discountQ = "";
    if (discount) {
        discountQ = "&discount=" + discount
    }

    if (!price) {
        price = ""
    }
    if (!popularity) {
        popularity = ""
    }


    if (!categoryIn) {
        categoryIn = ""
    }

    console.log("filtersss", categoryIn, characteristicIn, discount, price, popularity, lte, gte)

    fetch(global.components.url + "/product/api/product/?price__lte=" + lte + "&price__gte=" + gte + "&category__in=" + categoryIn + characteristic__id + discountQ + "&ordering=" + price + "," + popularity)
        .then(res => res.json())
        .then((result) => {
            // TODO: Пагинацию сюда засунуть
            setProduct(result["results"]);

        },)
}


export default class CatalogContent extends Component {
    state = {
        error: null, isLoaded: false,

        searchFiltreData: [],

        price: "price", popularity: "popularity",

        products: [],

        // to filtres

        filtre: '',
        page: [],
        params: new URLSearchParams(window.location.search),
        nowPage: false,
    }

    componentDidMount() {            
        let params = this.state.params.toString()

        if (this.props.ParrentName === "SearchCatalogData") {
            console.log(this.props.category)
            this.setState({
                products: this.props.products["results"],
                page: this.props.products["pages"],
                isLoaded: true,
            })
            if (params) {
                params += `&name__icontains=${window.location.pathname.split('/search/')[1].slice(0, -1)}`
            }else{
                params = `&name__icontains=${window.location.pathname.split('/search/')[1].slice(0, -1)}`
            }

        } else {
            if (this.props.categoryNow) {
                if (params) {
                    params += `&category__in=${this.props.categoryNow}`
                } else {
                    params = `category__in=${this.props.categoryNow}`
                }

            }
            
        }
        fetch(global.components.url + `/product/api/product/?${params}&format=json`)
        .then(res => res.json())
        .then((result) => {
            this.setState({
                page: result["pages"],
                products: result["results"],
                isLoaded: true,
            })
        })
    }

    searchFiltresMob = () => {
        document.getElementById("filtreMenu").classList.add("closeFiltreMenu");
        document.getElementById("filtreMenu").style.display = "none"
    }

    render() {
        if (!this.state.isLoaded) {
            return <div></div>
        } else {
            return <div className='CatalogContent'>
                <div className='content'>

                    <LeftCatalog
                        category={this.props.category}
                        category__in={this.props.categoryNow}
                        ParrentName={this.props.ParrentName}
                    />
                    <Filtres
                        category={this.props.category}
                        filtres={this.props.filtres}
                        searchFiltres={searchFiltres}
                        ParrentName={this.props.ParrentName}
                        setSearchProducts={this.props.setSearchProducts}
                    />

                    <TypeListCatalog
                        products={this.state.products}
                        page={this.state.page}
                    />


                    <div className='clearfix'></div>

                </div>
            </div>
        }
    }
}

// }