import React, {Component} from 'react'
import "./css/FiltreComponents.css"

export default class FiltreCompontent extends Component {
    state = {
        searchFiltres: [],
        searchLastFiltres: [],
        activeFiltre: null,
        params: new URLSearchParams(window.location.search)
    }

    OpenShadowBlock = () => {
        let shadow
        if (this.props.ParrentName === undefined) {
            shadow = document.getElementById("shadow" + this.props.filtres.attribute_id);
        } else {
            shadow = document.getElementById(this.props.ParrentName + "shadow" + this.props.filtres.attribute_id);
        }

        if (shadow.style.display === "none") {
            shadow.style.display = "flex";
            if (this.props.ParrentName === undefined) {
                document.getElementById("arrDown" + this.props.filtres.attribute_id).style.transform = "rotate(180deg)";
            } else {
                document.getElementById(this.props.ParrentName + "arrDown" + this.props.filtres.attribute_id).style.transform = "rotate(180deg)";
            }

            if (this.props.activeFiltreComp !== null) {
                if (this.props.ParrentName === undefined) {
                    document.getElementById("shadow" + this.props.activeFiltreComp).style.display = "none";
                    document.getElementById("arrDown" + this.props.activeFiltreComp).style.transform = "rotate(0)";
                } else {
                    document.getElementById(this.props.ParrentName + "shadow" + this.props.activeFiltreComp).style.display = "none";
                    document.getElementById(this.props.ParrentName + "arrDown" + this.props.activeFiltreComp).style.transform = "rotate(0)";
                }
                this.setState({
                    activeFiltre: this.props.filtres.attribute_id,
                }, function () {
                    this.props.updateActiveFiltre(this.state.activeFiltre);
                })
            } else {
                this.setState({
                    activeFiltre: this.props.filtres.attribute_id,
                }, function () {
                    this.props.updateActiveFiltre(this.state.activeFiltre);
                })
            }


        } else {
            shadow.style.display = "none";
            if (this.props.ParrentName === undefined) {
                document.getElementById("arrDown" + this.props.filtres.attribute_id).style.transform = "rotate(0)";
            } else {
                document.getElementById(this.props.ParrentName + "arrDown" + this.props.filtres.attribute_id).style.transform = "rotate(0)";
            }
            this.setState({
                activeFiltre: null,
            }, function () {
                this.props.updateActiveFiltre(this.state.activeFiltre);
            })
        }
    }
    CloseShadowBlock = () => {
        if (this.props.ParrentName === undefined) {
            document.getElementById("shadow" + this.props.filtres.attribute_id).style.display = "none";
            document.getElementById("arrDown" + this.props.filtres.attribute_id).style.transform = "rotate(0)";
        } else {
            document.getElementById(this.props.ParrentName + "shadow" + this.props.filtres.attribute_id).style.display = "none";
            document.getElementById(this.props.ParrentName + "arrDown" + this.props.filtres.attribute_id).style.transform = "rotate(0)";
        }
    }


    filterIsExist = (id) => {
        let value = this.state.params.getAll("characteristic__id")
        return value.indexOf(id.toString()) !== -1
    }

    setFiltres = (e) => {
         let params;

        if(this.props.params !== undefined){
            params = this.props.params
        }else {
            params = this.state.params
        }
        let characteristics = params.getAll("characteristic__id")
        let index = characteristics.indexOf(e.currentTarget.id)

        if (index !== -1) {
            params.delete("characteristic__id");
            characteristics.splice(index, 1);
            for (let characteristic of characteristics) {
                params.append("characteristic__id", characteristic);
            }
        } else {
            params.append("characteristic__id", e.currentTarget.id);
        }
    }

    pushFiltres = () => {
        window.location.search = this.state.params
    }

    render() {
        // Для названий блоков

        if (this.props.ParrentName === undefined) {
            let
                shadowBlock = "shadow" + this.props.filtres.attribute_id,
                button = "block" + this.props.filtres.attribute_id,
                arrDown = "arrDown" + this.props.filtres.attribute_id;

            return (
                <div className='FiltreComponent'>
                    <button className={button} onClick={this.OpenShadowBlock}>
                        <p>{this.props.filtres.attribute}</p>
                        <div className='arrDown' id={arrDown}></div>
                    </button>

                    <div id={shadowBlock} className="shadowBlock" style={{display: "none"}}>
                        {this.props.filtres.values.map((item, index) => (
                            <div className='inputs' key={"inputsFiltreComponent" + index}>
                                {
                                    this.filterIsExist(item.id)
                                        ?
                                        <input className="filterCheckBox" defaultChecked={true} type="checkbox"
                                               id={"inputsFiltre" + item.value} name={item.value}></input>
                                        :
                                        <input className="filterCheckBox" type="checkbox"
                                               id={"inputsFiltre" + item.value} name={item.value}></input>
                                }
                                <label for={"inputsFiltre" + item.value} className={item.value} id={item.id}
                                       onClick={this.setFiltres}><span>{item.value}</span></label>
                                <div className='line'></div>
                            </div>
                        ))}

                        <button onClick={this.pushFiltres}>Показать</button>
                    </div>
                </div>
            )
        } else {
            let
                shadowBlock = this.props.ParrentName + "shadow" + this.props.filtres.attribute_id,
                button = this.props.ParrentName + "block" + this.props.filtres.attribute_id,
                arrDown = this.props.ParrentName + "arrDown" + this.props.filtres.attribute_id;
            return (
                <div className='FiltreComponent'>
                    <button className={button} onClick={this.OpenShadowBlock}>
                        <p>{this.props.filtres.attribute}</p>
                        <div className='arrDown' id={arrDown}></div>
                    </button>

                    <div id={shadowBlock} className="shadowBlockinfo" style={{display: "none"}}>
                        {this.props.filtres.values.map((item, index) => (
                            <div className='inputs' key={"inputsFiltreComponent" + index}>
                                {
                                    this.filterIsExist(item.id)
                                        ?
                                        <input className="filterCheckBox" defaultChecked={true} type="checkbox" id={item.value}
                                               name={item.value}></input>
                                        :
                                        <input className="filterCheckBox" type="checkbox" id={item.value}
                                               name={item.value}></input>

                                }
                                <label onClick={this.setFiltres} for={item.value} className={item.value}
                                       id={item.id}><span>{item.value}</span></label>
                                <div className='line'></div>
                            </div>
                        ))}

                    </div>

                </div>
            )
        }
    }
}
