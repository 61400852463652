import React, { Component } from 'react'
import DeliveryAndPaymentInformation from '../Components/Pages/staticPages/deliveryAndPayment/DeliveryAndPaymentInformation'
//import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';

export default class GarantDeliveryAndPayment extends Component {
  render() {
    return (
      <div className='GarantDeliveryAndPayment'>
        <Helmet>
            <title>Доставка и оплата</title>
        </Helmet>
          <DeliveryAndPaymentInformation />
      </div>
    )
  }
}
