import React, {useEffect, useState} from 'react'
import './css/ProductTile.css';

import Like from "../../img/likeActive.svg"
import LikeGreenActive from "../../img/LikeGreenActive.svg"
import {Link} from 'react-router-dom';
import BasketStorage from "../../../../Methods/localStorage/BasketStorage";
import cost from "../../catalog/catalogContent/componentsFiltre/Cost";
import Favourite from "../../../../Methods/localStorage/Favourite";

export default function ProductTile(props) {

    const [infoToPupUp, setinfoToPupUp] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [price, setprice] = useState()

    const [like, setlike] = useState(null)
    const [onBasket, setonBasket] = useState(false)

    // const [totalBlock, settotalBlock] = useState()
    const [updstateTotalBlock, setUpdstateTotalBlock] = useState(true)

    const [styleQuantity, setstyleQuantity] = useState({display: 'none'})
    const [styleGet, setstyleGet] = useState({display: 'none'})
    const [basket,] = useState(props.basket)
    const [favourite,] = useState(props.favourite)
    const [lockPlus, setLockPlus] = useState(false)
    const [lockMinus, setLockMinus] = useState(false)
    useEffect(() => {
        if (!infoToPupUp) {
            setinfoToPupUp(props.ProductInfo)
        }
        if (!price) {
            setprice(props.price)
        }

        if (!like) {
            setlike(favourite.isFavourite(props.ProductInfo.article))
        }

        let product = basket.getProduct(props.ProductInfo.article)
        if (product) {
            setQuantity(product.quantity)
            setonBasket(true)
        }
    }, [])

    if (updstateTotalBlock === true && onBasket !== undefined) {
        if (window.innerWidth > 1280) {
            setstyleQuantity(
                {display: 'flex'}
            )
            setstyleGet(
                {display: 'block'}
            )

        } else {
            if (onBasket === true) {
                setstyleQuantity(
                    {display: 'flex'}
                )
                setstyleGet(
                    {display: 'none'}
                )
            } else {
                setstyleQuantity(
                    {display: 'none'}
                )
                setstyleGet(
                    {display: 'block'}
                )
            }
        }
        setUpdstateTotalBlock(false)
    }


    const openFastChekProduct = () => {
        document.getElementById("Footer").style.display = "none";

        let scrollPopUpGalery = window.pageYOffset;
        scrollPopUpGalery = scrollPopUpGalery + "px";

        document.getElementById('PopUpOnCategory').style.display = "flex";
        document.getElementById('PopUpOnCategory').style.top = (scrollPopUpGalery);

        document.getElementById('body').classList.add("fixed");
        document.getElementById('body').style.top = ("-" + scrollPopUpGalery);

        document.getElementById('headerBg').classList.add("hover");

        let Price;
        if (infoToPupUp.discount !== null) {
            Price = infoToPupUp.discount_price.new_price;
        } else {
            Price = price;
        }
        props.updateData(props.ProductInfo, quantity, Price);
    }

    const QuantityPlus = () => {
        setLockMinus(false)
        if (quantity + 1 > props.ProductInfo.amount) {
            setQuantity(props.ProductInfo.amount)
            if(lockPlus) {
                alert("Превышен лимит товаров")
                setLockPlus(true)
            }
        } else {
            setQuantity(quantity + 1)
        }
        if (window.innerWidth <= 1280) {
            getProductToButn()
        }
    }

    const QuantityMinus = () => {
        setLockPlus(false)
        let updQua = Number(quantity) - 1
        if (updQua <= 0) {
            setQuantity(1)
            if (lockMinus){
                alert("Нельзя указать товара меньше одного");
                setLockMinus(true);
            }
        } else {
            setQuantity(updQua)
        }
        if (window.innerWidth <= 1280) {
            getProductToButn()
        }
    }

    const getProductToButn = () => {
        basket.updateBasket(props.ProductInfo.article, quantity)
    }

    const getProduct = (e) => {
        let button = e.target
        let button_width = button.style.width

        button.style.width = "100%"
        button.textContent = "Добавлен в корзину"

        let total = button.previousSibling
        total.style.display = "none"

        setUpdstateTotalBlock(true)
        setonBasket(true)
        basket.updateBasket(props.ProductInfo.article, quantity)
        setTimeout(() => {
            button.textContent = "В корзине"
            button.style.width = button_width
            total.style.display = "flex"
        }, 800)
    }

    const likedProduct = (e) => {
        let likedIdProduct = e.currentTarget.className;

        // likedIdProduct = article
        favourite.updateFavourite(likedIdProduct)
        setlike(favourite.isFavourite(likedIdProduct))
    }

    let quantityBlock;
    if (styleQuantity !== undefined) {
        quantityBlock =
            <div className='quantity'
                 style={styleQuantity}>
                <button id='minus' onClick={QuantityMinus}>-</button>
                <input id={'totalValue'} value={quantity} disabled></input>
                <button id='plus' onClick={QuantityPlus}>+</button>
            </div>
    }

    let toBasketButton;
    if (styleGet !== undefined) {
        if (onBasket) {
            toBasketButton = <button id='getProduct' onClick={(e) => getProduct(e)}
                                     style={styleGet}
            >В корзине</button>
        } else {
            toBasketButton = <button id='getProduct' onClick={(e) => getProduct(e)}
                                     style={styleGet}
            >В корзину</button>
        }
    }

    let total =
        <div className='total'>
            {quantityBlock}
            {toBasketButton}
        </div>

    // for render
    let is_favorite,
        isAgree = like;

    if (isAgree === true) {
        is_favorite = <div id='likeImg' className={props.ProductInfo.article} onClick={likedProduct}
                           style={{backgroundImage: `url(${LikeGreenActive})`}}></div>
    } else {
        is_favorite = <div id='likeImg' className={props.ProductInfo.article} onClick={likedProduct}
                           style={{backgroundImage: `url(${Like})`}}></div>
    }

    let img;
    if (props.ProductInfo.image.length > 0) {
        img = `url(${props.ProductInfo.image[0].image})`
    }
    let haveOnShop

    if (props.ProductInfo.amount > 0) {
        haveOnShop =
            <div className='haveOnShop'>
                <div className='aproveImg'></div>
                <p>Есть в наличии</p>
            </div>
    } else {
        haveOnShop =
            <div className='haveOnShop'>
                <p>Нет в наличии</p>
            </div>
    }
    let display = {
            display: "none"
        },
        flex = {
            display: "none"
        },
        none = {
            display: "inline"
        },
        inlineBlock = {
            display: "none"
        },
        difference = "",
        nameSale = "",
        salePercent = "",
        oldCost = "",
        newCost = "";

    if (typeof (props.ProductInfo.discount) === "string") {
        difference = props.ProductInfo.discount_price.difference;
        nameSale = props.ProductInfo.discount_price.type;
        salePercent = props.ProductInfo.discount_price.percent;
        oldCost = props.ProductInfo.discount_price.old_price;
        newCost = props.ProductInfo.discount_price.new_price;

        inlineBlock = {
            display: "inline-block"
        };

        if (nameSale === "SEASON") {
            flex = {
                display: "flex",
                backgroundColor: "#7D5C70",
                position: "absolute",
            };
            display = {
                display: "block",
                color: "#7D5C70"
            };
        } else {
            flex = {
                display: "flex",
                backgroundColor: "#E94536",
                position: "absolute",
            };
            display = {
                display: "block",
                color: "#FF4747"
            };
        }

        none = {
            display: "none"
        };
    }


    return (
        <div style={{width: props.width}} className='ProductTile'>
            <div className='littleInformationProduct'>
                <Link to={{
                    pathname: `/product/${props.ProductInfo.article}/`
                }}>
                    <div className='img'
                         style={{
                             backgroundImage: img
                         }}
                    >
                        <div className='saleName' style={flex}>
                            <p className='name'>{nameSale}</p>
                            <p className='percent'>-{salePercent} %</p>
                        </div>
                    </div>
                </Link>
                {is_favorite}

                <div className='fastCheck'>
                    <button onClick={openFastChekProduct}>Быстрый просмотр</button>
                </div>

                <Link to={{
                    pathname: `/product/${props.ProductInfo.article}/`
                }}>
                    <div className='information'>
                        <h3 id='title'>{props.name}</h3>
                        <div className='havePlusDiscont'>
                            {haveOnShop}
                            <div className='discontCost' style={display}>
                                <div id='discontCost'>
                                    <p className='economy'>Экономия </p>
                                    <p className='cost'>{difference} руб.</p>
                                </div>
                            </div>
                        </div>
                        <div className='Cost'>
                            <div className='oldPropCost'>
                                <p id='costProduct' style={none}>{props.price}</p>
                                <p style={none}>руб/шт</p>
                            </div>

                            <div className='newCost' style={inlineBlock}>
                                <p>{newCost}</p>
                                <p>руб/шт</p>
                            </div>
                            <div className='oldCost' style={inlineBlock}>
                                <p>{oldCost}</p>
                                <p>руб/шт</p>
                            </div>
                        </div>
                    </div>
                </Link>

                {total}
            </div>
        </div>
    )
}