import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './css/Subscription.css'

export default function Subscription(props) {

    const [profileData, setprofileData] = useState(props.profileData)
    const [Email, setEmail] = useState(props.email);
    const [ActiveAprove, setActiveAprove] = useState();
    const [ActiveSending, setActiveSending] = useState();
    const [mail_id, setmail_id] = useState()
    const [confirmCode, setconfirmCode] = useState();

    useEffect(() => {

        if (ActiveAprove === undefined || ActiveSending === undefined) {
            setIsAprovedMailing();
        }
    })

    const setIsAprovedMailing = () => {
        if (profileData.mailing === null) {
            setActiveAprove("Нет")
            setActiveSending("Нет")
            console.log("NotMailing")
        } else {
            setEmail(profileData.mailing.email);
            if (profileData.mailing.mailing === true) {
                setActiveAprove("Да")
                if (profileData.mailing.is_active === true) {
                    setActiveSending("Да")
                    document.getElementById("IsAprovedMailing").style.display = "block";
                    document.getElementById("ButtonUnsub").style.display = "block";
                } else {
                    setActiveSending("Нет")
                }
            } else if (profileData.mailing.mailing === false) {
                setActiveAprove("Нет")
                setActiveSending("Нет")

                setTimeout(() => {
                    console.log(ActiveAprove, ActiveSending);
                }, 0);
                document.getElementById("IsAprovedMailing").style.display = "none";
                document.getElementById("ButtonUnsub").style.display = "none";
            }
            document.getElementById("infoSubMailing").style.display = "block";
        }

    }
    const sendMail = () => {
        let email = document.getElementById("mailSend").value
        setEmail(document.getElementById("mailSend").value);
        let pushMailData = {
            "profile": props.id,
            "email": document.getElementById("mailSend").value,
            "mailing": true
        }

        fetch(global.components.url + '/user/api/user/mailing/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pushMailData),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"]){
                        alert("Проверьте почту")
                    }else {
                        console.log(result)
                        alert("Введите другую почту")
                    }},
                (error) => {
                    console.log(error);
                })


    }
    const UnSubscribeMailing = () => {
        fetch(global.components.url + "/user/api/user/mailing/", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "profile": profileData.id,
                "email": Email,
                "mailing": false
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    profileData.mailing = result.mail;
                    setIsAprovedMailing();
                })
    }

    return (
        <div className='SubscriptionComponent'>
            <div className='settingsSub'>
                <h4>Настройки подписки</h4>
                <div className='infoEmail'>
                    <div className='title'>
                        <p><strong>Email</strong></p>
                        <div className='text'>
                            <p>После подписки или изменения email подписки на почту будет выслан код подтверждения.</p>
                        </div>
                    </div>
                    <div className='input'>
                        <input id="mailSend" defaultValue={Email}></input>
                        <div className='inputs'>
                            <input defaultChecked type="checkbox" id='personDate' name='personDate'></input>
                            <label htmlFor="personDate"><p>Я согласен на <Link to="/">обработку персональных данных</Link></p></label>
                        </div>
                        <button onClick={sendMail}>Изменить адрес подписки</button>
                    </div>
                </div>
                <div className='error' id='errorMail'>
                    <p className='red' style={{display: "none"}}>Адрес подписки уже существует</p>
                </div>
            </div>
            <div className='infoSub' id='infoSubMailing' style={{ display: "none" }}>
                <h4>Состояние подписки</h4>
                <div>
                    <p>Email подписки:</p>
                    <p>Подписка подтверждена:</p>
                    <p>Подписка активна:</p>
                </div>
                <div className='info'>
                    <p id="now_email">{Email}</p>
                    <p>{ActiveAprove}</p>
                    <p>{ActiveSending}</p>
                </div>
                <button id='ButtonUnsub' onClick={UnSubscribeMailing} style={{ display: "none" }}>Отписаться</button>
            </div>
            <div className='infoAprove' id='IsAprovedMailing' style={{ display: "none" }}>
                <p>Ваша подписка подтверждена и активна.</p>
                <p>Если вы хотите отписаться от рассылок, нажмите на кнопку Отписаться.</p>
            </div>
        </div>
    )
}
