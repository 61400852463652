import "./OrderConfirm.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pay from "../Profile/InformationProfile/OrderProduct/Pay";
//import { MetaTags } from "react-meta-tags";
import { Helmet } from 'react-helmet';

const OrderConfirm = () => {
  const [order, setOrder] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [linkDol, setLinkDol] = useState("");
  let DELIVERY_STATUS = {
    "WAIT-PAY": "Ожидание оплаты",
    "WAIT-OPERATOR": "Ожидание оператора",
    "CONFIRM-WAIT-PAY": "Подтвержден оператором и ожидает оплату",
    "CHANGE-WAIT-PAY": "Ваша корзина изменена оператором и ожидает оплату",
    "NOT-PAID": "Не был оплачен",
    PAID: "Оплачен",

    COLLECTED: "Собран",
    TRANSFERRED: "Доставляется",
    DELIVERED: "Доставлен",
    CLOSED: "Закрыт",
    CANCELLED: "Отменен",

    "REQUEST-REFUND": "Запрос на возврат",
    REFUNDED: "Возвращен",
  };
  useEffect(() => {
    fetch(
      global.components.url +
        "/order/api/order/" +
        atob(decodeURIComponent(window.location.href.split("/")[4])) +
        "/"
    )
      .then((res) => res.json())
      .then((result) => {
        console.log('hello order confirm')
        console.log(result)
        setName(
          result.personality.surname +
            " " +
            result.personality.name +
            " " +
            result.personality.middlename
        );
        setOrder(result);
        setEmail(result.personality.email);
        setPhone(result.personality.region + result.personality.phone);
        setStatus(result.delivery.status);
      });

      fetch(global.components.url + "/order/api/dolyame/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "x-csrf-token": token,
        },
        body: JSON.stringify({
          pk: atob(decodeURIComponent(window.location.href.split("/")[4]))
        })
      }).then((res) => res.json())
      .then((result) => {
        console.log(result)
        if (result.status == 'ok'){
            setLinkDol(result.link)
        }
      })
  }, []);

  if (order !== {}) {
    return (
      <div className="ConfirmFrame OrderConfirm">
        <Helmet>
          <title>Заказ успешно подтвержден</title>
        </Helmet>
        <div className="OrderConfirm ConfirmInfo">
          <div className="OrderConfirm Confirm__Up">
            <p className="OrderConfirm Confirm__name">
              Ваш заказ от {order.date} успешно создан.
            </p>
            {status === "WAIT-PAY" ? (
              <button
                onClick={() => {
                  if (linkDol !== ""){
                    window.location = linkDol
                  }else{
                    window.pay(document.getElementById(order.id));
                  }
                  
                }}
                className="PayButton"
              >
                Оплатить
              </button>
            ) : (
              <p>
                ВЫ НЕ МОЖЕТЕ ОЛАТИТЬ ЗАКАЗ, ПОТОМУ ЧТО ОЖИДАНИЕ ОПЛАТЫ ИСТЕКЛО
                ИЛИ ОН УЖЕ БЫЛ ОПЛАЧЕН
              </p>
            )}
          </div>
          <div className="OrderConfirm ConfirmStatus">
            <p>
              Статус заказа <strong>{DELIVERY_STATUS[status]}</strong>
            </p>
            <p>
              Сумма заказа <strong>{order.total_price} руб</strong>
            </p>
          </div>
        </div>
        <p className="OrderLine" />
        <div className="OrderConfirm">
          <p>Вся информация была отправлена на почту: {email}</p>
          <Link to="/profile">
            <button className="GoToProfile">ПЕРЕЙТИ В ЛИЧНЫЙ КАБИНЕТ</button>
          </Link>
          <p>
            Напоминаем, что заказы без оплаты резервируются только на 24 часа,
            статус заказа можно проверить в личном кабинете на сайте.
          </p>
        </div>

        <Pay
          id={order.id}
          cost={order.total_price}
          name={name}
          email={email}
          phone={phone}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default OrderConfirm;
