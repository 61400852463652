import React, { useState, useEffect, useCallback } from "react";

import "./css/ProductsInBasket.css";
import ProductList from "./productListComp/ProductList";
import BasketStorage from "../../../Methods/localStorage/BasketStorage";
import Favourite from "../../../Methods/localStorage/Favourite";
import Disconts from "../../../Routes/Disconts";
import banner from "./Cash_sticker-1.png";
import DolyameBanner from "../Dolyame/DolyameBanner";

export default function ProductsInBasket(props) {
  const [defaultBasketLink, setDefaultBasketLink] = useState(props.href);

  const [ProductListToBasket, setProductListToBasket] = useState([]);
  const [HaventOnShop, setHaventOnShop] = useState([]);

  const [LikedS, setLikedS] = useState([]);
  const [promo, setPromo] = useState(null);

  const [basket, setBasket] = useState(new BasketStorage());
  const [favourite, setFavourite] = useState(new Favourite());
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [showBasket, setShowBasket] = useState();

  useEffect(() => {
    if (basket.basket["basket"].length !== 0) {
      let Product = [];
      let Havent = [];
      let total_price = 0;
      let total_discount = 0;
      fetch(
        global.components.url +
          "/product/api/short_product/?article__in=" +
          basket.getProductsArticles().toString() +
          "&format=json"
      )
        .then((res) => res.json())
        .then((result) => {
          result.forEach((item) => {
            if (item["amount"] > 0 && item["is_discontinued"] === false) {
              total_price += getTotalPriceProduct(item);
              total_discount += getTotalDiscount(item);
              Product.push(item);
            } else if (
              item.info.amount <= 0 ||
              item["is_discontinued"] !== false
            ) {
              Havent.push(item);
            }
          });
          setDiscount(total_discount);
          setProductListToBasket(Product);
          setHaventOnShop(Havent);
          setPrice(total_price);
        });
    }

    if (favourite.favourite["articles"] !== []) {
      if (
        LikedS === undefined ||
        LikedS.length !== favourite.favourite["articles"].length
      ) {
        SetLikesArr();
      }
    }
  }, []);

  const setNewPrice = (currentProduct, quantity, method) => {
    if (method === "plus") {
      setPrice(price + getTotalPriceProduct(currentProduct, quantity));
      setDiscount(discount + getTotalDiscount(currentProduct));
    } else {
      setPrice(price - getTotalPriceProduct(currentProduct, quantity));
      setDiscount(discount - getTotalDiscount(currentProduct));
    }
  };

  const setProductInBasket = (currentProduct) => {
    let newList = ProductListToBasket;
    let index = newList.findIndex(
      (item) => item.article === currentProduct.article
    );
    newList.splice(index, 1);
    setProductListToBasket(newList);
  };

  const deleteFavourite = (currentProduct) => {
    let newList = LikedS;
    let index = newList.findIndex(
      (item) => item.article === currentProduct.article
    );
    newList.splice(index, 1);
    setLikedS(newList);
  };
  const appendProductInBasket = (currentProduct) => {
    let newList = ProductListToBasket;
    newList.push(currentProduct);
    setPrice(price + getTotalPriceProduct(currentProduct, 1));
    setDiscount(discount + getTotalDiscount(currentProduct));
    setProductListToBasket(newList);
  };

  useEffect(() => {
    let newBasket = new BasketStorage();
    setBasket(newBasket);
  }, []);

  const getTotalPriceProduct = (item, quantity = false) => {
    if (quantity === false) {
      quantity = basket.getProduct(item["article"])["quantity"];
    }
    if (!item["discount_price"]) {
      return item.price * quantity;
    } else {
      return item["discount_price"]["new_price"] * quantity;
    }
  };

  const getTotalDiscount = (item) => {
    if (item["discount_price"]) {
      return (
        item["discount_price"]["difference"] *
        new BasketStorage().getProduct(item["article"])["quantity"]
      );
    }
    return 0;
  };

  const SetLikesArr = () => {
    fetch(
      global.components.url +
        "/product/api/short_product/?article__in=" +
        favourite.favourite["articles"].toString()
    )
      .then((res) => res.json())
      .then((result) => {
        setLikedS(result);
      });
  };
  const clearBasket = () => {
    setPrice(0);
    setDiscount(0);
    setProductListToBasket([]);
    basket.deleteAllProduct();
  };

  const goToLink = (e) => {
    let BasketLink = e.currentTarget.id;

    // nonactive
    document.getElementById(defaultBasketLink).classList.remove("active");
    document.getElementById(props.href).classList.remove("active");
    // active
    document.getElementById(BasketLink).classList.add("active");

    setDefaultBasketLink(BasketLink);
  };

  const CheckMinCost = () => {
    if (promo) {
      if (Math.floor(price - (price * promo["percent"]) / 100) < 500) {
        alert("Чтобы совершить заказ нужно набрать товаров минимум на 500р");
        return false;
      }
    } else {
      localStorage.removeItem("promo");
      if (price < 500) {
        alert("Чтобы совершить заказ нужно набрать товаров минимум на 500р");
        return false;
      }
    }
    window.location = "/makingAnOrder/";
  };

  function checkPromo(e) {
    e.preventDefault();
    let code = e.target[0].value.toLowerCase();
    if (!/[\D\W]/.test(code) || /\s/.test(code)) {
      return false;
    }
    fetch(global.components.url + "/order/api/promo/?code=" + code, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        if (result[0]) {
          setPromo(result[0]);
          e.target[0].disabled = true;
          document.getElementById("btn_promo").style.display = "None";
          localStorage.setItem(
            "promo",
            JSON.stringify({
              id: result[0]["id"],
              code: result[0]["code"],
              percent: result[0]["percent"],
            })
          );
        } else {
          alert("Промокод не работает или уже был использован");
        }
      });
  }
  useEffect(() => {
    let newList = ProductListToBasket;
    setShowBasket(
      newList.map((item, index) => (
        <ProductList
          key={"ProductListOnBasket" + index}
          ProductList={"ProductListOnBasket"}
          product={item}
          basket={basket}
          favourite={favourite}
          setProductListToBasket={setProductListToBasket}
          setPrice={setNewPrice}
          setProductInBasket={setProductInBasket}
          ProductListToBasket={ProductListToBasket}
          appendProductInBasket={appendProductInBasket}
          deleteFavourite={deleteFavourite}
        />
      ))
    );
  }, [price, discount, ProductListToBasket]);
  return (
    <div className="ProductsInBasket">
      <div className="content">
        <div className="buttons">
          <div className="leftt">
            <button
              id="OnBasket"
              className={` ${defaultBasketLink === "OnBasket" ? "active" : ""}`}
              onClick={goToLink}
            >
              Товаров в заказе - {ProductListToBasket.length}
            </button>
            <button
              id="OnLiked"
              className={` ${defaultBasketLink === "OnLiked" ? "active" : ""}`}
              onClick={goToLink}
            >
              Отложено - {LikedS.length}
            </button>
            <button
              id="HaventOnBasket"
              className={` ${
                defaultBasketLink === "HaventOnBasket" ? "active" : ""
              }`}
              onClick={goToLink}
            >
              Недоступно - {HaventOnShop.length}
            </button>
          </div>
          <div className="rightt">
            <button onClick={clearBasket}>Очистить корзину</button>
          </div>
        </div>

        <div className="information">
          <div className="right">
            <div className="discount">
              <form
                onSubmit={(e) => {
                  checkPromo(e);
                  return true;
                }}
                className="ds"
              >
                <h2>Получите скидку по промокоду</h2>
                <input placeholder="Введите промокод" id="discontPromo" />
                <button type="submit" id="btn_promo" className="sendPromo">
                  Применить
                </button>
              </form>
              <div className="allDiscount">
                <div className="left">
                  <p>Товаров на</p>
                  <p>Скидка</p>
                  {promo ? <p>Промокод: {promo["code"]}</p> : <p></p>}
                  <p className="lastCost">Итого</p>
                </div>
                <div className="right">
                  <p id="allCost">{price} руб.</p>
                  <p id="allDiscount">{discount} руб.</p>
                  {promo ? <p id="allPromo">{promo["percent"]} %</p> : <p></p>}
                  {promo ? (
                    <p id="lastCost">
                      {Math.floor(price - (price * promo["percent"]) / 100)}{" "}
                      руб.
                    </p>
                  ) : (
                    <p id="lastCost">{price} руб.</p>
                  )}
                </div>
                <div className="clearfix"></div>
                <button
                  onClick={CheckMinCost}
                  className="placeAnOrder OnDiscont"
                >
                  Оформить заказ
                </button>
              </div>
              <div className="clearfix"></div>
              
              <button onClick={CheckMinCost} className="placeAnOrder after">
                Оформить заказ
              </button>
              <DolyameBanner clasName="left-bar" />
            </div>
            <img
              style={{ marginTop: "20px", marginLeft: "-5px", width: "328px" }}
              src={banner}
              alt=""
            />
            
          </div>
          <div className="left">
            <div style={{ display: "none" }} className="search">
              <input placeholder="Поиск товара в корзине"></input>
              <button className="searchBut"></button>
            </div>
            {defaultBasketLink === "OnBasket" ? (
              <div className="products">{showBasket}</div>
            ) : (
              <div>
                {defaultBasketLink === "OnLiked" ? (
                  <div className="products">
                    {LikedS.map((item, index) => (
                      <ProductList
                        key={"ProductListOnLiked" + index}
                        ProductList={"ProductListOnLiked"}
                        product={item}
                        basket={basket}
                        favourite={favourite}
                        setProductListToBasket={setProductListToBasket}
                        setPrice={setNewPrice}
                        setProductInBasket={setProductInBasket}
                        ProductListToBasket={ProductListToBasket}
                        appendProductInBasket={appendProductInBasket}
                        deleteFavourite={deleteFavourite}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="products">
                    {HaventOnShop.map((item, index) => (
                      <ProductList
                        key={"HaventOnBasket" + index}
                        ProductList={"HaventOnBasket"}
                        id={item.id}
                        name={item.info.name}
                        price={item.info.price}
                        ProductInfo={item.info}
                        basket={basket}
                        quantity={item.quantity}
                        display={"display: 'none'"}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="clearfix"></div>
      </div>
    </div>
  );
}
