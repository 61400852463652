import React from 'react'
import DiscontContent from '../Components/Pages/disconts/DiscontContent'
import Way from '../Components/Pages/globalComponents/Way'
import {useEffect} from "react";
import {useState} from "react";
//import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';

export default function Disconts(props) {
    const [filtres, setFiltres] = useState();
    const [discountNow, setDiscountNow] = useState(window.location.pathname.split('/')[2]);


    useEffect(() => {
        fetch(global.components.url + "/product/api/characteristic/?format=json")
            .then(res => res.json())
            .then(
                (result) => {
                    setFiltres(result)
                })
    }, [])

    return (
        <div className='Disconts' id='body'>
            <Helmet>
                <title>Распродажа</title>
            </Helmet>
            <Way
                title={"Распродажа"}
                way={["Mary Furnitura", "Распродажа"]}
            />
            <DiscontContent
                // function
                // data
                discountNow={discountNow}
                filtres={filtres}
            />
        </div>
    )
}
